import { API } from '@aws-amplify/api';

const apiName = 'AudioDownloadAPI';
const pathAudio = '/audio';
const pathFolder = '/folder';

export async function getAudioLinkFromAPI(srcKey,value,thumbnail,downloadMode){
    const myInit = { // OPTIONAL
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        body: {"id":srcKey,"thumbnail":thumbnail,"downloadMode":downloadMode,"accessToken":value.getSignInUserSession().getAccessToken().getJwtToken()}
    };
    let response = API.post(apiName, pathAudio, myInit).then(response => {
        if (response.status === 200) {
            return response.data.link;
        } else {
            console.error(response)
            throw new Error('Invalid response')
        }
    }).catch(error => {
        console.error(error)
        throw error
    });
    return response;
}

export async function deleteRecord(srcKey,value,clientId){
    const myInit = { // OPTIONAL
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        body: {
            accessToken:value.getSignInUserSession().getAccessToken().getJwtToken()
        }
    };
    let response = API.del(apiName, `/${clientId}${pathFolder}/${srcKey}`, myInit).then(response => {
        if (response.status === 200) {
            return response.data;
        } else {
            console.error(response)
            throw new Error('Invalid response')
        }
    }).catch(error => {
        console.error(error)
        throw new Error('Invalid response')
    });
    return response;
}