import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Header, Segment, Table } from 'semantic-ui-react';
import { makeComparator } from '../util/comparator';
import { I18n } from 'aws-amplify';


class ClientList extends Component {
  clientItems() {
    return this.props.clients.sort(makeComparator('name')).map(client =>
      <Table.Row key={client.id}>
        <Table.Cell><NavLink to={`/client/${client.id}`}>{client.name}</NavLink></Table.Cell>
        {false && (this.props.profile==='ITAdmin' || this.props.profile==='CM') &&<Table.Cell><NavLink to={`/transfert/${client.id}`}>{I18n.get('Transfert Request')}</NavLink></Table.Cell>}
      </Table.Row>
    );
  }

  render() {
    return (
      <Segment>
        <Header as='h3'>{I18n.get('My Clients')}</Header>
        <Table basic='very' celled  >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{I18n.get('Client name')}</Table.HeaderCell>
              {false && (this.props.profile==='ITAdmin' || this.props.profile==='CM') &&<Table.HeaderCell>{I18n.get('Transfert Request')}</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.clientItems()}
          </Table.Body>
        </Table>
        
      </Segment>
    );
  }
}

export default ClientList;