import React, { Component } from 'react';
import { HashRouter as Router, Route, NavLink, Routes, Navigate, useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { I18n } from 'aws-amplify';
import Auth from '@aws-amplify/auth'

import MyMenu from '../components/menu';
import ClientListLoader from '../components/clientlistloader';

import NewArchiveClient from '../components/newarchiveclient';
import ArchiveClientListLoader from '../components/archiveclientlistloader';
import ArchiveClientDetailsLoader from '../components/archiveclientdetailsloader';

import ClientView from '../components/clientView'
import TransfertRequest from './trasfertRequest';
import UsersListLoader from './userslistloader'
import CostDashboard from './dashboard/costs';
import UsageDashboard from './dashboard/usage';

import { config } from '../config';

import PerformancesDashboard from './dashboard/performances';
import TermsOfUse from './shared/beforeSignin/termsofuse';

import {isEmpty} from "../util/array"

const FUNC_TRANSFERT_ENABLED = config.FUNC_TRANSFERT
const USER_MANAGEMENT_ENABLED = config.USER_MANAGEMENT
const DASHBOARD_ENABLED = config.DASHBOARD


class Routing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admin: false,
            cm: false,
            profile: '',
            userId: '',
            company: [],
            fm: false
        };
    }

    getRights() {
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser({
                bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            }).then(user => {
                const cognitoGroups = user.signInUserSession.idToken.payload['cognito:groups'];
                const profile = cognitoGroups && cognitoGroups.filter(x => x === "CM" || x === "FM" || x === "ITAdmin")[0];
                const userId = user.signInUserSession.idToken.payload.sub;
                const username = user.username;
                const admin = cognitoGroups && cognitoGroups.includes('ITAdmin')
                const cm = cognitoGroups && cognitoGroups.includes('CM')
                const fm = cognitoGroups && cognitoGroups.includes('FM')
                this.setState({ profile: profile, username: username, userId: userId, cognitoGroups: cognitoGroups, admin: admin, cm: cm, fm: fm }, resolve());
            }).catch(
                err => reject('currentAuthenticatedUser: ' + err)
            );
        })
    }

    async componentDidMount() {
        await this.getRights().catch(err => { throw Error(err) });
    }

    routes() {
        const { admin, cm, profile, cognitoGroups, username, userId, fm } = this.state;

        const PrivateRouteAdmin = ({children}) => {
            return (DASHBOARD_ENABLED && admin) ? children : <Navigate to="/"/>
        }

        const PrivateClientView = () => {
            const {clientId, folderName} = useParams()
            return (admin || ((fm || cm) && cognitoGroups.includes(clientId))) ? <ClientView profile={profile} clientId={clientId} folder={folderName}/> : <Navigate to="/" />
        }

        const PrivateTransferRequest = () => {
            const {clientId} = useParams()
            return FUNC_TRANSFERT_ENABLED && (admin || (cm && cognitoGroups.includes(clientId))) ? <TransfertRequest clientId={clientId} username={username} userId={userId} admin={admin} profile={profile} /> : <Navigate to="/"/>
        }

        const PrivateRouteAdminUsers = ({children}) => {
            return (USER_MANAGEMENT_ENABLED && admin) ? children : <Navigate to="/" />
        }

        const PrivateClientFolderView = () => {
            const {clientId, folderName} = useParams()
            return (admin || (cognitoGroups.includes(clientId))) ? <ClientView profile={profile} clientId={clientId} folder={folderName}/> : <Navigate to="/"/>
        }

        const PrivateClientUpdateView = () => {
            const {clientId} = useParams()
            return (admin || (cm && cognitoGroups.includes(clientId))) ? <ArchiveClientDetailsLoader id={clientId} cm={cm} /> : <Navigate tp="/" />
        }

        const PrivateClientManagement = ({ children }) => {
            return (admin || cm) ?  children : <Navigate to="/"/>
        }

        const PrivateBasicUser = ({ children }) => {
            return (!admin && !fm && !cm) ?  children : <Navigate to="/"/>
        }

        let routeArr = [];

        if (isEmpty(cognitoGroups)) {
            routeArr = [
                <React.Fragment key="root">

                    {/* Home */}
                    <Route key="home" path="/" element={<PrivateBasicUser><MyMenu/><NoCompany/></PrivateBasicUser>} />

                </React.Fragment>
            ];
        }
        else {
            routeArr = [

                <React.Fragment key="root">

                    {/* Home */}
                    <Route key="home" path="/" element={<div><MyMenu/><ClientListLoader profile={profile} /></div>} />

                    {/* Dashboard */}
                    <React.Fragment key="dashboard">
                        <Route path="/dashboard/costs" element={<PrivateRouteAdmin><MyMenu/><CostDashboard /></PrivateRouteAdmin>} />
                        <Route path="/dashboard/usage" element={<PrivateRouteAdmin><MyMenu/><UsageDashboard /></PrivateRouteAdmin>} />
                        <Route path="/dashboard/performances" element={<PrivateRouteAdmin><MyMenu/><PerformancesDashboard /></PrivateRouteAdmin>} />
                    </React.Fragment>

                    {/* Client */}
                    <React.Fragment key="client">
                        <Route path="/client/:clientId" element={<div><MyMenu/><PrivateClientView/></div>} />
                        <Route path="/transfert/:clientId" element={<div><MyMenu/><PrivateTransferRequest/></div>}/>
                        <Route path="/client/:clientId/folder/:folderName" element={<div><MyMenu/><PrivateClientFolderView/></div>} />
                    </React.Fragment>

                    {/* Users */}
                    <Route key="users" path="/users" element={<PrivateRouteAdminUsers><MyMenu/><UsersListLoader/></PrivateRouteAdminUsers>} />

                    {/* Admin */}
                    <React.Fragment key="admin">
                        { admin ? <Route path="/admin" element={<PrivateClientManagement><MyMenu/><NewArchiveClient/><ArchiveClientListLoader/></PrivateClientManagement>} /> : <Route path="/admin" element={<PrivateClientManagement><MyMenu/><ArchiveClientListLoader/></PrivateClientManagement>} />}
                        <Route path="/admin/update/:clientId" element={<PrivateClientManagement><MyMenu/><PrivateClientUpdateView/><NavLink to='/admin'>{I18n.get('Back to Client Management')}</NavLink></PrivateClientManagement>} />
                    </React.Fragment>

                    {/* Terms Of Use */}
                    <Route key="termsofuse" path="/termsOfUse" element={<TermsOfUse/>} />

                </React.Fragment>
            ];
        }
        return routeArr;
    }

    render() {

        return (
            <Grid padded>
                <Grid.Column>
                    <Router basename="/">
                        <Routes>
                            {this.routes()}
                        </Routes>
                    </Router>
                </Grid.Column>
            </Grid>
        )
    }
}

function NoCompany() {
    return (
        <div className="ui negative message">
            <div className="header">
                {I18n.get('Your account has not been validated by your company')}
            </div>
            <p>{I18n.get('Please contact your administrator.')}</p>
        </div>
    )
}

export default Routing;
