import React, { Component } from 'react';
import { Segment, Dimmer, Loader, Image, Message, Button, Header } from 'semantic-ui-react';
import { graphqlOperation, I18n } from 'aws-amplify';
import API from '@aws-amplify/api'
import { GetAudioListByFolderAndClient, GetClientWithFolderList, listAudioRecordsFolderFilter } from '../../util/query';
import ClientViewHeader from './header';
import SearchFile from './searchFile';
import FolderList from '../shared/folderList';
import FileList from './fileList';
import { processFileArrayStorageStatus } from '../../util/constant';
import { getSubfolders } from '../../util/folder';

const initialState = {
    client: null,
    folderList: [],
    subFolderList: [],
    foldersLoading: true,
    fileList: [],
    filesLoading: true,
    filesNextToken: null,
    search: null,
    searchNextToken: null,
    error: false,
}

/**
 * ClientView component props:
 * 
 *     profile: "Role (ITAdmin, CM, FM)",
 *     clientId: "clientId",
 *     folder: "Folder name"
 */
class ClientView extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.retrieveFolders();
        this.retrieveMoreFiles();
    }

    componentDidUpdate(prevProps) {
        if (this.props.clientId !== prevProps.clientId || this.props.folder !== prevProps.folder) {
            this.setState({ ...initialState }, () => {
                this.retrieveFolders();
                this.retrieveMoreFiles();
            })
        }
    }

    isAdmin = () => this.props.profile === 'ITAdmin' || this.props.profile === 'CM';

    retrieveFolders = () => {
        this.setState({ foldersLoading: true });
        API.graphql(graphqlOperation(GetClientWithFolderList, { id: this.props.clientId })).then(({ data }) => {
            const folderList = data.getClient.folderList.map(folderData => folderData.folder).sort();
            this.setState({
                folderList: folderList,
                subFolderList: getSubfolders(this.props.folder, folderList),
                foldersLoading: false
            });
        }).catch(_err => this.setState({ error: I18n.get('Could not load client details!'), foldersLoading: false }));
    }

    retrieveMoreFiles = () => {
        if (this.state.search) {
            this.search(this.state.search, this.state.searchNextToken);
            return;
        }

        this.setState({ filesLoading: true });
        const params = {
            limit: 20,
            id: this.props.clientId,
            folderName: this.props.folder ? decodeURIComponent(this.props.folder) : "/",
            nextToken: this.state.filesNextToken
        }
        API.graphql(graphqlOperation(GetAudioListByFolderAndClient, params)).then(({ data }) => {
            this.setState({
                client: data.getClient,
                fileList: [...this.state.fileList, ...data.listFolderRecords.items],
                filesLoading: false,
                filesNextToken: data.listFolderRecords.nextToken
            });
        }).catch(_err => this.setState({ error: I18n.get('Could not load files!'), foldersLoading: false }));
    }

    reloadFileList = () => {
        this.setState({ fileList: [], filesNextToken: null, search: null, searchNextToken: null }, this.retrieveMoreFiles);
    }

    deleteFiles = files => {
        const ids = files.map(f => f.id);
        this.setState({ fileList: this.state.fileList.filter(f => !ids.includes(f.id)) });
    }

    updateFolderList = newFolderList => {
        this.setState({
            folderList: newFolderList,
            subFolderList: getSubfolders(this.props.folder, newFolderList)
        });
    }

    sortFilesByFolder = files => files.sort((a, b) => (a.folder > b.folder) ? 1 : ((a.folder < b.folder) ? -1 : 0));

    search = async (searchData) => {
        this.setState({ filesLoading: true, search: searchData });
        const nextToken = this.state.searchNextToken;
        if (!nextToken)
            this.setState({ fileList: [] });

        const { fileName, folder, tags, creationDateFrom, creationDateTo, searchType } = searchData;

        let params = this.props.folder
            ? { limit: 20, audioRecordClientId: this.props.clientId, filter: { folder: { beginsWith: decodeURIComponent(this.props.folder) } } }
            : { limit: 20, audioRecordClientId: this.props.clientId };

        if ((fileName || folder || tags || creationDateFrom || creationDateTo || searchType) && !params.filter)
            params.filter = {}

        if (fileName)
            params.filter.filename = { contains: fileName }

        if (folder) {
            if (params.filter.folder)
                params.filter.folder.contains = folder
            else
                params.filter.folder = { contains: folder }
        }

        if (tags)
            params.filter.tags = { contains: tags }

        if (creationDateFrom || creationDateTo)
            params.filter.createdAt = {}

        if (creationDateFrom)
            params.filter.createdAt.gt = creationDateFrom.getTime()

        if (creationDateTo)
            params.filter.createdAt.lt = creationDateTo.getTime()

        if (searchType)
            params.filter.recordType = { eq: searchType }

        if (nextToken)
            params.nextToken = nextToken

        API.graphql(graphqlOperation(listAudioRecordsFolderFilter, params)).then(async result => {
            let files = result.data.listAudioFolderRecords.items;
            let newNextToken = result.data.listAudioFolderRecords.nextToken;

            if (files.length !== 0) {
                this.setState({
                    fileList: this.sortFilesByFolder(nextToken ? [...this.state.fileList, ...files] : files),
                    searchNextToken: newNextToken,
                    filesLoading: false
                });
            } else if (newNextToken) {
                this.setState({ searchNextToken: newNextToken }, () =>
                    this.search(searchData)
                );
            } else {
                this.setState({ filesLoading: false });
            }
        }).catch(err => console.log(err));
    }

    render() {
        const { profile, folder } = this.props;
        const client = this.state.client;
        const folderURI = folder ? decodeURIComponent(folder) : null;

        if (!client || this.state.foldersLoading) {
            return (
                <Segment>
                    <Dimmer active>
                        <Loader content={I18n.get('Loading Client Details')} />
                    </Dimmer>
                    <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                </Segment>
            );
        }

        if (this.state.error) {
            return (
                <Message error>
                    <Message.Header>{this.state.error}</Message.Header>
                </Message>
            );
        }

        return (
            <>
                <ClientViewHeader
                    profile={profile}
                    client={client}
                    folderList={this.state.folderList}
                    updateFolderList={this.updateFolderList}
                    folder={folder}
                    reloadFileList={this.reloadFileList}
                />

                <Segment className={this.state.subFolderList.length === 0 ? 'emptySegment' : ''}>
                    <SearchFile folder={folderURI} handleSearchResults={this.search} />
                    {this.state.subFolderList.length > 0 &&
                        <FolderList
                            client={client}
                            folders={this.state.subFolderList}
                            root={folderURI}
                            editMode={false}
                        />
                    }
                </Segment>

                <Segment>
                    {this.state.filesLoading &&
                        <React.Fragment>
                            <Dimmer active>
                                <Loader content={I18n.get(this.state.search ? 'Searching files...' : 'Loading files...')} />
                            </Dimmer>
                            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                        </React.Fragment>
                    }

                    {this.state.search && !this.state.filesLoading && this.state.fileList.length === 0 &&
                        <Header as='h4' color='grey'>
                            {I18n.get('No file found matching')} {this.props.fileName} {this.props.tags}
                        </Header>
                    }

                    {(!this.state.search || this.state.fileList.length > 0) &&
                        <FileList
                            profile={profile}
                            client={client}
                            folder={folder}
                            files={processFileArrayStorageStatus(this.state.fileList, this.state.client.myRetentionList).map(file => {
                                return { ...file, folderExists: file.folder === '/' || this.state.folderList.includes(file.folder) };
                            })}
                            hasSubFolders={this.state.subFolderList.length > 0}
                            isSearch={this.state.search !== null}
                            retrieveMoreFiles={this.retrieveMoreFiles}
                            deleteFiles={this.deleteFiles}
                        />
                    }

                    {(this.state.filesNextToken || (this.state.search && this.state.searchNextToken)) &&
                        <Button
                            onClick={this.retrieveMoreFiles}
                            icon='refresh'
                            disabled={this.state.filesLoading}
                            content={this.state.filesLoading ? I18n.get('Loading...') : I18n.get('Load more files')}
                        />
                    }
                </Segment>
            </>
        );
    }
}

export default ClientView;
