import React, { Component } from 'react';
import CustomTooltip from './customTooltip';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class Chart extends Component {

    render() {
        const { yFormat, crosshairTitleFormat, crosshairItemsFormat, legends, referenceLines } = this.props;

        var data = referenceLines ? this.props.data.map(d => ({ ...d, rl: referenceLines[0].value })) : this.props.data

        return (
            <ResponsiveContainer width='100%'  height={300}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis tickFormatter={yFormat} tickMargin={10} tickSize={5} tick={{fontSize: 10}}/>
                    <XAxis dataKey="x" tick={{fontSize: 10}} tickSize={5} tickMargin={10}/>
                    <Tooltip content={<CustomTooltip crosshairtitle={crosshairTitleFormat} crosshairitems={crosshairItemsFormat}/>} />
                    <Legend verticalAlign="top" align="right" wrapperStyle={{paddingBottom: 10, fontSize: 12}} iconType="plainline" iconSize={10} />
                    <Line name={legends[0]} type="linear" dataKey="y0" stroke="rgb(24, 172, 179)" dot={false} strokeWidth={2}/>
                    {legends[1] && <Line name={legends[1]} type="linear" dataKey="y1" stroke="rgb(255, 152, 51)" dot={false} strokeWidth={2}/>}
                    {referenceLines && <Line dataKey="rl" name={legends[2]} strokeDashArray="2" stroke={referenceLines[0].color} dot={false} strokeWidth={2}/>}
                </LineChart>
            </ResponsiveContainer>
        );
    }
}

export default Chart;