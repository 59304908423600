import React, { Component } from 'react';
import API from '@aws-amplify/api'
import { graphqlOperation, I18n } from 'aws-amplify';
import { Button, Form, Header, Input, Segment, Message } from 'semantic-ui-react';
import { storageType, removeEmptyRetentionList, removeEmptyFolderName } from '../util/constant';
import { reorganizeFolders } from '../util/folder';
import { createClientQuery } from '../util/query';
import { createGroup } from '../util/cognito';
import { S3Util } from '../util/s3';
import aws_exports from '../aws-exports';

class NewArchiveClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientName: '',
      myRetentionList: [
        { type: '', retention: 0 }
      ],
      folders:[{folder:''}],
      showAddButton: true,
      showWarning: false,
      showSuccess:false,
      createdClient:'',
      storageType: storageType
    };
  }
  
  createClient = async (event) => {
    if (this.state.clientName) {
      event.preventDefault();
      this.setState({ showWarning: false });
      let myRetentionList = removeEmptyRetentionList(this.state.myRetentionList);
      myRetentionList = JSON.stringify(myRetentionList);
      myRetentionList = myRetentionList.replace(/"(\w+)"\s*:/g, '$1:');
      let folders = removeEmptyFolderName(this.state.folders);
      folders = await reorganizeFolders(folders)
      folders = JSON.stringify(folders);
      folders = folders.replace(/"(\w+)"\s*:/g, '$1:');
      const result = await API.graphql(graphqlOperation(createClientQuery(myRetentionList,folders), { name: this.state.clientName, myRetentionList: this.state.myRetentionList }));
      await createGroup(result.data.createClient.id, this.state.clientName)
      await this.createRetentions(this.state.myRetentionList, result.data.createClient.id)
      this.setState({ showSuccess: true });
      this.setState({ createdClient: this.state.clientName });
      this.setState({
        clientName: '',
        myRetentionList: [
          { type: '', retention: 0, duration: '' }
        ],
        folders:[{folder:''}]});
    } else {
      this.setState({ showSuccess: false });
      this.setState({ showWarning: true });
    }
  }

  createRetentions = async (retentionList, clientId) => {
    let s3 = await S3Util.create()
    retentionList = retentionList.filter( x => x.retention || x.type )
    await s3.createOrUpdateLifecyclePolicy(aws_exports.aws_user_files_s3_bucket, clientId, retentionList)
  }

  showType = () => {
    this.setState({ showAddButton: this.state.showAddButton });
    let myRetentionList = [...this.state.myRetentionList];
    myRetentionList.push({ type: '', retention: 0});
    this.setState({ myRetentionList });
    if(myRetentionList.length >= storageType.length)
      this.setState({ showAddButton: false});
  }

  addFolder = async () => {
    let folders = [...this.state.folders];
    folders = await reorganizeFolders(folders)
    folders.push({folder:''});
    this.setState({ folders });
  }

  handleSelectChange = (index, type, event, { value }) => {
    let myRetentionList = [...this.state.myRetentionList];
    let item = { ...myRetentionList[index] };
    if (type === 'type') {
      item.type = value;
      if(value === undefined )
        item.retention = 0
    }
    if (type === 'retention') {
      item.retention = parseInt(value);
    }
    myRetentionList[index] = item;
    this.setState({ myRetentionList });
    this.disableStorageTypeInputSelectedInRetentions(myRetentionList)

  };

  deleteType = (type) => {
    var array = [...this.state.myRetentionList]
    array.splice(type, 1)
    this.setState({myRetentionList: array})
    if (this.state.myRetentionList.length <= 4)
      this.setState({ showAddButton: true });
  }

  deleteFolder = async (folder) => {
    var array = [...this.state.folders]
    if (array[folder].folder==="") {
      array.splice(folder,1)
      this.setState({folders: array})
    } else {
      const newArray = []
      array.forEach(f => {
        if (!f.folder.startsWith(array[folder].folder)) {
          newArray.push(f)
        }
      });
      this.setState({folders: newArray})
    }
  }

  disableStorageTypeInputSelectedInRetentions = (myRetentionList) => {
    let types = [...this.state.storageType]
    types.map(x => {
      if (myRetentionList.map(t => t.type).includes(x.key))
        x.disabled = true
      else
        x.disabled = false
      return x
    })
    this.setState({ storageType: types });
  }

  handleChange = (e, { name, value }) => {
    this.setState({ showWarning: false });
    this.setState({ showSuccess: false });
    this.setState({ createClient:'' });
    this.setState({ [name]: value });
  }

  handleFolderChange = (index, event, { value }) => {
    let folders = [...this.state.folders];
    let item = { ...folders[index] };
    item.folder = value;
    folders[index] = item;
    this.setState({ folders });
  };

  render() {
    return (
      <Segment>
        
        <Header as='h3'>{I18n.get('Create a new client')}</Header>
        {this.state.showWarning && <Message negative>
          <Message.Header>
              {I18n.get('You must enter Client Name to create a new client!')}
          </Message.Header> </Message>}
        {this.state.showSuccess && <Message positive>
            <Message.Header>{I18n.get('Created client with name')}{this.state.createdClient}</Message.Header>
        </Message>}
        <Form> 
          <Form.Group widths='equal'>
            <Form.Field
              control={Input}
              required={true}
              value={this.state.clientName}
              onChange={this.handleChange}
              label={I18n.get('Client name')}
              name='clientName'
              placeholder={I18n.get('Client name')}
            />
          </Form.Group>
          
          {this.state.myRetentionList.map((item, index) => (
            <Form.Group key={index}>
              <React.Fragment key={index}>
                    <Form.Select label={I18n.get('Type')} key={'type' + index} options={[{ key: "null", text: "", value: undefined }].concat(this.state.storageType)} placeholder={I18n.get('Type')} value={item.type} disabled={this.props.cm} onChange={this.handleSelectChange.bind(this, index, 'type')} />
                  <Form.Input fluid label={I18n.get('Retention (days)')} type="number" min="0" step="1" key={'retention' + index} required={item.type !== ''} control={Input} value={item.retention} disabled={this.props.cm} onChange={this.handleSelectChange.bind(this, index, 'retention')} placeholder={I18n.get('Retention')}/>
                  <React.Fragment>
                    <div className="field">
                      <label htmlFor="">&nbsp;</label>
                      <button type="submit" className="ui button icon" title={I18n.get('Delete retention')} onClick={() => this.deleteType(index)}><i className="trash icon"></i></button>
                    </div>
                  </React.Fragment>
                </React.Fragment>
              </Form.Group>
            ))}

            {this.state.showAddButton && !this.props.cm && <React.Fragment>
                <div className="field">
                  {this.state.myRetentionList.length<1 && <label htmlFor="">{I18n.get("Add retention rules")}</label>}
                  <button type="submit" className="ui button icon" title={I18n.get('Add retention')} onClick={this.showType}><i className="plus icon"></i></button>
                </div>
              </React.Fragment>
            }

          {this.state.folders.map((item, index) => (
            <Form.Group key={index}>
              <React.Fragment key={index}>
                <Form.Field control={Input} label={I18n.get('Folder name')} value={item.folder} onChange={this.handleFolderChange.bind(this, index)} placeholder={I18n.get('Folder name')} />
                <React.Fragment>
                  <div className="field">
                    <label htmlFor="">&nbsp;</label>
                    <button type="submit" className="ui button icon" title={I18n.get('Delete folder')} onClick={() => this.deleteFolder(index)}><i className="trash icon"></i></button>
                  </div>
                </React.Fragment>
              </React.Fragment>
            </Form.Group>
          ))}

          <React.Fragment>
            <div className="field">
              {this.state.folders.length<1 && <label htmlFor="">{I18n.get("Add root folders")}</label>}
              <button className="ui button icon" type="button" title={I18n.get('Add folder to root')} onClick={this.addFolder}><i className="plus icon"></i></button>
            </div>
          </React.Fragment>

          <Button type="submit" onClick={this.createClient} primary>{I18n.get('Create client')}</Button>

        </Form>
      </Segment>
    )
  }
}

export default NewArchiveClient;