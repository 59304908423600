import { config } from '../config'
var moment = require('moment');

function storageTypeOptionsConstructor(mapping){
  return Object.keys(mapping).map( x => ({ key: x, text: x, value: x}) )
}

export const storageMapping = config.STORAGE_TYPES

export const storageType = storageTypeOptionsConstructor(storageMapping)


export const signUpConfig = {
  defaultCountryCode: 33,
  signUpFields: [
    {
      label: 'Username',
      key: 'username',
      required: true,
      placeholder: 'Username',
      displayOrder: 1,
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      placeholder: 'Password',
      type: 'password',
      displayOrder: 2,
    },
    {
      label: 'Email',
      key: 'email',
      required: true,
      placeholder: 'Email',
      type: 'email',
      displayOrder: 3
    },
    {
      label: 'Phone Number',
      key: 'phone_number',
      placeholder: 'Phone Number',
      required: true,
      displayOrder: 4
    },
    {
      label: 'Company',
      key: 'custom:Customer',
      required: true,
      displayOrder: 5,
      placeholder: 'Company',
      type: 'string'
    }
  ]
};

export function removeEmptyFolderName(folderList) {
  var myArr = [];
  for (var i = 0; i < folderList.length; i++) {
    var folderName = folderList[i].folder;
    if (folderName) {
      myArr.push({ folder: folderName });
    }
  }
  return myArr;
}

export function removeEmptyRetentionList(myRetentionList) {
  var myArr = [];
  for (var i = 0; i < myRetentionList.length; i++) {
    var type = myRetentionList[i].type;
    var retention = myRetentionList[i].retention;
    var duration = myRetentionList[i].duration;
    if (type) {
      if (isNaN(duration) || !duration) {
        myArr.push({ type: type, retention: parseInt(retention) });
      } else {
        myArr.push({ type: type, retention: parseInt(retention), duration: parseInt(duration) });
      }
    }
  }
  return myArr;
}
export function formatDate(epochTime,dateformat) {
  if (! dateformat || dateformat==='dateFormat')  {
    dateformat='MM/DD/YYYY HH:mm'
  }
  if (epochTime)
    return moment(epochTime).format(dateformat);
  else
    return '';
}

export function formatDateEpochTimeStamp(epochTime,dateformat) {
  if (epochTime)
    return formatDate(epochTime/1000,dateformat)
  else
    return '';
}

export function formatSeconds(seconds) {
  if (seconds) {
    return Math.round(seconds);
    //return moment().startOf('day').add(seconds, 'seconds').format('mm:ss');
  }
  return '';
}

export function arraysEqual(a1,a2) {
  return JSON.stringify(a1) === JSON.stringify(a2);
}


export const mode = [
  { key: 'mail', text: 'Mail', value: 'mail' },
  { key: 'link', text: 'Link', value: 'link' }
];

export const REJECTED = 'Rejected';
export const ACCEPTED = 'Accepted';
export const ERROR = 'Error';
export const COMPLETED = 'Completed';
export const TIMEOUT = 'Timeout';
export const IN_PROGRESS = 'In Progress';


export const STORAGE_STATUS={ normal: 'Normal', glacier: 'Glacier', glacierRestored: 'GlacierRestored'}

function addDaysToTimestamp(timestamp, days) {
  let date = new Date(timestamp)
  date.setDate(date.getDate() + days)
  return date.getTime()
}

export function processFileStorageStatus(storageStatus, statusDate, retention) {
  let currentDate = new Date().getTime()
  if( retention === undefined ) // in case the client doesnt have any retention declared
  {
    if( storageStatus === STORAGE_STATUS.glacierRestored && currentDate >= statusDate ) // Exception since if file is restored, statusDate contains the date where he will no longer be restored
      return STORAGE_STATUS.glacier
    else
      return storageStatus ? storageStatus : STORAGE_STATUS.normal
  }
  else if (
    storageStatus === STORAGE_STATUS.normal && currentDate >= statusDate && currentDate < addDaysToTimestamp(statusDate, retention) 
  )
    return STORAGE_STATUS.normal

  else if ( 
    storageStatus === STORAGE_STATUS.glacier || 
    (storageStatus === STORAGE_STATUS.normal && currentDate >= addDaysToTimestamp(statusDate, retention) )  ||
    (storageStatus === STORAGE_STATUS.glacierRestored && currentDate >= statusDate)
  )
    return STORAGE_STATUS.glacier

  else if (
    storageStatus === STORAGE_STATUS.glacierRestored && currentDate < statusDate
  )
    return STORAGE_STATUS.glacierRestored
  else
    return undefined
}

export function processFileArrayStorageStatus(records, retentions) {
  return records.map( record => {
    let fileTypeRetentions = retentions.filter( ret => ret.type === record.recordType)
    let retentionDays = fileTypeRetentions.length > 0 ? retentions.filter( ret => ret.type === record.recordType)[0].retention : undefined
    let fileState = processFileStorageStatus(record.storageStatus, record.statusDate, retentionDays)
    record.fileState = fileState
    return record
  })
}
