import React, { Component } from 'react';
import { I18n, Auth, API } from 'aws-amplify';
import { Button, Modal, Message, Form, Header, Card } from 'semantic-ui-react';
import { getUsers } from '../../../util/cognito';

const initialState = budget => {
    return {
        open: false,
        error: null,
        budgetLimit: budget.BudgetLimit.Amount,
        budgetNotifications: budget.Notifications.map(notifData => {
            return {
                Notification: notifData.Notification,
                Subscribers: notifData.Subscribers.map(subData => subData.Address)
            };
        }),
        loading: false,
        admins: [],
    }
}

class UpdateBudgetModal extends Component {

    constructor(props) {
        super(props);
        this.state = initialState(props.budget);
        this.loadUsers();
    }

    onModalOpen = () => {
        this.setState(initialState(this.props.budget));
        this.loadUsers();
    }

    loadUsers = () => {
        getUsers().then(users =>
            this.setState({
                admins: users
                    .filter(u => u.Attributes.profile === 'ITAdmin' && u.Attributes.email)
                    .map(u => {
                        return { key: u.Attributes.email, value: u.Attributes.email, text: u.Attributes.email + ' (' + u.Username + ')' }
                    })
            })
        );
    }

    handleChange = (_e, { name, value }) => {
        this.setState({ [name]: value || null });
    }

    updateBudgetApiCall = async (budgetData) => {
        const apiName = 'AudioDownloadAPI';
        const path = "/dashboard/budgets";
        const self = this;

        await Auth.currentAuthenticatedUser().then(_value => {
            Auth.currentCredentials().then(_credentials => {
                const params = { response: true, body: budgetData };
                API.patch(apiName, path, params).then(response => {
                    if (response.status === 200) {
                        self.setState({ open: false });
                        self.props.updateBudgetsFunction();
                    } else throw Error();
                }).catch(_error => {
                    self.setState({ error: I18n.get("Unable to update budget"), loading: false });
                    const timer = setTimeout(() => {
                        self.setState({ error: null });
                        clearTimeout(timer);
                    }, 5000);
                });
            });
        });
    }

    addNotification = _e => {
        this.setState({budgetNotifications: [...this.state.budgetNotifications, {
            Notification: {
                ComparisonOperator: "GREATER_THAN",
                NotificationType: "ACTUAL",
                Threshold: 100,
                ThresholdType: "PERCENTAGE"
            },
            Subscribers: []
        }]});
    }

    deleteNotification = (_e, data) => {
        const index = data['data-index'];
        let notifications = this.state.budgetNotifications;
        notifications.splice(index, 1);
        this.setState({budgetNotifications: notifications});
    }

    handleNotificationThresholdChange = (_e, data) => {
        const index = data['data-index'];
        let notifications = this.state.budgetNotifications;
        notifications[index].Notification.Threshold = data.value;
        this.setState({budgetNotifications: notifications});
    }

    handleNotificationSubscribersChange = (_e, data) => {
        const index = data['data-index'];
        let notifications = this.state.budgetNotifications;
        notifications[index].Subscribers = data.value;
        this.setState({budgetNotifications: notifications});
    }

    handleSubmit = () => {
        this.setState({ error: null, loading: true });
        let budget = { ...this.props.budget };
        delete budget.History;
        delete budget.Notifications;
        budget.BudgetLimit.Amount = this.state.budgetLimit
        this.updateBudgetApiCall({ budget, notifications: this.state.budgetNotifications });
    }

    validate = () => {
        if (this.state.budgetLimit <= 0)
            return false;
        for (const notif of this.state.budgetNotifications) {
            if (notif.Notification.Threshold <= 0)
                return false;
            if (notif.Subscribers.length === 0)
                return false;
        }
        return true;
    }

    render() {
        return (
            <Modal
                size='small'
                dimmer='blurring'
                trigger={
                    <Button
                        onClick={this.onModalOpen}
                        floated="right"
                        size="mini"
                        icon="pencil"
                        color="blue"
                    />
                }
                closeIcon
                open={this.state.open}
                onOpen={() => this.setState({ open: true })}
                onClose={() => this.setState({ open: false })}
                as={Form}
                error={this.state.error !== null}
                onSubmit={this.handleSubmit}
            >
                <Modal.Header>{I18n.get('Updating budget')}</Modal.Header>
                <Modal.Content>

                    <Message error
                        header={I18n.get('Budget update failed!')}
                        content={this.state.error}
                    />

                    <Form.Input
                        label={I18n.get('Budget limit')}
                        icon='dollar sign'
                        iconPosition='left'
                        type='number'
                        min='0'
                        step='any'
                        name='budgetLimit'
                        onChange={this.handleChange}
                        value={this.state.budgetLimit}
                    />

                    <Header as="h3">{I18n.get("Notifications")}</Header>

                    {this.state.budgetNotifications.map((budgetNotification, index) => {
                        return (
                            <Card key={index} style={{ width: '100%', height: 'unset' }}>
                                <Card.Content>
                                    <Button
                                        type='button'
                                        icon='trash'
                                        color='red'
                                        floated='right'
                                        size='mini'
                                        onClick={this.deleteNotification}
                                        data-index={index}
                                    />

                                    <Form.Input
                                        label={I18n.get('Threshold')}
                                        icon='percent'
                                        type='number'
                                        min='0'
                                        onChange={this.handleNotificationThresholdChange}
                                        value={budgetNotification.Notification.Threshold}
                                        data-index={index}
                                    />

                                    <Form.Select
                                        label={I18n.get('Emails')}
                                        multiple
                                        onChange={this.handleNotificationSubscribersChange}
                                        value={budgetNotification.Subscribers}
                                        options={this.state.admins}
                                        data-index={index}
                                    />
                                </Card.Content>
                            </Card>
                        );
                    })}

                    <Button
                        type='button'
                        icon='plus'
                        labelPosition='left'
                        content={I18n.get('Add notification')}
                        onClick={this.addNotification}
                    />

                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        type='submit'
                        disabled={this.state.loading || !this.validate()}
                        loading={this.state.loading}
                    >
                        {I18n.get('Update')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default UpdateBudgetModal;