import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { Button, Modal, Icon, Card } from "semantic-ui-react";
import TermsOfUse from "./termsofuse";

const initialState = {
    loading: false,
    error: null,
}

/**
 * CookiesModal component props:
 *     
 *     open: boolean,
 *     acceptTermsOfUse: function(),
 *     onClose: function,
 */
class CookiesModal extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    onModalOpen = () => {
        this.setState({ ...initialState });
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open)
            this.onModalOpen();
    }

    render() {
        return (
            <Modal
                as={Card}
                style={{ width: '50%', height: 'unset' }}
                dimmer='blurring'
                closeOnDimmerClick={false}
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <Card.Content>
                    <Card.Header><Icon name='book' />{I18n.get('Please accept the Terms of use')}</Card.Header>
                </Card.Content>
                <Card.Content>
                    <div style={{marginLeft:"1cm",marginRight:"1cm",marginTop:"0.5cm",marginBottom:"1cm"}} >
                        <div>
                            <h3 align='center'><strong>{I18n.get('Cookies Policy')}</strong></h3>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                        </div>
                        <div>
                            <p><span >{I18n.get('Cookies Policy description')}</span></p>
                        </div>
                    </div>
                </Card.Content>
                <Card.Content>
                    <TermsOfUse />
                </Card.Content>
                <Card.Content extra textAlign='center'>
                    <Button
                        color='blue'
                        content={I18n.get('Accept All')}
                        onClick={this.props.acceptTermsOfUse}
                        loading={this.state.loading}
                        disabled={this.state.error !== null || this.state.loading}
                        data-test="accept-terms-of-use"
                    />
                </Card.Content>
            </Modal>
        );
    }

}

export default CookiesModal;