import { graphqlOperation, I18n } from 'aws-amplify';
import API from '@aws-amplify/api'
import React, { Component } from 'react';
import { Modal, Button, Card, Message, Icon, Form, Container, Segment, List } from 'semantic-ui-react';
import { createTransfertRequest } from '../../util/query';

const initialState = {
    loading: false,
    requestConfirmed: false,
    restoreDaysInput: 1,
}

/**
 * RestoreRequestModal component props:
 *     
 *     open: boolean,
 *     client: {Client object},
 *     folder: "folderName",
 *     invalidFiles: [{file Objects}],
 *     onClose: function,
 */
class RestoreRequestModal extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open)
            this.setState({ ...initialState });
    }

    maxRestoreDuration = () => {
        const { client, invalidFiles } = this.props;

        const types = [...new Set(invalidFiles.map(f => f.recordType))];
        const retentions = client.myRetentionList.filter(ret => types.includes(ret.type)).map(ret => ret.retention);
        if (retentions.length === 0)
            return undefined;

        // The maximum duration that can be asked is the minimum of the ones configured for the types contained in selection
        return Math.min(...retentions);
    }

    validateRestoreInput = restoreDays => {
        if (!restoreDays || isNaN(restoreDays) || restoreDays < 1)
            return false;
        const maxRestoreDuration = this.maxRestoreDuration();
        return !maxRestoreDuration || restoreDays <= maxRestoreDuration;
    }

    confirmRestoreProposition = async () => {
        const { restoreDaysInput } = this.state;
        if (!this.validateRestoreInput(restoreDaysInput)) {
            this.setState({ showRestoreDaysWarning: true, restoreDaysInput: initialState.restoreDaysInput });
            return;
        }

        const audios = this.props.invalidFiles.map(file => file.id);
        const files = this.props.invalidFiles;
        const { client } = this.props;
        const folder = this.props.folder ?? '/';

        this.setState({ loading: true });
        await API.graphql(graphqlOperation(createTransfertRequest(false, client.id, folder, audios, files, 'restore', restoreDaysInput, audios)));
        this.setState({ loading: false });
        this.props.onClose();
    }

    render() {
        const maxRestore = this.maxRestoreDuration();

        return (
            <Modal
                as={Card}
                style={{ width: 'unset', height: 'unset' }}
                dimmer='blurring'
                closeOnDimmerClick={!this.state.loading}
                closeIcon={!this.state.loading}
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <Card.Content>
                    <Card.Header><Icon name='exclamation circle' />{I18n.get('Issue with the selected file(s)')}</Card.Header>
                    {this.state.showRestoreDaysWarning &&
                        <Message negative>
                            <Message.Header>{I18n.get('Please enter a valid number !')}</Message.Header>
                        </Message>
                    }

                    {!this.state.requestConfirmed
                        ? (
                            <Container text>
                                <p>{I18n.get('The following files are archived, and therefore cannot be accessed directly.')}</p>
                                <Segment inverted className={'fixedSegment'}>
                                    <List divided inverted relaxed className={'scrollableList'}>
                                        {this.props.invalidFiles.map(file =>
                                            <List.Item key={file.id}>
                                                <List.Content>
                                                    <List.Header>{file.filename}</List.Header>
                                                    {file.folder === '/' ? undefined : file.folder}
                                                </List.Content>
                                            </List.Item>
                                        )}
                                    </List>
                                </Segment>
                                <p>{I18n.get('Would you like to request a restore for these files?')}</p>
                            </Container>
                        )
                        : (
                            <Form>
                                <Form.Input
                                    label={`${I18n.get('Number of days that the restored files will be available')}${(maxRestore && ` (max ${maxRestore})`) || ''}`}
                                    type='number'
                                    size='mini'
                                    name='restoreDaysInput'
                                    value={this.state.restoreDaysInput}
                                    min={1}
                                    max={maxRestore}
                                    onChange={(_e, { value }) => this.setState({ restoreDaysInput: value })}
                                />
                            </Form>
                        )
                    }
                </Card.Content>
                <Card.Content extra textAlign='center'>
                    <Button.Group>
                        <Button
                            content={I18n.get('Cancel')}
                            onClick={this.props.onClose}
                        />
                        <Button
                            primary
                            content={I18n.get(this.state.requestConfirmed ? 'Confirm' : 'OK')}
                            onClick={() => this.state.requestConfirmed
                                ? this.confirmRestoreProposition()
                                : this.setState({ requestConfirmed: true })
                            }
                            loading={this.state.loading}
                            disabled={(this.state.requestConfirmed && !this.validateRestoreInput(this.state.restoreDaysInput)) || this.state.loading}
                        />
                    </Button.Group>
                </Card.Content>
            </Modal>
        );
    }
}

export default RestoreRequestModal;