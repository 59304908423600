import React from 'react';
import { NavLink } from 'react-router-dom';

export async function reorganizeFolders (folders )  {
    if (!folders) return
    // construct structure
    var map1 = new Map(); 
    folders.forEach( 
      folder => {
        var structure= folder.folder.split("/") ;
        map1.set(structure[0],subFoldersList(structure, map1.get(structure[0]),1))
      } 
    );
    // return flat view
    //map1.keys().forEach
    var returnfolders = []
    for (const [key, value] of map1.entries()) {
      var mainFolder = {
        folder : key
      }
      returnfolders.push(mainFolder);
      returnfolders = subFoldersLineralize(returnfolders,mainFolder.folder,value)
    }
    returnfolders.sort();
    return returnfolders 
  }

function subFoldersList (structure, sublist, iterator) {
  
  if (!structure || ! structure[iterator])
    return new Map();
  if (! sublist) {
    sublist = new Map();
    sublist.set(structure[iterator],new Map());
  }
  return sublist.set(structure[iterator],subFoldersList(structure,sublist.get(structure[iterator]),iterator+1))
}

function subFoldersLineralize (folders,prefix,value) {
  if (!value ) return;
  for (const [key1, value1] of value.entries()) {
    var mainSubFolder = {
      folder : `${prefix}/${key1}`
    }
    folders.push(mainSubFolder);
    folders = subFoldersLineralize(folders,mainSubFolder.folder,value1)
  }
  return folders;
}
function subFoldersUnlinearize (structure,sublist,iterator) {
  if (!structure ) return new Map();
  if (structure.length>iterator) {
    if (! sublist) {
        sublist = new Map()
    } 
    var sub=subFoldersUnlinearize(structure,sublist.get(structure[iterator]),iterator+1)
    return sublist.set(structure[iterator],sub)
  } else {
    return new Map();
  }
}

  export async function folderMap (folders) {
    var map1 = new Map(); 
    folders.map( folder => {
      var structure= folder.split("/") ;
      map1.set(structure[0], subFoldersUnlinearize(structure,map1.get(structure[0]),1));
      return folders;      
    })
    return map1;
  }


  export async function folderArray (folders) {
    var returnfolders = []
    for (const [key, value] of folders.entries()) {
      var mainFolder = {
        folder : key
      }
      returnfolders.push(mainFolder);
      returnfolders = subFoldersLineralize(returnfolders,mainFolder.folder,value)
    }
    return returnfolders;
  }

  export function compareFolder(a, b) {
    if (a.key > b.key) return 1;
    if (b.key > a.key) return -1;
  
    return 0;
  }


// Used to slice folder path into an array
  export function   processEntryKey(key){
    let arr = key.split('/')
    for (let i = 1; i < arr.length; i++) {
      arr[i] = [arr[i-1],arr[i]].join('/')
    }
    return arr
  }

  export function mapToKeys(map,disablebutton){
    if (!map ) return ;
    var myFolderKeys = Array.from(map.keys());
    var mybuttons = disablebutton
    myFolderKeys.forEach((entry,index)=> {
      myFolderKeys[index] = {
        key : `${entry}`,
        value : map.get(entry)
      }
      if ( map.get(entry).size > 0) {
        mybuttons.set(entry,0)
      } else {
        mybuttons.set(entry,2)
      }
    })
    return [myFolderKeys, mybuttons]
  }

  export function formatFolder(currentFolder, foldersPath){

    // if(foldersPath){
      if(currentFolder){
        foldersPath = foldersPath.filter(x => x.startsWith(decodeURIComponent(currentFolder)+'/')).map(x => x.substr((decodeURIComponent(currentFolder)+'/').length))
      }
      
      let result = [];
      let level = {result};
      if(foldersPath.length>0){
      foldersPath.forEach(path => {
        if(path.length>0){
        path.split('/').reduce((r, name, i, a) => {
          if(!r[name]) {
            r[name] = {result: []};
            r.result.push({name,active:false, children: r[name].result})
          }
          
          return r[name];
        }, level)
      }
      })
      }

      return result
    // }

  }


  export function hierarchyToFoldersList(hierarchy) {
    let result = []
    handleHierarchyToFoldersList(hierarchy, [], result)
    return result
  }

  function handleHierarchyToFoldersList(a, parents, arr) {
    a.forEach(e => {
        let parentsBackup = [...parents]
        if (e.children.length > 0) {
            if (parents.length > 0)
                arr.push(parents.join('/') + '/' + e.name)
            else
                arr.push(e.name)
            parents.push(e.name)
            handleHierarchyToFoldersList(e.children, parents, arr)
        }
        else {
            if (parents.length > 0)
                arr.push(parents.join('/') + '/' + e.name)
            else
                arr.push(e.name)
        }
        parents = parentsBackup
    })
}

export function generateFolderPathWithLinks(folder, clientId) {
  if (folder === '/')
      return <NavLink to={`/client/${clientId}`}>{' / '}</NavLink>
  
  return processEntryKey(folder).map((path, i) =>
      <React.Fragment key={i}>
          <NavLink to={`/client/${clientId}/folder/${encodeURIComponent(path)}`}>
              {path.split('/')[i]}
          </NavLink>{' / '}
      </React.Fragment>
  );
}


export function getSubfolders(folder, folderList) {
  const folderName = folder ? decodeURIComponent(folder) : null;
  return folderName && folderName !== '/'
    ? folderList.filter(f => f.startsWith(`${folderName}/`))
    : folderList;
}