import React from "react"

const CustomTooltip = ({ active, payload, crosshairtitle, crosshairitems}) => {
    if (active && payload && payload.length) {
        var p = [];
        for (var i = 0; i < crosshairitems(payload[0].payload).length; i++) {p.push((crosshairitems(payload[0].payload))[i].title + ": " + (crosshairitems(payload[0].payload))[i].value)}
        return (
            <div style={{padding: "7px 10px", backgroundColor: "#3a3a48", fontSize: "12px", color: "#fff", borderRadius: "4px", boxShadow: "0 2px 4px rgba(0,0,0,.5)"}}>
                {<p><strong>{(crosshairtitle(payload[0].payload))[0].title + ": " + (crosshairtitle(payload[0].payload))[0].value}</strong></p>}
                {p.map(item => <p>{item}</p>)}
            </div>
        );
    } return null;
};
export default CustomTooltip