import React, { Component } from 'react'
import { I18n } from 'aws-amplify';
import Auth from '@aws-amplify/auth'
import API from '@aws-amplify/api'
import { Header, Segment, Dimmer, Loader, Image, Card, Button, Form, Select, Label } from 'semantic-ui-react';
import Chart from '../shared/linechart';
import fileSize from 'filesize';

const SEC_PER_DAY = 24 * 3600;
class UsageDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
        };

        this.periods = [
            { key: SEC_PER_DAY, value: SEC_PER_DAY, text: I18n.get('day') },
            { key: 3600, value: 3600, text: I18n.get('hour') },
            { key: 60, value: 60, text: I18n.get('minute') },
        ];
    }

    async componentDidMount() {
        this.getCloudWatchStorageDataApiCall();
        this.getDownloadActivationStatus();
    }

    getCloudWatchStorageDataApiCall = async () => {
        const apiName = 'AudioDownloadAPI';
        const path = "/dashboard/metrics";
        const self = this;

        await Auth.currentAuthenticatedUser().then(_user => {
            Auth.currentCredentials().then(_credentials => {
                const params = { response: true };
                API.get(apiName, path, params).then(response => {
                    if (response.status === 200) {
                        this.cloudWatchDashboardDataProcess("storage", [response.data.Storage]);
                        this.cloudWatchDashboardDataProcess("download", response.data.Downloads.Metrics);
                        this.cloudWatchDashboardAlarmProcess("download", response.data.Downloads.Alarm);
                        this.cloudWatchDashboardDataProcess("upload", response.data.Uploads.Metrics);
                    } else {
                        self.setState({ error: I18n.get("Unable to retrieve cloudwatch metrics") });
                        const timer = setTimeout(() => {
                            self.setState({ error: false });
                            clearTimeout(timer);
                        }, 5000);
                    }
                    self.setState({ loading: false });
                }).catch(error => {
                    self.setState({ loading: false, error: I18n.get("Unable to retrieve cloudwatch metrics") });
                    const timer = setTimeout(() => {
                        self.setState({ error: false });
                        clearTimeout(timer);
                    }, 5000);
                });
            });
        });
    }

    cloudWatchDashboardDataProcess = (dataName, data) => {
        let m = data.map(datapoints =>
            Object.keys(datapoints).map((key) => ({ 'x': parseInt(key), 'y': datapoints[key] })).sort((a, b) => a.x - b.x)
        );
        let lines = []
        for (let i = 0; i < m[0].length; i++) {
            const line = m[1] ? {'x': new Date(m[0][i].x).toLocaleDateString(), 'y0': m[0][i].y, 'y1': m[1][i].y} : {'x': new Date(m[0][i].x).toLocaleDateString(), 'y0': m[0][i].y}
            lines.push(line)
        }
        this.setState({ [`${dataName}Lines`]: lines})
    }

    cloudWatchDashboardAlarmProcess = (dataName, alarm) => {
        this.setState({
            [`${dataName}Alarm`]: alarm,
            [`${dataName}AlarmThreshold`]: alarm.Threshold,
            [`${dataName}AlarmPeriod`]: alarm.Period,
            [`${dataName}OriginalAlarmThreshold`]: alarm.Threshold,
            [`${dataName}OriginalAlarmPeriod`]: alarm.Period
        });
    }

    periodToString = (period) => {
        return this.periods.filter(p => p.key === period)[0].text;
    }

    dailyAverageThreshold = (threshold, period) => threshold * (SEC_PER_DAY / period);

    cancelAlarmEdit = (dataName) => {
        this.setState({
            [`${dataName}AlarmThreshold`]: this.state[`${dataName}OriginalAlarmThreshold`],
            [`${dataName}AlarmPeriod`]: this.state[`${dataName}OriginalAlarmPeriod`],
            [`${dataName}AlarmEdit`]: false,
        });
    }

    submitAlarmEdit = (dataName) => {
        let alarm = this.state[`${dataName}Alarm`];
        alarm.Threshold = this.state[`${dataName}AlarmThreshold`];
        alarm.Period = this.state[`${dataName}AlarmPeriod`];
        this.setState({ [`${dataName}AlarmEditLoading`]: true });
        
        Auth.currentAuthenticatedUser().then(_user => {
            Auth.currentCredentials().then(_credentials => {
                const apiName = 'AudioDownloadAPI';
                const path = "/dashboard/alarms";
                API.put(apiName, path, { response: true, body: {
                    alarm: {
                        AlarmName: alarm.AlarmName,
                        ComparisonOperator: alarm.ComparisonOperator,
                        EvaluationPeriods: alarm.EvaluationPeriods,
                        Namespace: alarm.Namespace,
                        MetricName: alarm.MetricName,
                        Period: alarm.Period,
                        Statistic: alarm.Statistic,
                        Threshold: alarm.Threshold,
                        Unit: alarm.Unit,
                        AlarmActions: alarm.AlarmActions
                    }
                } }).then(response => {
                    if (response.status !== 200)
                        throw Error();
                    this.setState({
                        [`${dataName}Alarm`]: alarm,
                        [`${dataName}OriginalAlarmThreshold`]: this.state[`${dataName}AlarmThreshold`],
                        [`${dataName}OriginalAlarmPeriod`]: this.state[`${dataName}AlarmPeriod`],
                        [`${dataName}AlarmEditLoading`]: false,
                        [`${dataName}AlarmEdit`]: false,
                    });
                })
            });
        });
    }

    getDownloadActivationStatus = () => {
        Auth.currentAuthenticatedUser().then(_user => {
            Auth.currentCredentials().then(_credentials => {
                const apiName = 'AudioDownloadAPI';
                const path = "/dashboard/downloads-activation";
                API.get(apiName, path, { response: true }).then(response => {
                    if (response.status === 200)
                        this.setState({ downloadEnabled: response.data.status === 'true' });
                });
            });
        });
    }

    setDownloadActivationStatus = enabled => {
        this.setState({ downloadActivationChangeLoading: true });
        Auth.currentAuthenticatedUser().then(_user => {
            Auth.currentCredentials().then(_credentials => {
                const apiName = 'AudioDownloadAPI';
                const path = "/dashboard/downloads-activation";
                API.post(apiName, path, { response: true, body: { enabled } }).then(response => {
                    if (response.status === 200)
                        this.setState({ downloadEnabled: enabled, downloadActivationChangeLoading: false });
                });
            });
        });
    }

    render() {

        return (
            <React.Fragment>

                <Segment>

                    <Header as="h3">{I18n.get('Usage Dashboard')}</Header>

                    {(!this.state.loading &&
                        <>

                            <Card style={{ width: 'unset', height: 'unset' }}>
                                <Card.Content>
                                    <Card.Header>{I18n.get('Storage usage')}</Card.Header>
                                    {(this.state.storageLines[0] &&
                                        <Chart
                                            yFormat={d => fileSize(d)}
                                            data={this.state.storageLines}
                                            legends={[I18n.get("Size")]}
                                            crosshairTitleFormat={d => [{ title: 'Date', value: d.x}]}
                                            crosshairItemsFormat={d => [
                                                { title: I18n.get('Size'), value: fileSize(d.y0) },
                                            ]}
                                        />
                                    ) || <Card.Meta>{I18n.get('No storage usage statistics available yet')}</Card.Meta>}
                                </Card.Content>
                            </Card>

                            <Card style={{ width: 'unset', height: 'unset' }}>
                                <Card.Content>
                                    <Card.Header>
                                        {I18n.get('Download Statistics')}
                                        {!this.state.downloadEnabled &&
                                            <Label color='orange' icon='warning sign' content={I18n.get('Downloads disabled')} style={{ marginLeft: '10px' }} />
                                        }
                                    </Card.Header>
                                    <Chart
                                        data={this.state.downloadLines}
                                        crosshairTitleFormat={d => [{ title: 'Date', value: d.x}]}
                                        crosshairItemsFormat={d => [
                                            { title: I18n.get('Downloads'), value: d.y0},
                                            { title: I18n.get('Download Errors'), value: d.y1},
                                            { title: I18n.get('Download limit'), value: d.rl},
                                        ]}
                                        legends={[I18n.get('Downloads'), I18n.get('Download Errors'), I18n.get('Download limit')]}
                                        referenceLines={[
                                            {
                                                value: this.dailyAverageThreshold(this.state.downloadAlarmThreshold, this.state.downloadAlarmPeriod),
                                                color: this.state.downloadAlarmPeriod === SEC_PER_DAY ? 'rgba(255, 0, 0, 0.5)' : 'rgba(255, 150, 0, 0.5)'
                                            }
                                        ]}
                                    />
                                </Card.Content>
                                <Card.Content>
                                    <Card.Header>{I18n.get('Download limit')}</Card.Header>
                                    <Card.Description>
                                        {this.state.downloadAlarmEdit
                                            ? <>
                                                <Form>
                                                    <Form.Group unstackable style={{ alignItems: 'center' }}>
                                                        <Form.Input
                                                            style={{ width: '100px' }}
                                                            placeholder='limit'
                                                            value={this.state.downloadAlarmThreshold}
                                                            type='number'
                                                            onChange={(_e, { value }) => this.setState({ downloadAlarmThreshold: value })}
                                                            disabled={this.state.downloadAlarmEditLoading}
                                                        />
                                                        {I18n.get('downloads per')}
                                                        <Form.Field
                                                            control={Select}
                                                            options={this.periods}
                                                            value={this.state.downloadAlarmPeriod}
                                                            onChange={(_e, { value }) => this.setState({ downloadAlarmPeriod: value })}
                                                            disabled={this.state.downloadAlarmEditLoading}
                                                        />
                                                        <Button.Group size='mini'>
                                                            <Button color='green' icon='check'
                                                                onClick={() => this.submitAlarmEdit('download')}
                                                                loading={this.state.downloadAlarmEditLoading}
                                                                disabled={this.state.downloadAlarmEditLoading}
                                                            />
                                                            <Button icon='cancel'
                                                                onClick={() => this.cancelAlarmEdit('download')}
                                                                disabled={this.state.downloadAlarmEditLoading}
                                                            />
                                                        </Button.Group>
                                                    </Form.Group>
                                                </Form>
                                            </>
                                            : <>
                                                {this.state.downloadAlarmThreshold} {I18n.get('downloads per')} {this.periodToString(this.state.downloadAlarmPeriod)}
                                                <Button
                                                    primary
                                                    compact
                                                    size='mini'
                                                    icon='pencil'
                                                    style={{ marginLeft: '10px' }}
                                                    onClick={() => this.setState({ downloadAlarmEdit: true })}
                                                />
                                            </>
                                        }
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content>
                                    <Card.Header>{I18n.get('Download activation')}</Card.Header>
                                    <Card.Description>
                                        {I18n.get('Downloads are currently')} <span style={{ fontWeight: 'bold' }}>{I18n.get(this.state.downloadEnabled ? 'enabled': 'disabled')}</span>.
                                    </Card.Description>
                                    <Button
                                        style={{ marginTop: '10px' }}
                                        color={this.state.downloadEnabled ? 'red' : 'blue'}
                                        size='small'
                                        content={I18n.get(this.state.downloadEnabled ? 'Disable' : 'Enable')}
                                        onClick={() => this.setDownloadActivationStatus(!this.state.downloadEnabled)}
                                        loading={this.state.downloadActivationChangeLoading}
                                        disabled={this.state.downloadActivationChangeLoading}
                                    />
                                </Card.Content>
                            </Card>

                            <Card style={{ width: 'unset', height: 'unset' }}>
                                <Card.Content>
                                    <Card.Header>{I18n.get('Upload Statistics')}</Card.Header>
                                    <Chart
                                        data={this.state.uploadLines}
                                        crosshairTitleFormat={d => [{ title: 'Date', value: d.x}]}
                                        crosshairItemsFormat={d => [
                                            { title: I18n.get('Uploads'), value: d.y0},
                                            { title: I18n.get('Upload Errors'), value: d.y1},
                                        ]}
                                        legends={[I18n.get('Uploads'), I18n.get('Upload Errors')]}
                                    />
                                </Card.Content>
                            </Card>
                        </>
                    ) || (
                            <Segment style={{ height: "290px", marginTop: "10px", marginBottom: 0, display: "flex", alignItems: "center" }}>
                                <Dimmer active inverted>
                                    <Loader inverted content={I18n.get('Loading usage metrics')} />
                                </Dimmer>
                                <Image fluid src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                            </Segment>
                        )}

                </Segment>
            </React.Fragment>
        );
    }
}

export default UsageDashboard;