
import React, { Component } from 'react';
import { I18n, Hub } from 'aws-amplify';
import Auth from '@aws-amplify/auth'
import { NavLink } from 'react-router-dom';
import logogif from '../images/320px-Logo.gif';
import { config } from '../config'
import { Button,
  Dropdown,
  Icon,
  Image,
  Menu,
  Visibility, } from 'semantic-ui-react';
import CGUModal from './shared/beforeSignin/cgumodal';
  const menuStyle = {
    border: 'none',
    borderRadius: 0,
    marginBottom: '1em',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
    transition: 'box-shadow 0.5s ease, padding 0.5s ease',
    height:"55px",
  }
  
  const fixedMenuStyle = {
    backgroundColor: '#fff',
    border:'none',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.23)',
    height:"55px",
  }
class MyMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientName: '',
      admin: false,
      menuFixed: false
    };
    this.loadUser = this.loadUser.bind(this);
    this.signOut = this.signOut.bind(this);
    Hub.listen('auth', (data) => {
      this.loadUser();
      console.log('A new auth event has happened: ', data.payload.data.username + ' has ' + data.payload.event);
    }, 'navigator');
    this.state = { 
      user: null,
      cguModalOpen: false,
    };

  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser() {
    Auth.currentAuthenticatedUser()
      .then(user => this.setState({ user: user }))
      .catch(err => this.setState({ user: null }));
  }

  signOut() {
    Auth.signOut().then(() => {
      this.setState({authState: 'signIn'});
    }).catch(e => {
      console.log(e);
    });
  }

  getRights() {
    if (this.state.eval === true) return;
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      const cognitoGroups = user.signInUserSession.idToken.payload['cognito:groups'];
      const profile = cognitoGroups && cognitoGroups.filter( x => x === "CM" || x === "FM" || x === "ITAdmin")[0];
      if (profile && profile.localeCompare('ITAdmin') === 0) {

        this.setState({ 'admin': true, 'eval': true, 'profile': profile });

      }

      else if (profile && profile.localeCompare('CM') === 0) {

        this.setState({ 'cm': true, 'eval': true, 'profile': profile });

      }
      
      else {
        this.setState({'profile': profile, 'eval': true });
      }
    }).catch(
      err => console.error('currentAuthenticatedUser: ' + err)
    );
  }
  unStickTopMenu = () => this.setState({ menuFixed: false })
  stickTopMenu = () => this.setState({ menuFixed: true })
  render() {
    const { menuFixed } = this.state
    const { user } = this.state;
    this.getRights();
    
    return (
      <div>
      <Visibility
          onBottomPassed={this.stickTopMenu}
          onBottomVisible={this.unStickTopMenu}
          once={false}
        >
          <Menu
            borderless
            fixed="top"
            color="blue"
            style={menuFixed ? fixedMenuStyle : menuStyle}
          >
              <Menu.Item>
                <Image size='small' src={logogif} className="bgLogo"/>
              </Menu.Item>
              <Menu.Item
                as={NavLink}
                to='/'
                content={I18n.get('Home')}
                name='home'
              />
              { config.DASHBOARD && this.state && this.state.admin &&
              <Menu.Menu>
                <Dropdown text={I18n.get('Dashboard')} className='link item'>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={NavLink}
                      to='/dashboard/costs'
                      content={I18n.get('Costs')}
                      name='dashboard_costs'
                    />
                    <Dropdown.Item
                      as={NavLink}
                      to='/dashboard/usage'
                      content={I18n.get('Usage')}
                      name='dashboard_usage'
                    />
                    <Dropdown.Item
                      as={NavLink}
                      to='/dashboard/performances'
                      content={I18n.get('Performances')}
                      name='dashboard_performances'
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
              }
              {this.state && (this.state.admin || this.state.cm) && 
              <Menu.Item
                as={NavLink}
                to="/admin"
                content={I18n.get('Client Management')}
                name='admin'
              />}

              { config.USER_MANAGEMENT && this.state && this.state.admin && 
              <Menu.Item
                as={NavLink}
                to="/users"
                content={I18n.get('Users Management')}
                name='users'
              />}

              <Menu.Menu position='right'>
                <Dropdown text={user ? `${I18n.get('Hello')} ${user.username} ${this.state.profile ? `/ ${this.state.profile}` : ''}` : ''} pointing className='link item' data-test="nav-right-menu">
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Button name='termsOfUse' color='grey' fluid onClick={() => this.setState({ cguModalOpen: true })} data-test="terms-of-use-button">
                        {I18n.get('Terms of Use')}
                      </Button>
                      <CGUModal
                        open={this.state.cguModalOpen}
                        onClose={() => this.setState({ cguModalOpen: false })}
                      />
                    </Dropdown.Item>
                    <Dropdown.Item>
                    <Button color='blue' icon labelPosition='right' fluid onClick={this.signOut} data-test="sign-out-button">
                      {I18n.get('Sign out')}<Icon name='sign-out' />
                    </Button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
          </Menu>
        </Visibility>

      </div>
    )
  }
}

export default MyMenu;