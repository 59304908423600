import React, { Component } from 'react';
import { Button, Icon, Input, Popup, Label } from 'semantic-ui-react';
import { I18n } from 'aws-amplify';

const colors = [
  'orange',
  'yellow',
  'teal',
  'blue',
  'green',

]

const tagsDivInputContainer = {
  display: 'flex'
}

const tagsInputStyle = {
  borderRadius: ".28571429rem 0 0 .28571429rem"
}

const tagsButtonAdd = {
  borderRadius: "0",
  marginRight: "0"
}

const tagsButtonDelete = {
  borderRadius: "0 .28571429rem .28571429rem 0",
  marginRight: "0"
}


class TagsEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputMode: false,
      tags : this.props.tags
    }
  }


  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  }

  addTags = (recordId) => {
    if(this.state.newTag) {
      let tags = [...this.state.tags]
      var tagsArr = this.state.newTag.split(',').map(x => x.trim()).filter( x => x )
      if (Array.isArray(tags) && tagsArr.length > 0) {
        // handle deduplicate (todo optimisation !)
        tagsArr = [...new Set(tagsArr.concat(tags))];
        this.props.createTags(recordId, tagsArr).then( () => {
          this.setState({ tags: tagsArr})
        })
      }
      this.setState({ newTag: undefined, inputMode : false})
    }
  }

  handleCloseInput = () => {
    this.setState({ newTag: undefined, inputMode : false})

  }

  deleteTag = (i, recordId) => {
    let tags = [...this.state.tags]
    if(tags.length){
      tags.splice(i,1)
    }
    this.props.createTags(recordId, tags).then( () => {
      this.setState({ tags: tags})
    })
    
  }

  render() {

    return (
      <div>
        { this.state.tags && <div>
          { this.state.tags.map((tag, i) => <Label color={colors[i % 5]} size='medium' key={tag} className="tagLabel" >{tag}<Icon name='delete' onClick={ () => this.deleteTag(i, this.props.audio.id) }/></Label>)}

        </div>}
        <div className="newTagInput">
          { !this.state.inputMode && 
          <Button animated='fade' size='mini'  primary onClick={() => this.setState({ inputMode: true })} >
            <Button.Content hidden >{I18n.get('Add')}</Button.Content>
            <Button.Content visible ><Icon name='add' /></Button.Content>
          </Button> }
          { this.state.inputMode && <Popup
            content={I18n.get('Add tags to your audio (If you want to add multiple tag use comma)')}
            trigger={<div style={tagsDivInputContainer}><Input
              icon='tags'
              iconPosition='left'
              placeholder={I18n.get('Enter tags')}
              onChange={this.handleChange}
              name="newTag"
              size='mini'
              style={tagsInputStyle}
              action>
                {/*<input value={this.state.newTag} />*/}
  
              </Input>
              <Button size='mini' icon='add' style={tagsButtonAdd} primary onClick={() => this.addTags(this.props.audio.id)} />
              <Button size='mini' icon='close' style={tagsButtonDelete} negative onClick={() => this.handleCloseInput()} /></div>
            }
          />
          }
          { this.state.inputMode && <br></br>}
        </div>
         

      </div>
    );
  }
}

export default TagsEditor;