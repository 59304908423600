/* eslint-disable */

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
 
const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:b17db926-5eb8-435d-a970-b6ae1e827c73",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_kkyUgDQpI",
    "aws_user_pools_web_client_id": "5lvatojb1526aijlgjrf27cjva",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://wb3xdlwu2bcxlm2ce7ijo6j7ie.appsync-api.ap-south-1.amazonaws.com/graphql",

    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AudioDownloadAPI-test",
            "endpoint": "https://ok7bisr0fb.execute-api.ap-south-1.amazonaws.com/test",
            "region": "ap-south-1"
        }
    ],

    "aws_user_files_s3_bucket": "psm-audio-record-storage-test",
    "aws_user_files_s3_bucket_region": "ap-south-1",
    // "aws_content_delivery_bucket": "webhelp-poc-psma",
    // "aws_content_delivery_bucket_region": "ap-south-1",
    // "aws_content_delivery_url": "http://webhelp-poc-psma.s3-website.eu-west-3.amazonaws.com"
};
 
 
export default awsmobile;

// const awsmobile = {

//     "aws_project_region": "ap-south-1",

//     "aws_cognito_identity_pool_id": "eu-west-3:88319b34-f518-4fe5-9276-9cb53e670fd3",

//     "aws_cognito_region": "ap-south-1",

//     "aws_user_pools_id": "eu-west-3_3O7NcJZ1S",

//     "aws_user_pools_web_client_id": "1307noklo1a57rd814gvk300c4",

//     "oauth": {},

//     "aws_appsync_graphqlEndpoint": "https://k3ctbfdwzrdxpazfne6vo6xyei.appsync-api.eu-west-3.amazonaws.com/graphql",

//     "aws_appsync_region": "ap-south-1",

//     "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",

//     "aws_cloud_logic_custom": [

//         {

//             "name": "AudioDownloadAPI",

//             "endpoint": "https://iay7ki1lva.execute-api.eu-west-3.amazonaws.com/psma", // API GATEWAY STAGING URL

//             "region": "ap-south-1"

//         }

//     ],

//     "aws_user_files_s3_bucket": "audio-record798423cccbcd4c929d9fb0141b4db1e1210456-psma", // S3 bucket with the trigger

//     "aws_user_files_s3_bucket_region": "ap-south-1"

// };