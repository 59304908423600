import { I18n } from 'aws-amplify';
import React, { Component } from 'react';

class TermsOfUse extends Component{

    createMarkup(key) {
        return (
            {__html: I18n.get(key)}
        );
    }

    render() {
        return (
            <div style={{marginLeft:"1cm",marginRight:"1cm",marginTop:"0.5cm",marginBottom:"1cm"}} >
                <div dangerouslySetInnerHTML={this.createMarkup('Terms_of_use_title')} />
                <div dangerouslySetInnerHTML={this.createMarkup('Terms_of_use_notice')} />
                <div dangerouslySetInnerHTML={this.createMarkup('Terms_of_use_article_1')} />
                <div dangerouslySetInnerHTML={this.createMarkup('Terms_of_use_article_2')} />
                <div dangerouslySetInnerHTML={this.createMarkup('Terms_of_use_article_3')} />
                <div dangerouslySetInnerHTML={this.createMarkup('Terms_of_use_article_4')} />
                <div dangerouslySetInnerHTML={this.createMarkup('Terms_of_use_article_5')} />
                <div dangerouslySetInnerHTML={this.createMarkup('Terms_of_use_article_6')} />
                <div dangerouslySetInnerHTML={this.createMarkup('Terms_of_use_article_7')} />
            </div>
        );
    }
}

export default TermsOfUse;