import { graphqlOperation, I18n } from 'aws-amplify';
import API from '@aws-amplify/api'
import React, { Component } from 'react';
import { Button, Checkbox, Table, Popup, Icon } from 'semantic-ui-react';
import { config } from '../../config';
import { formatDateEpochTimeStamp, storageMapping, STORAGE_STATUS } from '../../util/constant';
import filesize from 'filesize';
import AudioPlayer from '../audioplayer';
import ImageViewer from '../imageViewer';
import VideoPlayer from '../videoplayer';
import TagsEditor from '../tagseditor';
import { createTags } from '../../util/query';
import TransferRequestModal from './transferRequestModal';
import S3FileDownloadModal from './s3FileDownloadModal';
import RestoreRequestModal from './restoreRequestModal';
import DeleteModal from './deleteModal';
import { generateFolderPathWithLinks } from '../../util/folder';

const initialState = {
    showId: false,
    selection: [],

    // Permissions
    displayMedia: false,
    download: false,
    delete: false,

    // RestoreRequestModal
    restoreRequestModalOpen: false,
    invalidFilesToRequest: [],

    // TransferRequestModal
    transferRequestModalOpen: false,
    transferFullFolder: false,

    // DownloadModal
    downloadModalOpen: false,

    // DeleteModal
    deleteModalOpen: false,
}

/**
 * FileList component props:
 * 
 *     profile: "Role (ITAdmin, CM, FM)",
 *     client: {Client object},
 *     folder: "folderName",
 *     files: [{files objects}],
 *     hasSubfolders: boolean,
 *     isSearch: boolean,
 *     retrieveMoreFiles: function,
 *     deleteFiles: function([{file Objects}]),
 */
class FileList extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        const { profile } = this.props;
        this.setState({
            displayMedia: ['ITAdmin', 'CM', 'FM'].includes(profile),
            download: ['ITAdmin', 'CM','FM'].includes(profile),
            delete: ['ITAdmin', 'CM'].includes(profile),
        });
    }

    deleteType = (type) => {
        var array = [...this.state.myRetentionList]
        var index = array.indexOf(type)
        array.splice(index, 1)
        this.setState({myRetentionList: array})
    }

    allFilesSelected = () => this.props.files.every(file => this.state.selection.includes(file));
    areThereFilesSelected = () => this.state.selection.length > 0;

    selectedFolders = () => this.state.selection.reduce((selectedFolders, file) => {
        selectedFolders[file.folder] ? selectedFolders[file.folder] += 1 : selectedFolders[file.folder] = 1;
        return selectedFolders;
    }, {});

    createTags = (fileId, tags) => new Promise(async (resolve, reject) => {
        if (tags) {
            const result = await API.graphql(graphqlOperation(createTags(tags, fileId, this.props.client.id)));
            resolve(result);
        } else reject();
    });

    checkForInvalidFiles = files => files.filter(file => file.fileState === STORAGE_STATUS.glacier);
    handleInvalidFiles = invalidFiles => this.setState({ restoreRequestModalOpen: true, invalidFilesToRequest: invalidFiles });

    checkAndHandleInvalidFiles = files => {
        const invalidFiles = this.checkForInvalidFiles(files);
        if (invalidFiles.length > 0) {
            this.handleInvalidFiles(invalidFiles);
            return false;
        }
        return true;
    }

    clearRestoreRequest = () => this.setState({ restoreRequestModalOpen: false, invalidFilesToRequest: [] });

    render() {
        return (
            <>
                <RestoreRequestModal
                    open={this.state.restoreRequestModalOpen}
                    client={this.props.client}
                    folder={this.props.folder}
                    invalidFiles={this.state.invalidFilesToRequest}
                    onClose={this.clearRestoreRequest}
                />

                <div style={{ width: "100%", overflowX: "auto" }}>
                    <Table basic='very' striped>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    <Checkbox
                                        checked={this.allFilesSelected()}
                                        indeterminate={this.areThereFilesSelected() && !this.allFilesSelected()}
                                        onChange={(_e, data) => data.checked
                                            ? this.setState({ selection: this.props.files })
                                            : this.setState({ selection: [] })
                                        }
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>{I18n.get('Filename')}</Table.HeaderCell>
                                <Table.HeaderCell>{I18n.get('Record Type')}</Table.HeaderCell>
                                <Table.HeaderCell>{I18n.get('Created at')}</Table.HeaderCell>
                                <Table.HeaderCell>{I18n.get('File Size')}</Table.HeaderCell>
                                {config.MEDIA_PREVIEW && this.state.displayMedia &&
                                    <Table.HeaderCell>{I18n.get('Media')}</Table.HeaderCell>
                                }

                                <Table.HeaderCell>{I18n.get('Tags')}</Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Button
                                        floated='right'
                                        onClick={() => this.setState({ showId: !this.state.showId })}
                                        primary
                                        size='small'
                                    >
                                        {I18n.get(this.state.showId ? 'Hide Id' : 'Show Id')}
                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.props.files.map((file, index) => (
                                <Table.Row key={file.id}>
                                    <Table.Cell>
                                        <Checkbox
                                            checked={this.state.selection.includes(file)}
                                            onChange={(_e, data) => data.checked
                                                ? this.setState({ selection: [...this.state.selection, file] })
                                                : this.setState({ selection: this.state.selection.filter(f => f !== file) })
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell className={file.fileState === STORAGE_STATUS.glacier ? "archivedText" : ""}>
                                        {file.fileState === STORAGE_STATUS.glacier &&
                                            <Popup size='mini' content={`${I18n.get('Archived')} ${I18n.get('since')} ${new Date(file.statusDate)}`} trigger={
                                                <Icon name='snowflake outline' />
                                            } />
                                        }
                                        {file.fileState === STORAGE_STATUS.glacierRestored &&
                                            <Popup size='mini' content={`${I18n.get('Restored')} ${I18n.get('until')} ${new Date(file.statusDate)}`} trigger={
                                                <Icon name='clock outline' color='blue' />
                                            } />
                                        }
                                        {this.props.isSearch && !file.folderExists &&
                                            <Popup size='mini' content={I18n.get('Missing folder')} trigger={
                                                <Icon name='warning sign' color='orange' />
                                            } />
                                        }
                                        {this.props.isSearch
                                            ? <>{generateFolderPathWithLinks(file.folder, this.props.client.id)} {file.filename}</>
                                            : file.filename
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        {file.recordType.toString().charAt(0).toUpperCase()+file.recordType.toString().slice(1)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatDateEpochTimeStamp(file.createdAt * 1000,I18n.get('dateFormat'))}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {filesize(file.fullsize.size)}
                                    </Table.Cell>
                                    {config.MEDIA_PREVIEW && (this.state.displayMedia || storageMapping[file.recordType] === "image") &&
                                        <Table.Cell>
                                            {<>
                                                {storageMapping[file.recordType] === "audio" &&
                                                    <AudioPlayer key={file.id} audio={file} valkey={file.fullsize.key.replace('public/', '')} fileAvailable={file.fileState === STORAGE_STATUS.normal || file.fileState === STORAGE_STATUS.glacierRestored} />
                                                }
                                                {storageMapping[file.recordType] === "image" &&
                                                    <ImageViewer key={file.id} image={file} fileAvailable={file.fileState === STORAGE_STATUS.normal || file.fileState === STORAGE_STATUS.glacierRestored} />
                                                }
                                                {storageMapping[file.recordType] === "video" &&
                                                    <VideoPlayer key={file.id} video={file} fileAvailable={file.fileState === STORAGE_STATUS.normal || file.fileState === STORAGE_STATUS.glacierRestored} />
                                                }
                                                {storageMapping[file.recordType] === "application" &&
                                                    <span>No preview</span>
                                                }
                                            </>}
                                        </Table.Cell>
                                    }
                                    <Table.Cell>
                                        <div>
                                            <TagsEditor tags={file.tags || []} createTags={this.createTags} audio={file} index={index}></TagsEditor>
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>

                                        {this.state.showId && file.id}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>

                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan='8'>
                                    {config.FUNC_TRANSFERT &&
                                        <div>
                                            <Button
                                                disabled={Object.keys(this.selectedFolders()).length !== 1}
                                                icon='share alternate'
                                                labelPosition='left'
                                                content={I18n.get('Request Selected Files')}
                                                floated='right'
                                                primary
                                                size='small'
                                                onClick={() => this.checkAndHandleInvalidFiles(this.state.selection) &&
                                                    this.setState({ transferRequestModalOpen: true, transferFullFolder: false })
                                                }
                                            />
                                            <Button
                                                disabled={this.props.files.length === 0 && !this.props.hasSubFolders}
                                                icon='share alternate'
                                                labelPosition='left'
                                                content={I18n.get('Request Full Folder')}
                                                floated='right'
                                                primary
                                                size='small'
                                                onClick={() => this.checkAndHandleInvalidFiles(this.props.files) &&
                                                    this.setState({ transferRequestModalOpen: true, transferFullFolder: true })
                                                }
                                            />
                                            <TransferRequestModal
                                                open={this.state.transferRequestModalOpen}
                                                client={this.props.client}
                                                folder={this.props.folder}
                                                fullFolder={this.state.transferFullFolder}
                                                files={this.state.selection}
                                                onClose={() => this.setState({ transferRequestModalOpen: false })}
                                            />
                                        </div>
                                    }
                                    {this.state.download && (
                                        <>
                                            <Button
                                                primary
                                                size='small'
                                                floated='right'
                                                icon='download'
                                                labelPosition='left'
                                                disabled={this.state.selection.length === 0}
                                                content={I18n.get('Download Selected')}
                                                onClick={() => this.checkAndHandleInvalidFiles(this.state.selection) &&
                                                    this.setState({ downloadModalOpen: true })
                                                }
                                            />
                                            <S3FileDownloadModal
                                                open={this.state.downloadModalOpen}
                                                files={this.state.selection}
                                                onClose={() => this.setState({ downloadModalOpen: false })}
                                            />
                                        </>
                                    )}
                                    {this.state.delete && (
                                        <>
                                            <Button
                                            floated='right'
                                            disabled={this.state.selection.length === 0}
                                            icon='delete'
                                            labelPosition='left'
                                            content={I18n.get('Delete Selected')}
                                            onClick={() => this.setState({ deleteModalOpen: true })}
                                            color='red'
                                            size='small'
                                            />
                                            <DeleteModal
                                                open={this.state.deleteModalOpen}
                                                client={this.props.client}
                                                files={this.state.selection}
                                                deleteFiles={files => {
                                                    this.setState({ selection: this.state.selection.filter(file => !files.includes(file)) });
                                                    this.props.deleteFiles(files);
                                                }}
                                                onClose={() => this.setState({ deleteModalOpen: false })}
                                            />
                                        </>
                                    )}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </div>
            </>
        );
    }
}

export default FileList;