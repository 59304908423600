import React, { Component } from 'react';
import { Modal, Button, Card, Message } from 'semantic-ui-react';
import { I18n } from 'aws-amplify';
import Auth from '@aws-amplify/auth'
import { getAudioLinkFromAPI } from '../../util/api';

const initialState = {
    loadingSeparate: false,
    loadingZipped: false,
    downloadType: null,
    error: null,
}

/**
 * S3FileDownloadModal component props:
 *     
 *     open: boolean,
 *     files: [{file Objects}],
 *     onClose: function,
 */
class S3FileDownloadModal extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    onModalOpen = () => {
        this.setState({ ...initialState });
        this.props.files.forEach(file => delete file.error);
        if (this.props.files.length === 1)
            this.download(this.downloadSeparately)
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open)
            this.onModalOpen();
    }

    downloadSeparately = user => {
        this.setState({ loadingSeparate: true, downloadType: 'separate' });
        Promise.all(this.props.files.map(file =>
            getAudioLinkFromAPI(file.id, user, false, true)
                .then(url => {
                    // This trick makes the browser download multiple files without having to
                    // open multiple blank tabs, which would cause it to ask for permission to do so
                    const frame = document.createElement('frame');
                    frame.style.display = 'none';
                    frame.setAttribute('src', url);
                    document.body.appendChild(frame);
                    setTimeout(f => f.remove(), 2000, frame);
                })
                // When downloading separately, we catch errors independently so we don't fail all the other files
                .catch(err => {
                    file.error = true;
                    if (!this.state.error) {
                        this.setState({
                            error: err.response.status === 429
                                ? I18n.get("Downloads quota exceeded, contact your administrator")
                                : I18n.get("Some files could not be downloaded, try again later"),
                            quotaError: err.response.status === 429,
                        });
                    }
                })
        )).then(() => {
            this.setState({ loadingSeparate: false });
        });
    }

    downloadZipped = user => {
        this.setState({ loadingZipped: true });
        getAudioLinkFromAPI(this.props.files.map(file => file.id), user, false, true)
            .then(url => {
                window.location.assign(url);
                this.props.onClose();
            })
            .catch(err => {
                this.setState({
                    error: err.response.status === 429
                        ? I18n.get("Downloads quota exceeded, contact your administrator")
                        : I18n.get("Could not download zipped file, try again later"),
                    quotaError: err.response.status === 429,
                    loadingZipped: false
                });
            });
    }

    download = downloadFunction => {
        Auth.currentAuthenticatedUser().then(user => {
            Auth.currentCredentials().then(_credentials => {
                downloadFunction(user);
            });
        });
    }

    render() {
        const unique = this.props.files.length === 1;
        return (
            <Modal
                as={Card}
                style={{ width: 'unset', height: 'unset' }}
                dimmer='blurring'
                closeOnDimmerClick={!(this.state.loadingSeparate || this.state.loadingZipped)}
                closeIcon={!(this.state.loadingSeparate || this.state.loadingZipped)}
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <Card.Content>
                    <Card.Header>{I18n.get(unique ? "File download" : "Choose download type")}</Card.Header>
                    {!unique && <>
                        <Card.Meta>{I18n.get("Multi-file download")}</Card.Meta>
                        <Card.Description>
                            {I18n.get("When downloading multiple files, you can either get them in a single ZIP archive, or download them separately.")}
                        </Card.Description>
                    </>}
                    
                    {this.state.error && !this.state.loadingSeparate && 
                        <Message error>
                            <Message.Header>{this.state.error}</Message.Header>
                            {this.state.downloadType === 'separate' && !this.state.quotaError &&
                                <Message.List>
                                    {this.props.files.filter(f => f.error).map(file =>
                                        <Message.Item key={file.id}>{file.filename}</Message.Item>
                                    )}
                                </Message.List>
                            }
                        </Message>
                    }
                </Card.Content>
                {(!unique || this.state.loadingSeparate) &&
                    <Card.Content extra textAlign='center'>
                        <Button.Group>
                            <Button
                                onClick={() => this.download(this.downloadSeparately)}
                                disabled={this.state.loadingSeparate || this.state.loadingZipped || this.state.error !== null}
                                loading={this.state.loadingSeparate}
                            >
                                {I18n.get("Download files seperately")}
                            </Button>
                            {!unique &&
                                <Button
                                    primary
                                    onClick={() => this.download(this.downloadZipped)}
                                    disabled={this.state.loadingSeparate || this.state.loadingZipped || this.state.error !== null}
                                    loading={this.state.loadingZipped}
                                >
                                    {I18n.get("Download zipped archive")}
                                </Button>
                            }
                        </Button.Group>
                    </Card.Content>
                }
            </Modal>
        );
    }
}

export default S3FileDownloadModal;