import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import aws_exports from '../aws-exports';
import {Button, Message} from 'semantic-ui-react';
import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import { I18n } from 'aws-amplify';
import { getAudioLinkFromAPI } from '../util/api';

Amplify.configure(aws_exports);

class VideoPlayer extends Component {
  constructor (props) {
    super(props);
    this.state= {placeholder: true, showDownloadDisabledError: false};
  }
  
  async getVideoSource() {
    if (this.state && this.state.src) return;
    const self = this;
    //console.log(util.inspect(self.props, {showHidden: false, depth: 5}));
    // plug here the call to lambda
    if (this.state && this.state.src) return ;
    self.loadSignedUrlDownload(this.props.video.id)
  };

  async loadSignedUrlDownload (srcKey)  {
    const self = this

    Auth.currentAuthenticatedUser().then (value => {
      //console.log(util.inspect(value.getSignInUserSession().getAccessToken(), {showHidden: false, depth: 5}));
      Auth.currentCredentials()
        .then(credentials => {
          //console.log(util.inspect(credentials, {showHidden: false, depth: 5}));
          getAudioLinkFromAPI(srcKey,value,true).then(link => {
            self.setState({url:link})
            self.setState({showButton:true})
          }).catch(err => {
            if (err.response.status === 429) { // Downloads disabled
              this.setState({ showDownloadDisabledError: true });
              setTimeout(() => this.setState({ showDownloadDisabledError: false }), 5000);
              return;
            }
          })
        });
      });
  } 

  handleSubmit = async (event) => {
    this.getVideoSource();
  }

  render() {
    
    return (
      <React.Fragment>
        { this.props.fileAvailable  && <Button primary inverted fluid
                  content={I18n.get('Open Video player')}
                  onClick= {this.handleSubmit}
                  name='Audio'
                  style={{visibility: this.state.showButton ? 'hidden' :  'visible' }}  />
        }
        {this.state && this.state.url &&
            <ReactPlayer className="videoFrame" ref="video_tag" url={this.state.url} playing controls={true}  width="180px" height="120px" volume={0.1} />
          
        }
        {this.state.showDownloadDisabledError &&
            <Message error size='tiny'>
              <Message.Header>{I18n.get("Downloads quota exceeded, contact your administrator")}</Message.Header>
            </Message>
        }
      </React.Fragment>
    );
  }
}
  
export default VideoPlayer;