export const dictionary = {
    'fr': {
        'dateFormat':'DD/MM/YYYY HH:mm',
        'Company':"Nom de la compagnie",
        'Home': "Accueil",
        'Client Management': "Gestion des clients",
        'Loading Client Details': "Chargement des détails du Client ",
        'Client name': "Nom du client",
        'Type': "Type",
        'Confirm Password': "Confirmer le mot de passe",
        'Types': "Type(s)",
        'Retentions': "Rétention(s)",
        'Retention': "Rétention",
        'Duration': "Durée de l'enregistrement",
        'Verifing': "Vérification",
        'Folder name': "Nom de dossier",
        'User deleted successfully': "L'utilisateur a été supprimé avec succès!",
        'Add folder': "Ajout de dossier",
        'Add folder to root': "Ajouter un dossier à la racine",
        'Add new folder' : "Ajouter un nouveau dossier",
        'Rename folder' : "Renommer le dossier",
        'Remove folder': "Retrait de dossier",
        'Update client': "Mise à jour du client",
        'Username already exists': "Ce nom d'utilisateur existe déjà",
        'Record date': "Date d'enregistrement",
        'Confirm password': "Confirmer le mot de passe",
        'Last update at': "Dernière mise à jour le",
        'Add retention rules': "Ajouter des règles de rétention",
        'Add root folders': "Ajouter des dossiers à la racine",
        'Retention (days)': "Rétention (jours)",
        'Add retention': "Ajouter une règle de rétention",
        'Delete retention': 'Supprimer la règle de rétention',
        'Delete folder': 'Supprimer le dossier',
        'Edit': "Editer",
        'at': "à",
        'on': "le",
        'Filename': "Nom du fichier",
        'The username should either be a string or one of the sign in types': "Veuillez saisir votre nom d'utilisateur.",
        'Custom auth lambda trigger is not configured for the user pool.': "Veuillez saisir votre mot de passe.",
        'User does not exist.': "L'utilisateur n'existe pas.",
        'Password cannot be empty': "Le mot de passe ne peut pas être vide",
        'Email cannot be empty': "L'adresse mail ne peut pas être vide",
        'Invalid email address format.': "Format d'adresse mail non valide.",
        'Your passwords must match': "Vos mots de passe doivent correspondre",
        'Are you sure you want to delete the user': "Etes-vous sûr de vouloir supprimer l'utilisateur",
        'A client attempted to write unauthorized attribute': "Un client a tenté d'écrire un attribut non autorisé",
        'Confirm Signup': "Confirmer l'inscription",
        'Your code is on the way. To log in, enter the code we emailed to': "Votre code est en route. Pour vous connecter, entrez le code que nous avons envoyé par e-mail à",
        'It may take a minute to arrive.': "Il peut prendre une minute pour arriver.",
        'Confirming': "Confirmation",
        'Password did not conform with policy: Password not long enough': "Le mot de passe n'est pas assez long.",
        'Password did not conform with policy: Password must have uppercase characters': "Le mot de passe doit comporter des caractères majuscules.",
        'Password did not conform with policy: Password must have numeric characters': "Le mot de passe doit comporter des caractères numériques.",
        'Password did not conform with policy: Password must have symbol characters': "Le mot de passe doit comporter des caractères spéciaux.",
        "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern:": "Le mot de passe doit contenir au moins 8 caractères.",
        'Record Type': "Type d'enregistrement",
        'Created at': "Créé le",
        'Sending': "Envoie du code",
        'Attempt limit exceeded, please try after some time.': "La limite d'essai est dépassée, veuillez réessayer après un certain temps.",
        'Incorrect username or password.': "Nom d'utilisateur ou mot de passe incorrect.",
        'Enter your confirmation code': "Saisissez votre code de vérification",
        'Invalid verification code provided, please try again.': "Le code de vérification fourni est invalide, veuillez réessayer.",
        'Company name': "Nom de l'entreprise",
        'Enter your company name': "Saisissez le nom de votre entreprise",
        'Please accept the terms and conditions of use.': "Veuillez accepter les conditions générales d'utilisation.",
        'Unable to retrieve costs': "Impossible de récupérer les coûts",
        'Audio': "Audio",
        'Profile': "Profil",
        'Confirm': "Confirmer",
        'Unable to retrieve cloudwatch metrics': "Impossible de récupérer les métriques de cloudwatch",
        'Restore': "Restaurer",
        'Retry': "Réessayer",
        'Issue with client informations!': "Problème avec les informations du client !",
        'Lost your code?': "Vous avez perdu votre code ?",
        'Enter your code': "Entrez le code",
        'Resend Code': "Renvoyer le code",
        'Your transfert request has been created.': "Votre demande de transfert a été créée.",
        'Password not long enough. It should contain more than 8 characters.': "Le mot de passe n'est pas assez long. Il doit contenir plus de 8 caractères.",
        'Invalid email address format': "Format d'adresse électronique non valide",
        'Media': "Media",
        'Confirmation Code': "Code de confirmation",
        'Tags': "Etiquettes",
        'Password': "Mot de passe",
        'No clients found': "Aucun client trouvé",
        'Enter tags': "Ajout d'étiquette",
        'Add tags to your audio (If you want to add multiple tag use comma)': "Ajouter une étiquette au fichier (séparée par une virgule pour en mettre plusieurs)",
        'Request Selected Files': "Demander les fichiers sélectionnés",
	    'Add other retention': "Rajouter d'autres règles de rétention", 
        'Request Full Folder': "Demander le transfert du dossier",
        'Folder' : "Dossier",
        'Select any of the transfert mode:': "Choisir un mode de transfert",
        'Sftp': "Sftp",
        'Url': "Url",
        'Invalid username format': "Format de nom d'utilisateur non valide",
        'Download Selected': "Télécharger les fichiers sélectionnés",
        'Audio player': "Lecteur audio",
        'Open Audio player': "Ouvrir le lecteur audio",
        'Open Video player': "Ouvrir le lecteur vidéo",
        'Click to view image in full size': "Cliquez pour voir l'image en taille réelle",
        'Preview of the record': "Aperçu de l'enregistrement",
        'Loading usage metrics': "Chargement des métriques d'utilisation",
        'No file found matching': "Pas de fichier correspondant",
        'Loading...': "Chargement...",
        'Load more files': "Charger plus de fichiers",
        'My Clients': "Mes clients",
        'Transfert Request': "Demande de transfert",
        'Hello': "Bonjour",
        'You must enter Client Name to create a new client!': "Entrer un nom de client afin de le créer!",
        'New Client Name': "Nouveau nom de client",
        'Back to Client list': "Retour à la liste client",
        'Back to Client Management': "Retour à la gestion des clients",
        'My Record Type': "Mon type d'enregistrement",
        'Uploading...': "Chargement...",
        'Add audio': "Ajout d'un audio",
        'Add image': "Ajout d'une image",
        'Add video': "Ajout d'une vidéo",
        'Add application': "Ajout d'une application",
        'Tag name': "Etiquette",
        'Creation date from': "De",
        'Creation date to': "Jusqu'à",
        'Search': "Rechercher",
        'Just one second': "Une seconde SVP",
        'We are fetching trasnsfert request for you.': "En train de charger les demandes de transfert.",
        'No transfert request found!': "Pas de demande de transfert trouvée!",
        'Id': "Id",
        'UserId': "ID de l'utilisateur",
        'UserName': "Nom de l'utilisateur",
        'Mode': "Mode",
        'Requested Audios': "Fichiers demandés",
        'Requested Medias': "Fichiers demandés",
        'Status': "Status",
        'Action': "Action",
        'Full folder': "Dossier complet",
        'Accept': "Accepter",
        'Reject': "Refuser",
        'Load More Transfert Request': "Charger plus de demande de transfert",
        'Create':"Créer",
        'Show audios':"Afficher les fichiers",
        'Back to folder list':"Retour à la liste des dossiers",
        'Create a new client':"Créer un nouveau client",
        'Create client':"Créer un client",
        'Edit client':"Modifier le client",
        'Created':"Créé",
        'Completed':"Terminé",
        'Rejected':"Rejeté",
        'Restore In Progress': 'Restauration en cours',
        'On Hold': 'En attente de validation',
        'Access Denied':"Accès refusé",
        'You are':"Tu es",
        'not authorized':"pas autorisé",
        'to access this resource.':"à accéder à cette ressource.",
        'No folder found for the client':"Aucun dossier trouvé pour le client",
        'Your account has not been validated by your company':"Votre compte n'a pas été validé par votre entreprise",
        'Please contact your administrator.':"Veuillez contacter votre administrateur",
        'Loading Folder':"Chargement des dossiers",
        'Created client with name':"Client créé avec nom ",
        "Your transfert request has been executed. You'll received an email with":"Votre demande de transfert a été exécutée. Vous recevrez un e-mail avec",
        'Error':"Erreur",
        'Your transfert request has been rejected.':"Votre demande de transfert a été rejetée.",
        'An error occured while executing your transfert request.':"Une erreur s'est produite lors de l'exécution de votre demande de transfert.",
        'Audio Duration':"Durée audio (en secondes)",
        'Audio Duration From':"Durée audio de",
        'Audio Duration To':"Durée audio au",
        'Upload now':"Télécharger maintenant",
        'Import CSV File':'Importer un fichier CSV',
        'Select any of the transfert mode to upload:':"Choisissez un mode de transfert à télécharger:",
        'Your transfer request has been created.':"Votre demande de transfert a été créée avec succès.",
        'Sign out': "Déconnexion",
        'Sign In': 'Se connecter',
		'Sign Up': "S'inscrire",
        'No storage usage statistics available yet': "Aucune statistique d'utilisation du stockage n'est encore disponible",
		'Sign Out': 'Déconnexion',
		'Forgot Password': 'Mot de passe oublié',
		'Username': "Nom d'utilisateur",
		'Change Password': 'Changer le mot de passe',
		'New Password': 'nouveau mot de passe',
		'Email': 'Email',
		'Phone Number': 'Numéro de téléphone',
		'Confirm a Code': 'Confirmer un code',
		'Confirm Sign In': 'Confirmer la connexion',
		'Confirm Sign Up': "Confirmer l'inscription",
		'Back to Sign In': 'Retour à la connexion',
		'Send Code': 'Envoyer le code',
		'Resend a Code': 'Renvoyer un code',
		'Submit': 'Soumettre',
		'Skip': 'Sauter',
		'Verify': 'Vérifier',
		'Verify Contact': 'Vérifier le contact',
		'Code': 'Code',
		'Account recovery requires verified contact information': 'La récupération du compte nécessite des informations de contact vérifiées',
		'User does not exist': "L'utilisateur n'existe pas",
		'User already exists': "L'utilisateur existe déjà",
		'Incorrect username or password': 'identifiant ou mot de passe incorrect',
		'Invalid password format': 'format de mot de passe invalide',
		'Invalid phone number format': `Format de numéro de téléphone invalide.
        Veuillez utiliser un format de numéro de téléphone du +12345678900`,
		'Sign in to your account': 'Connectez-vous à votre compte',
		'Forget your password? ': 'Mot de passe oublié ? ',
		'Reset password': 'Réinitialisez votre mot de passe',
		'No account? ': 'Pas de compte ? ',
		'Create account': 'Créer un compte',
		'Create Account': 'Créer un compte',
		'Have an account? ': 'Déjà un compte ?',
		'Sign in': 'Se connecter',
		'Create a new account': 'Créer un nouveau compte',
		'Reset your password': 'Réinitialisez votre mot de passe',
		'Enter your username': "Saisissez votre nom d'utilisateur",
		'Enter your password': 'Saisissez votre mot de passe',
		'An account with the given email already exists.':
		'Un utilisateur avec cette adresse email existe déjà.',
        'Username cannot be empty': "Le nom d'utilisateur doit être renseigné",
        'Error : expected format : Client ID, Folder ID, AudioRecord ID':"Erreur : format attendu : Client ID, Folder ID, Record ID",
        'File Size': "Taille du fichier",
        'Updated client success': 'Client modifié avec succès',
        'Cost ($)': 'Coût ($)',
        'Upload': 'Téléverser',
        'Upload new files': 'Téléverser un (plusieurs) fichier(s)',
        'Folders List': 'Liste des dossiers',
        'Add': 'Ajouter',
        'Rename': 'Renommer',
        'Delete': 'Supprimer',
        'Users Management': 'Gestion des utilisateurs',
        'Users list': "Liste d'utilisateurs",
        'Add new user': 'Ajouter un nouveau utilisateur',
        'Add user': 'Ajouter l\'utilisateur',
        'Edit user': 'Modifier l\'utilisateur',
        'None': 'Aucun',
        'Cancel': 'Annuler',
        'Delete User?': 'Suprimmer l\'utilisateur?',
        'Are you sure you want to delete the user?': 'Etes-vous sûr(e) de vouloir supprimer l\'utilisateur?',
        'Enable': 'Activer',
        'Disable': 'Désactiver',
        'User edited successfully': 'L\'utilisateur a été modifié avec succès!',
        'User added successfully': 'L\'utilisateur a été ajouté avec succès!',
        'Save modifications': 'Enregistrer les modifications',
        'Files downloads': 'Téléchargements de fichiers',
        'Number of days that the restored files will be available': 'Le nombre de jours durant lesquels les fichiers restaurés seront disponibles',
        'Archived': 'Archivé',
        'Restored': 'Restauré',
        'until': 'jusqu\'au',
        'since': 'depuis',
        'The following files are archived, and therefore cannot be accessed directly.': 'Les fichiers ci-dessous sont archivés et ne peuvent pas être accessibles sans restauration.',
        'Would you like to request a restore for these files?': 'Voulez-vous demander une restauration pour ces fichiers?',
        'Issue with the selected file(s)': 'Problème avec le(s) fichier(s) demandé(s)',
        'In Progress': 'En cours',
        'Please enter a valid number !': 'Merci de mettre un nombre valide',
        "Sorry, we're having trouble accessing the file(s) you requested. Please refresh the page and try again in a few minutes.": "On a eu des problèmes en accédant à vos fichiers et nous en sommes navrés. Veuillez actualiser la page et réessayer dans quelques minutes.",
        'Transfer': 'Transfert',
        'Storage': 'Stockage',
        'Other': 'Autre',
        'Total cost': 'Coût total',
        'Loading costs metrics': 'Chargement des métriques de coûts',
        'Loading storage metrics': 'Chargement des métriques de stockage',
        'Loading downloads metrics': 'Chargement des métriques de téléchargements',
        'Budgets': 'Budgets',
        'Loading budgets': 'Chargement des budgets',
        'Daily budget': 'Budget quotidien',
        'Monthly budget': 'Budget mensuel',
        'Yearly budget': 'Budget annuel',
        'Spent today:': 'Dépensé aujourd\'hui :',
        'Spent this month:': 'Dépensé ce mois :',
        'Spent this year:': 'Dépensé cette année :',
        'Anomalies in the last 30 days': 'Anomalies dans les 30 derniers jours',
        'Anomalies in the last 12 months': 'Anomalies dans les 12 derniers mois',
        'DAILY budget limit:': 'Limite quotidienne :',
        'MONTHLY budget limit:': 'Limite mensuelle :',
        'ANNUALLY budget limit:': 'Limite annuelle :',
        'Unable to retrieve budgets': 'Impossible de récupérer les budgets',
        'Unable to update budget': 'Impossible de mettre à jour le budget',
        'Updating budget': 'Mise à jour du budget',
        'Budget update failed!': 'Erreur de mise à jour du budget !',
        'Budget limit': 'Limite du budget',
        'Update': 'Mettre à jour',
        'Upload failed!': 'Erreur lors du téléchargement!',
        'Upload failed due to a network error!': 'Erreur réseau lors du téléchargement!',
        'Upload successful': 'Téléchargement réussi',
        'notification(s) setup': 'notification(s) configurée(s)',
        'Notifications': 'Notifications',
        'Threshold': 'Seuil',
        'Emails': 'Emails',
        'Add notification': 'Ajouter une notification',
        'Some files could not be deleted, try again later': 'Certains fichiers n\'ont pas pu être supprimés, réessayez plus tard',
        'Confirm deletion': 'Confirmer la suppression',
        'Confirm deletion of these {count} files?': 'Confirmer la suppression de ces {count} fichiers ?',
        'Issue with the requested file(s)': 'Issue with the requested file(s)',
        'Hide Id': 'Masquer les ID',
        'Show Id': 'Afficher les ID',
        'Delete Selected': 'Supprimer la sélection',
        'Missing folder': 'Dossier manquant',
        'Create folder': 'Créer le dossier',
        'Transfer Request': 'Requêtes de transfert',
        'Could not load client details!': 'Impossible de charger les détails du client !',
        'Could not load files!': 'Impossible de charger les fichiers !',
        'Searching files...': 'Recherche de fichiers...',
        'Loading files...': 'Chargement des fichiers...',
        'Select transfert mode': 'Sélectionnez le mode de transfert',
        'File download': 'Téléchargement d\'un fichier',
        'Choose download type': 'Choisissez le type de téléchargement',
        'Multi-file download': 'Téléchargement multi-fichiers',
        'When downloading multiple files, you can either get them in a single ZIP archive, or download them separately.': 'Lorsque vous téléchargez plusieurs fichiers, vous pouvez soit les récupérer dans une seule archive ZIP, soit les télécharger séparément.',
        'Download files seperately': 'Télécharger les fichiers séparément',
        'Download zipped archive': 'Télécharger une archive ZIP',
        'Some files could not be downloaded, try again later': 'Certains fichiers n\'ont pas pu être téléchargés, réessayez plus tard',
        'Could not download zipped file, try again later': 'Impossible de télécharger le fichier ZIP, réessayez plus tard',
        'Could not create the folder': 'Impossible de créer le dossier',
        'Creating a new folder in': 'Création d\'un nouveau dossier dans',
        'Creating a new folder in root': 'Création d\'un nouveau dossier à la racine',
        'This folder already exists': 'Ce dossier existe déjà',
        'Could not delete the folder': 'Impossible de supprimer ce dossier',
        'Deleting': 'Suppression de',
        'Warning': 'Attention',
        'Deleting folder will permanently delete content if none are locked.': 'Supprimer ce dossier va définitivement supprimer son contenu s\'il n\'est pas verrouillé.',
        'Could not rename the folder': 'Impossible de renommer ce dossier',
        'Renaming': 'Renommage de',
        'Renaming folder will not move content.': 'Renommer un dossier ne bougera pas son contenu.',
        'Dashboard': 'Tableau de bord',
        'Costs': 'Coûts',
        'Costs Dashboard': 'Tableau de bord des coûts',
        'Usage': 'Utilisation',
        'Usage Dashboard': 'Tableau de bord d\'utilisation',
        'Size': 'Taille',
        'Storage Size': 'Taille du stockage',
        'Storage usage': 'Stockage utilisé',
        'Download Statistics': 'Statistiques de téléchargements',
        'Downloads': 'Téléchargements',
        'Download Errors': 'Erreurs de téléchargement',
        'Download limit': 'Limite de téléchargements',
        'downloads per': 'téléchargements par',
        'Upload Statistics': 'Statistiques de mises en ligne',
        'Uploads': 'Mises en ligne',
        'Upload Errors': 'Erreurs de mise en ligne',
        'Upload limit': 'Limite de mises en ligne',
        'uploads per': 'mises en ligne par',
        'day': 'jour',
        'hour': 'heure',
        'minute': 'minute',
        'Downloads quota exceeded, contact your administrator': 'Quota de téléchargements dépassé, contactez votre administrateur',
        'Downloads disabled': 'Téléchargements désactivés',
        'Download activation': 'Activation des téléchargements',
        'enabled': 'activés',
        'disabled': 'désactivés',
        'Downloads are currently': 'Les téléchargements sont actuellement',
        'Performances Dashboard': 'Tableau de bord des performances',
        'Loading performance metrics': 'Chargement des métriques de performance',
        'ZIP download performances': 'Performances ZIP des téléchargements',
        'Performances (ms/B)': 'Performances (ms/o)',
        'ms/B': 'ms/o',
        'Download performances': 'Performances des téléchargements',
        'Browsing API latency': 'Latence de l\'API de navigation',
        'Latency (ms)': 'Latence (ms)',
        'Upload performances': 'Performances des mises en ligne',
        'days': 'jours',
        ' day(s)': ' jour(s)',
        'Terms of Use': 'Conditions générales d\'utilisation',
        'Signing in': "Connexion",
        'Accept All': 'Accepter tout',
        'Terms_of_use_title': '<h3 align=\'center\'><strong><span>Conditions g&eacute;n&eacute;rales d&apos;utilisation du portail PSM</span></strong></h3><h5 align=\'center\'><span>En vigueur au 01/01/2022</span></h5><span>&nbsp;</span></p><p><em><span>&nbsp;</span></em></p>',
        'Terms_of_use_notice': '<p><span >Les pr&eacute;sentes conditions g&eacute;n&eacute;rales d&apos;utilisation (dites &laquo;&nbsp;CGU&nbsp;&raquo;) ont pour objet l&apos;encadrement juridique des modalit&eacute;s de mise &agrave; disposition du portail PSM &eacute;dit&eacute; par Edifixio et de ses services, et de d&eacute;finir les conditions d&rsquo;acc&egrave;s et d&rsquo;utilisation des services par &laquo;&nbsp;l&apos;Utilisateur&nbsp;&raquo;.</span></p><p><span >Les pr&eacute;sentes CGU sont accessibles sur le portail &agrave; la rubrique &laquo;&nbsp;CGU&nbsp;&raquo;.</span></p><p><span >Les CGU doivent avoir &eacute;t&eacute; accept&eacute;es par tout Utilisateur souhaitant acc&eacute;der au portail PSM. Elles constituent le contrat entre le portail et l&apos;Utilisateur. L&rsquo;acc&egrave;s au portail PSM par l&rsquo;Utilisateur signifie son acceptation des pr&eacute;sentes CGU. Il s&rsquo;engage d&eacute;sormais &agrave; respecter les pr&eacute;sentes conditions.</span></p><p><span >&nbsp;</span></p><p><span >Toute inscription ou utilisation du portail PSM implique l&apos;acceptation sans aucune r&eacute;serve ni restriction des pr&eacute;sentes CGU par l&rsquo;utilisateur. Lors d&apos;une premi&egrave;re utilisation ou de l&apos;inscription sur le portail PSM via le Formulaire destin&eacute; &agrave cet effet, chaque utilisateur accepte express&eacute;ment les pr&eacute;sentes CGU en appuyant sur le bouton :&nbsp;&laquo;&nbsp;</span><em><span>Accepter tout</span></em><span >&nbsp;&raquo;.</span></p><p><span >En cas de non-acceptation des CGU stipul&eacute;es dans le pr&eacute;sent contrat, l&apos;Utilisateur se doit de renoncer &agrave; l&apos;acc&egrave;s des services propos&eacute;s par le portail PSM.</span></p><p><span >Edifixio se r&eacute;serve le droit de modifier unilat&eacute;ralement et &agrave; tout moment le contenu des pr&eacute;sentes CGU.</span></p><p><span >&nbsp;</span></p>',
        'Terms_of_use_article_1': '<p><strong><span >ARTICLE 1</span></strong><span >&nbsp;: Mentions l&eacute;gales</span></p><p><span >&nbsp;</span></p><p><span >L&apos;&eacute;dition du portail PSM</span><span >&nbsp;<span >est assur&eacute;e par la Soci&eacute;t&eacute; Edifixio, SASU au capital de 22 000&euro;, immatricul&eacute;e au RCS de&nbsp;</span></span>Nanterre<em><span >&nbsp;</span></em><span >sous le num&eacute;ro&nbsp;</span>433 002 011<em><span >,&nbsp;</span></em><span >dont le si&egrave;ge <span >social est situ&eacute; au&nbsp;</span></span>123 RUE Jules Guesde 92300 Levallois Perret.</p><p><span >N&deg; de TVA intracommunautaire :&nbsp;</span>FR87433002011</p><p><span >Le Directeur de la publication est Yannick Tricaud&nbsp;</span></p><p><span >&nbsp;</span></p><p><span >L&apos;h&eacute;bergeur du portail PSM</span><em><span >&nbsp;</span></em><span >est la&nbsp;</span><span >Soci&eacute;t&eacute; Edifixio, SASU au capital de 22 000&euro;, immatricul&eacute;e au RCS de&nbsp;</span>Nanterre<em><span >&nbsp;</span></em><span >sous le num&eacute;ro&nbsp;</span>433 002 011<em><span >,&nbsp;</span></em><span >dont le si&egrave;ge social est situ&eacute; au&nbsp;</span>123 RUE Jules Guesde 92300 Levallois Perret.</p><p>Pour contacter Edifixio, <a href="https://www.edifixio.com/en/contact">un formulaire</a><span >&nbsp;est d&eacute;di&eacute; &agrave; cet effet.</span></p><p><span >&nbsp;</span></p>',
        'Terms_of_use_article_2': `<p><strong><span >ARTICLE 2</span></strong><span >&nbsp;: Acc&egrave;s au portail PSM</span></p><p><span >Le portail PSM permet &agrave; l&apos;Utilisateur un acc&egrave;s gratuit aux services suivants :</span></p><ul><li><span >Auto-enregistrement et acc&egrave;s (apr&egrave;s acceptation aux services du portail)</span></li><li><span >T&eacute;l&eacute;versement, h&eacute;bergement et t&eacute;l&eacute;chargement de fichier de donn&eacute;es scientifiques</span></li></ul><p><span >&nbsp;</span></p><p><span >Le portail PSM est accessible gratuitement en tout lieu &agrave; tout Utilisateur ayant un acc&egrave;s &agrave; Internet. Tous les frais support&eacute;s par l&apos;Utilisateur pour acc&eacute;der au service (mat&eacute;riel informatique, logiciels, connexion Internet, etc.) sont &agrave; sa charge.</span></p><p><span >L&rsquo;Utilisateur non enregistr&eacute; n&apos;a pas acc&egrave;s aux services. Pour cela, il doit s&rsquo;inscrire en remplissant le formulaire. En acceptant de s&rsquo;inscrire aux services r&eacute;serv&eacute;s, l&rsquo;Utilisateur membre s&rsquo;engage &agrave; fournir des informations sinc&egrave;res et exactes concernant son adresse &eacute;lectronique.</span></p><p><span >L&rsquo;Utilisateur est responsable de la mise &agrave; jour des informations fournies. Il lui est pr&eacute;cis&eacute; qu&rsquo;il peut les modifier en se connectant &agrave; son espace membre.</span></p><p><span >Pour acc&eacute;der aux services, l&rsquo;Utilisateur devra s&apos;identifier &agrave; l&apos;aide de son nom d&rsquo;utilisateur et de son mot de passe qui lui seront communiqu&eacute;s apr&egrave;s son inscription et qui sont strictement personnels. A ce titre, il s&rsquo;en interdit toute divulgation. Dans le cas contraire, il restera seul responsable de l&rsquo;usage qui en sera fait.</span></p><p><span >L&rsquo;Utilisateur pourra &eacute;galement solliciter
         sa d&eacute;sinscription en se rendant &agrave; la page d&eacute;di&eacute;e sur son espace personnel ou envoyant un courriel adress&eacute; &agrave;&nbsp;</span><a href="mailto:dpo@edifixio.com"><span >dpo@edifixio.com</span></a>.<span >&nbsp;Celle-ci sera effective dans un d&eacute;lai raisonnable.</span></p><p><span >En cas de non-respect des conditions g&eacute;n&eacute;rales de vente et/ou d&rsquo;utilisation, Edifixio aura la possibilit&eacute; de suspendre voire de fermer le compte d&rsquo;un Utilisateur apr&egrave;s mise en demeure adress&eacute;e par voie &eacute;lectronique et rest&eacute;e sans effet.</span></p><p><span >Toute suppression de compte, quel qu&rsquo;en soit le motif, engendre la suppression pure et simple de toutes informations personnelles de l&rsquo;Utilisateur.</span></p><p><span >Tout &eacute;v&eacute;nement d&ucirc; &agrave; un cas de force majeure ayant pour cons&eacute;quence un dysfonctionnement du portail PSM et sous r&eacute;serve de toute interruption ou modification en cas de maintenance, n&apos;engage pas la responsabilit&eacute; d&rsquo;Edifixio. Dans ces cas, l&rsquo;Utilisateur accepte ainsi ne pas tenir rigueur &agrave; l&rsquo;&eacute;diteur de toute interruption ou suspension de service, m&ecirc;me sans pr&eacute;avis.</span></p><p><span >L&apos;Utilisateur a la possibilit&eacute; de contacter Edifixio par l&rsquo;interm&eacute;diaire&nbsp;</span><a href="https://www.edifixio.com/en/contact"><span >du formulaire de contact</span></a><span >&nbsp;de l&rsquo;&eacute;diteur communiqu&eacute; &agrave; l&rsquo;ARTICLE 1.</span></p><p><span >&nbsp;</span></p>`,
        'Terms_of_use_article_3': `<p><strong><span >ARTICLE 3</span></strong><span >&nbsp;: Donn&eacute;es personnelles</span></p><p><span >Le portail PSM garanti &agrave; l&apos;Utilisateur une collecte et un traitement d&apos;informations personnelles dans le respect de la vie priv&eacute;e conform&eacute;ment &agrave; la loi n&deg;78-17 du 6 janvier 1978 relative &agrave; l&apos;informatique, aux fichiers et aux libert&eacute;s.</span></p><p><span >En conformit&eacute; avec le RGPD en vigueur, l&apos;Utilisateur dispose d&apos;un droit d&apos;acc&egrave;s, de rectification, de suppression et d&apos;opposition de ses donn&eacute;es personnelles. L&apos;Utilisateur exerce ce droit :</span></p><ul><li><span >par mail adress&eacute; &agrave;&nbsp;</span><a href="mailto:dpo@edifixio.com"><span >dpo@edifixio.com</span></a></li><li><span >par voie postale&nbsp;: Edifixio</span></li></ul><p ><span >&nbsp;&agrave; l&rsquo;attention du DPO,&nbsp;</span></p><p >123 RUE Jules Guesde 92300 Levallois Perret.</p><p><span >&nbsp;</span></p>`,
        'Terms_of_use_article_4': `<p><strong><span >ARTICLE 4</span></strong><span >&nbsp;: Propri&eacute;t&eacute; intellectuelle</span></p><p><span >Les marques, logos, sigles du portail PSM (textes, images,) font l&apos;objet d&apos;une protection par le Code de la propri&eacute;t&eacute; intellectuelle et plus particuli&egrave;rement par le droit d&apos;auteur.</span></p><p><span >L&apos;Utilisateur doit solliciter l&apos;autorisation pr&eacute;alable d&rsquo;Edifixio pour toute reproduction, publication, copie des diff&eacute;rents &eacute;l&eacute;ments intrins&egrave;quement li&eacute;s au fonctionnement du portail.&nbsp;</span></p><p><span >Toute repr&eacute;sentation totale ou partielle du portail PSM par quelque proc&eacute;d&eacute; que ce soit, sans l&rsquo;autorisation expresse d&rsquo;Edifixio constituerait une contrefa&ccedil;on sanctionn&eacute;e par l&rsquo;article L 335-2 et suivants du Code de la propri&eacute;t&eacute; intellectuelle.</span></p><p><span >Il est rappel&eacute; conform&eacute;ment &agrave; l&rsquo;article L122-5 du Code de propri&eacute;t&eacute; intellectuelle que l&rsquo;Utilisateur qui reproduit, copie ou publie le contenu prot&eacute;g&eacute; doit citer l&rsquo;auteur et sa source.</span></p><p><span >&nbsp;</span></p>`,
        'Terms_of_use_article_5': `<p><strong><span >ARTICLE 5</span></strong><span >&nbsp;: Responsabilit&eacute;</span></p><p><span >Malgr&eacute; des mises &agrave; jour r&eacute;guli&egrave;res, Edifixio</span><em><span >&nbsp;</span></em><span >ne peut &ecirc;tre tenu responsable de la modification des dispositions administratives et juridiques survenant apr&egrave;s la publication. De m&ecirc;me, Edifixio ne peut &ecirc;tre tenu responsable de l&rsquo;utilisation et de l&rsquo;interpr&eacute;tation de l&rsquo;information contenue dans le portail PSM.</span></p><p><span >&nbsp;</span></p><p><span >L&apos;Utilisateur s&apos;assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume les risques li&eacute;s &agrave; l&apos;utilisation de son identifiant et mot de passe. Edifixio d&eacute;cline toute responsabilit&eacute; en cas de non-respect des r&egrave;gles pr&eacute;cit&eacute;es</span></p><p><span >Edifixio ne peut &ecirc;tre tenu pour responsable d&rsquo;&eacute;ventuels virus qui pourraient infecter l&rsquo;ordinateur ou tout mat&eacute;riel informatique de l&rsquo;Internaute, par suite d&rsquo;une utilisation, de l&rsquo;acc&egrave;s, ou d&rsquo;un t&eacute;l&eacute;chargement provenant du portail.</span></p><p><span >La responsabilit&eacute; d&rsquo;Edifixio ne peut &ecirc;tre engag&eacute;e en cas de force majeure ou du fait impr&eacute;visible et insurmontable d&apos;un tiers.</span></p><p><span >&nbsp;</span></p>`,
        'Terms_of_use_article_6': `<p><strong><span >ARTICLE 6</span></strong><span >&nbsp;: Liens hypertextes</span></p><p><span >Des liens hypertextes peuvent &ecirc;tre pr&eacute;sents dans le contenu h&eacute;berg&eacute; sur le portail PSM. L&rsquo;Utilisateur est inform&eacute; qu&rsquo;en cliquant sur ces liens, il sortira du portail PSM</span><em><span>.&nbsp;</span></em><span >Ce dernier n&rsquo;a pas de contr&ocirc;le sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, &ecirc;tre responsable de leur contenu.</span></p><p><span >&nbsp;</span></p>`,
        'Terms_of_use_article_7': `<p><strong><span >ARTICLE 7</span></strong><em><span >&nbsp;</span></em><span >: Droit applicable et juridiction comp&eacute;tente</span></p><p><span >La l&eacute;gislation fran&ccedil;aise s&apos;applique au pr&eacute;sent contrat. En cas d&apos;absence de r&eacute;solution amiable d&apos;un litige n&eacute; entre les parties, les tribunaux fran&ccedil;ais seront seuls comp&eacute;tents pour en conna&icirc;tre.</span></p><p><span >Pour toute question relative &agrave; l&rsquo;application des pr&eacute;sentes CGU, vous pouvez joindre l&rsquo;&eacute;diteur aux coordonn&eacute;es inscrites &agrave; l&rsquo;ARTICLE 1.</span></p>`,
        'Please accept the Terms of use': 'Politique de gestion des cookies et Conditions générales d’utilisation – Merci d’en accepter les termes en bas de page',
        'Cookies Policy': 'Politique de gestion des cookies',
        'Cookies Policy description': 'Afin de garantir un fonctionnement optimal du portail, nous pouvons être amenés à déposer des cookies techniques nécessaires et exclusivement destinés à vous permettre de naviguer sur le site web et d\'en utiliser les principales fonctionnalités.',
    },
    'es': {
        'dateFormat':'DD/MM/YYYY hh:mm',
        'Company':"Nombre de empresa",
        'Home': "Hogar",
        'Client Management': "Gestión de clientes",
        'Loading Client Details': "Cargando detalles de Cliente",
        'Client name': "Nombre de cliente ",
        'Type': "Tipo",
        'Types': "Tipo(s)",
        'Retentions': "Retenciones",
        'Delete User?': 'Borrar usuario?',
        'days': 'días',
        'Retention': "Retención",
        'Duration': "Duración de la grabación",
        'Your transfert request has been created.': "Su solicitud de transferencia ha sido creada.",
        'Folder name': "Nombre de carpeta",
        'Are you sure you want to delete the user?': 'Está seguro de que quiere eliminar el usuario?',
        'Disable': "Desactive",
        'Unable to retrieve cloudwatch metrics': "No se pueden recuperar las métricas de Cloudwatch",
        'Add folder': "Añadir una carpeta",
        'Upload new files': 'Cargar nuevos archivos',
        'Password': "Contraseña",
        'Cost ($)': 'Coste ($)',
        'Please enter a valid number !': 'Por favor, introduzca un número válido.',
        'Lost your code?': "Ha perdido su código?",
        'User edited successfully': 'Usuario editado con éxito!',
        'Remove folder': "Eliminar carpeta",
        'Searching files...': 'Buscando archivos...',
        'Users list': "Lista de usuarios",
        'Unable to update budget': 'No se puede actualizar el presupuesto',
        'Invalid username format': "Formato de nombre de usuario inválido",
        'Add new user': 'Añadir un nuevo usuario',
        'Update client': "Editar cliente",
        'Open Video player': "Abrir el reproductor de vídeo",
        'Loading budgets': 'Presupuestos de carga',
        'Click to view image in full size': "Haga clic para ver la imagen en tamaño completo",
        'Preview of the record': "Vista previa del disco",
        'Loading usage metrics': "Carga de las métricas de uso",
        'Retry': "Reintentar",
        'Could not create the folder': 'No se ha podido crear la carpeta',
        'Record date': "Fecha de grabación",
        'Could not download zipped file, try again later': 'No se ha podido descargar el archivo comprimido, inténtelo más tarde',
        'Edit': "Editar",
        'The following files are archived, and therefore cannot be accessed directly.': 'Los siguientes ficheros están archivados, por lo que no se puede acceder a ellos directamente.',
        'Filename': "Nombre del fichero",
        'Record Type': "Tipo de grabación",
        'Number of days that the restored files will be available': 'Número de días que estarán disponibles los archivos restaurados',
        'Clients': "Clientes",
        'Created at': "Creado en",
        'Audio': "Audio",
        'Upload successful': 'Cargar con éxito',
        'Tags': "Etiquetas",
        'Would you like to request a restore for these files?': 'Desea solicitar una restauración de estos archivos?',
        'since': 'desde',
        'Add': 'Añadir',
        'Performances (ms/B)': 'Prestaciones (ms/B)',
        'Threshold': 'Umbral',
        'until': 'hasta',
        'Storage usage': 'Uso del almacenamiento',
        'Resend Code': "Reenviar código",
        'Delete': "Borrar",
        'Upload Errors': 'Errores de carga',
        'Enter your code': "Introduzca su código",
        'Create account': 'Crear una cuenta',
        'Anomalies in the last 12 months': 'Anomalías en los últimos 12 meses',
        'Restore': "Restaurar",
        'No storage usage statistics available yet': "Todavía no hay estadísticas de uso del almacenamiento",
        'Performances (ms)': "Prestaciones (ms)",
        'Budget update failed!': 'La actualización del presupuesto ha fracasado.',
        'Could not load files!': 'No se han podido cargar los archivos.',
        'Download files seperately': 'Descargue los archivos por separado',
		'Create Account': 'Crear una cuenta',
        'Downloads': 'Descargas',
        'When downloading multiple files, you can either get them in a single ZIP archive, or download them separately.': 'Al descargar varios archivos, puede obtenerlos en un único archivo ZIP o descargarlos por separado.',
        'Enter tags': "Introduce etiquetas",
        'Renaming folder will not move content.': 'El cambio de nombre de la carpeta no moverá el contenido.',
        'Unable to retrieve costs': "No se pueden recuperar los costes",
        'Download zipped archive': 'Descargar el archivo comprimido',
        'Multi-file download': 'Descarga de varios archivos',
        'Some files could not be downloaded, try again later': 'No se han podido descargar algunos archivos, inténtelo de nuevo más tarde',
        'Add tags to your audio (If you want to add multiple tag use comma)': "Agregue etiquetas a su audio (si desea agregar varias etiquetas use una coma)",
        'Request Selected Files': "Solicitar audios seleccionados",
        'Request Full Folder': "Solicitar carpeta completa",
        'Select any of the transfert mode:': "Seleccione cualquiera de los modos de transferencia:",
        'Sftp': "Sftp",
        'Rename': 'Renombrar',
        'minute': 'minuto',
        'Browsing API latency': 'Latencia de la API de navegación',
        'Url': "Url",
        'Costs Dashboard': 'Cuadro de mando de costes',
        'Download Selected': "Descargar seleccionado",
        'Audio player': "Reproductor de música",
        'Open Audio player': "Abrir el reproductor de audio",
        'No file found matching': "No se encontró audio que coincida",
        'Loading...': "Cargando...",
        'Load more files': "Cargue más audio",
        'My Clients': "Mis clientes",
        'Download activation': 'Descargar la activación',
        'Transfert Request': "Solicitud de transferencia",
        'Hello': "Hola",
        'downloads per': 'descargas por',
        'Download limit': 'Límite de descarga',
        'Confirmation Code': "Código de confirmación",
        'Upload Statistics': 'Subir estadísticas',
        'Unable to retrieve budgets': 'No se pueden recuperar los presupuestos',
        'Choose download type': 'Elija el tipo de descarga',
        'Loading files...': 'Cargando archivos...',
        'File download': 'Descarga de archivos',
        'Reset password': 'Restablecer contraseña',
        'Updating budget': 'Actualización del presupuesto',
        'You must enter Client Name to create a new client!': "Debe ingresar el nombre del cliente para crear un nuevo cliente!",
        'New Client Name': "Nuevo nombre del cliente",
        'Back to Client list': "Volver a la lista de clientes",
        'Back to Client Management': "Volver a la gestión de clientes",
        'My Record Type': "Mi tipo de registro",
        'Uploading...': "Subir archivos...",
        'Add Audio': "Añadir audio",
        'ZIP download performances': 'Actuaciones de descarga de ZIP',
        'Issue with the selected file(s)': 'Problema con el o los archivos seleccionados',
        'Archived': 'Archivado',
        'Deleting folder will permanently delete content if none are locked.': 'La eliminación de la carpeta borrará permanentemente el contenido si no hay ninguno bloqueado.',
        'Download Statistics': 'Descargar estadísticas',
        'Download performances': 'Descargar actuaciones',
        'Could not load client details!': 'No se han podido cargar los datos del cliente.',
        'Updated client success': 'Éxito actualizado de los clientes',
        'Enter your password': 'Introduzca su contraseña',
        'Users Management': 'Gestión de usuarios',
        'Tag name': "Nombre de etiqueta",
        'Size': 'Tamaño',
        'Downloads are currently': 'Las descargas son actualmente',
        'No account? ': 'No tiene cuenta ? ',
        'Loading performance metrics': 'Carga de las métricas de rendimiento',
        'Creation date from': "Fecha de creación desde",
        'Creation date to': "Fecha de creación a",
        'Search': "Buscar",
        'at': "a las",
        'Date': "Fecha",
        'User deleted successfully': "Usuario eliminado con éxito !",
        'Restored': 'Restaurado',
        'Storage Size': 'Tamaño del almacén',
        'Forget your password? ': 'Olvidaste tu contraseña ? ',
        'Edit user': 'Editar usuario',
        'Renaming': 'Cambiando el nombre de',
        'Latency (ms)': 'Latencia (ms)',
        'Usage Dashboard': 'Cuadro de mandos de uso',
        'Select transfert mode': 'Seleccione el modo de transferencia',
        'Just one second': "Sólo un segundo",
        'Creating a new folder in root': 'Creación de una nueva carpeta en la raíz',
        'We are fetching trasnsfert request for you.': "Estamos obteniendo la solicitud de transferencia para usted.",
        'No transfert request found!': "No se encontró solicitud de transferencia!",
        'Id': "Id",
        'hour': 'hora',
        'Performances Dashboard': 'Panel de control de los resultados',
        'File Size': "Tamaño del fichero",
        'Issue with client informations!': "Problema con la información de los clientes !",
        'UserId': "ID de usuario",
        'Add new folder' : "Añadir una nueva carpetar",
        'UserName': "Nombre del usuario",
        'Profile': "Perfil",
        'Have an account? ': 'Tiene una cuenta?',
        'Invalid email address format': "Formato de dirección de correo electrónico no válido",
        'Mode': "Modo",
        'Downloads disabled': 'Descargas desactivadas',
        'Anomalies in the last 30 days': 'Anomalías en los últimos 30 días',
        'Missing folder': 'Carpeta perdida',
        'Requested Audios': "Audios solicitados",
        'Status': "Estado",
        'Rename folder' : "Renombrar la carpeta",
        'This folder already exists': 'Esta carpeta ya existe',
        'Action': "Acción",
        'Upload failed!': 'Carga fallida.',
        'Full folder': "Carpeta completa",
        'Accept': "Aceptar",
        'Add folder to root': 'Añadir carpeta a la raíz',
        'Warning': 'Advertencia',
        'Could not delete the folder': 'No se ha podido eliminar la carpeta',
        'Confirm deletion': 'Confirmar el borrado',
        'Reject': "Rechazar",
        'Confirm deletion of these {count} files?': 'Confirmar la eliminación de estos {contados} archivos?',
        'Dashboard': 'Panel de control',
        'Transfer Request': 'Solicitud de traslado',
        'Load More Transfert Request': "Cargar más solicitud de transferencia",
        'Create':'Crear',
        'Creating a new folder in': 'Creación de una nueva carpeta en',
        'Upload performances': 'Subir actuaciones',
        'Create folder': 'Créer carpeta',
        'Show audios':"Mostrar audio",
        'Upload': 'Cargar',
        'Back to folder list':"Volver a la lista de carpetas",
        'Create a new client':"Crear un nuevo cliente",
        'Create client':"Crear cliente",
        'Edit client':"Editar cliente",
        'Created':"Creado",
        'on': "el",
        'Password not long enough. It should contain more than 8 characters.': "La contraseña no es lo suficientemente larga. Debe contener más de 8 caracteres.",
        'Completed':"Terminado",
        'Download Errors': 'Descarga de errores',
        'Enter your username': "Introduzca su nombre de usuario",
        'Rejected':"Rechazado",
        'Access Denied':"Acceso denegado",
        'No clients found': "No se han encontrado clientes",
        'You are':"Usted está",
        'not authorized':"no autorizado",
        'Add other retention': "Añadir otras retenciones", 
        'Enable': "Active",
        'Notifications': 'Notificaciones',
        'Folders List': 'Lista de carpetas',
        'Uploads': 'Sube',
        'Some files could not be deleted, try again later': 'No se han podido eliminar algunos archivos, inténtelo de nuevo más tarde',
        'to access this resource.':"para acceder a este recurso.",
        'No folder found for the client':"No se encontró ninguna carpeta para el cliente.",
        'Your account has not been validated by your company':"Su cuenta no ha sido validada por su empresa",
        'Please contact your administrator.':"Por favor contacte a su administrador",
        'Loading Folder':"Cargando Carpeta",
        'Created client with name':"Cliente creado con nombre ",
        "Your transfert request has been executed. You'll received an email with":"Su solicitud de transferencia ha sido ejecutada. Recibirá un correo electrónico con",
        'Error':"Error",
        'Hide Id': 'Ocultar Id',
        'Budgets': 'Presupuestos',
        'day': 'día',
        'Deleting': 'Eliminación de',
        'Could not rename the folder': 'No se ha podido renombrar la carpeta',
        'Downloads quota exceeded, contact your administrator': 'Se ha superado la cuota de descargas, póngase en contacto con su administrador',
        'Reset your password': 'Restablecer la contraseña',
        'Your transfert request has been rejected.':"Su solicitud de transferencia ha sido rechazada.",
        'An error occured while executing your transfert request.':"Se produjo un error al ejecutar su solicitud de transferencia.",
        'Audio Duration':"Duración de audio (en segundos)",
        'Audio Duration From':"Duración de audio desde",
        'Audio Duration To':"Duración de audio a",
        'Upload now':"Cargar ahora",
        'Import CSV File':'Importar archivo CSV',
        'Select any of the transfert mode to upload:':"Elija un modo de transferencia para cargar:",
        'Your transfer request has been created.':"Su solicitud de transferencia se ha creado con éxito.",
        'Sign out': "Desconectar",
        'User added successfully': 'Usuario añadido con éxito!',
        'Add user': 'Añadir usuario',
        'Costs': 'Costes',
        'Show Id': 'Mostrar Id',
        'Sign In': 'Registrarse',
        'Upload failed due to a network error!': 'La carga ha fallado debido a un error de red.',
		'Sign Up': 'Regístrate',
        'Budget limit': 'Límite presupuestario',
        'Sign in to your account': 'Acceda a su cuenta',
        'Cancel': 'Cancelar',
        'Update': 'Actualizar',
        'Delete Selected': 'Borrar lo seleccionado',
        'Usage': 'Utilización',
        'Performances': "Actuaciones",
        'Add notification': 'Añadir notificación',
		'Sign Out': 'Desconectar',
        'Error : expected format : Client ID, Folder ID, AudioRecord ID':"Error : formato esperado : ID del cliente, ID de la carpeta, ID de la grabación de audio.",
		'Forgot Password': 'Se te olvidó tu contraseña',
		'Username': 'Nombre de usuario',
		'Change Password': 'Cambia la contraseña',
		'New Password': 'Nueva contraseña',
		'Email': 'Email',
		'Phone Number': 'Número de teléfono',
		'Confirm a Code': 'Confirmar un código',
		'Confirm Sign In': 'Confirmar inicio de sesión',
		'Confirm Sign Up': 'Confirmar Registrarse',
		'Back to Sign In': 'Volver a Iniciar sesión',
		'Send Code': 'Enviar código',
		'Confirm': 'Confirmar',
		'Resend a Code': 'Reenviar un código',
		'Submit': 'Enviar',
		'Skip': 'Omitir',
		'Verify': 'Verificar',
		'Verify Contact': 'Verificar contacto',
		'Code': 'Código',
		'Account recovery requires verified contact information':
			'La recuperación de la cuenta requiere información de contacto verificada',

		'User does not exist': 'el usuario no existe',
		'User already exists': 'El usuario ya existe',
		'Incorrect username or password':
			'Nombre de usuario o contraseña incorrecta',
		'Invalid password format': 'Formato de contraseña inválido',
		'Invalid phone number format': `Formato de número de teléfono inválido.
        Utilice el formato de número de teléfono +12345678900`,
        'Transfer': 'Transferencia',
        'Storage': 'Almacenamiento',
        'Other': 'Otro',
        'Total cost': 'Coste total',
        'Loading costs metrics': 'Cargando las métricas de costos',
        'Loading storage metrics': 'Cargando las métricas de almacenamiento',
        'Loading downloads metrics': 'Cargando las métricas de descargas',
        'Terms of Use': 'Condiciones de Uso',
        'Accept All': 'Aceptar todo',
        'Please accept the Terms of use': 'Política de Cookies y Términos y Condiciones - Por favor, acepte los términos al final de la página',
        'Cookies Policy': 'Política de gestión de cookies',
        'Cookies Policy description': 'Para garantizar el óptimo funcionamiento del portal, podemos depositar cookies técnicas necesarias y exclusivamente destinadas a permitirle navegar por el sitio web y utilizar sus principales funciones.',
    },
    'en': {
        'Company':"Company Name",
        'Home': "Home",
        'Client Management': "Client Management",
        'Loading Client Details': "Loading Client Details",
        'Client name': "Client name",
        'Type': "Type",
        'Retention': "Retention",
        'Duration': "Duration",
        'Folder name': "Folder name",
        'Add folder': "Add folder",
        'Remove folder': "Remove folder",
        'Password did not conform with policy: Password must have uppercase characters': "Password must have uppercase characters",
        'Update client': "Update client",
        'Record date': "Record date",
        'Edit': "Edit",
        'Filename': "File name",
        'Record Type': "Record Type",
        'Created at': "Created at",
        'Audio': "Audio",
        'Media': "Media",
        'Tags': "Tags",
        'Folder': "Folder",
        'Enter tags': "Enter tags",
        'Add tags to your audio (If you want to add multiple tag use comma)': "Add tags to your file (If you want to add multiple tag use comma)",
        'Request Selected Files': "Request selected files",
        'Request Full Folder': "Request Full Folder",
        'Select any of the transfert mode:': "Select any of the transfert mode:",
        'Sftp': "Sftp",
        'Url': "Url",
        'Download Selected': "Download Selected",
        'Audio player': "Audio player",
        'Open Audio player': "Open Audio player",
        'No file found matching': "No matching file found",
        'Loading...': "Loading...",
        'Load more files': "Load more files",
        'My Clients': "My Clients",
        'Client Name': "Client Name",
        'Transfert Request': "Transfert Request",
        'Hello': "Hello",
        'You must enter Client Name to create a new client!': "You must enter Client Name to create a new client!",
        'New Client Name': "New Client Name",
        'Back to Client list': "Back to Client list",
        'Back to Client Management': "Back to Client Management",
        'My Record Type': "My Record Type",
        'Uploading...': "Uploading...",
        'Add audio': "Add audio",
        'Add image': "Add image",
        'Add video': "Add video",
        'Add application': "Add application",
        'Tag name': "Tag name",
        'Are you sure you want to delete the user?': 'Are you sure you want to delete the user?',
        'Creation date from': "Creation date from",
        'Creation date to': "Creation date to",
        'Search': "Search",
        'Just one second': "Just one second",
        'We are fetching trasnsfert request for you.': "We are fetching trasnsfert request for you.",
        'No transfert request found!': "No transfert request found!",
        'Id': "Id",
        'UserId': "User ID",
        'UserName': "User Name",
        'Invalid email address format': "Invalid email address format",
        'Mode': "Mode",
        'Requested Audios': "Requested Audios",
        'Requested Medias': "Requested Medias",
        'Status': "Status",
        'Action': "Action",
        'Invalid username format': "Invalid username format",
        'Full folder': "Full folder",
        'Accept': "Accept",
        'Reject': "Reject",
        'Load More Transfert Request': "Load More Transfert Request",
        'Create':'Create',
        'Show audios':"Show files",
        'Back to folder list':"Back to folder list",
        'Create a new client':"Create a new client",
        'Create client':"Create client",
        'Edit client':"Edit client",
        'Open Video player': "Open Video playe",
        'Click to view image in full size': "Click to view image in full size",
        'Preview of the record': "Preview of the record",
        'Loading usage metrics': "Loading usage metrics",
        'Created':"Created",
        'Completed':"Completed",
        'Rejected':"Rejected",
        'Error':"Error",
        'Access Denied':"Access Denied",
        'You are':"You are",
        'not authorized':"not authorized",
        'Password not long enough. It should contain more than 8 characters.': "Password not long enough. It should contain more than 8 characters.",
        'to access this resource.':"to access this resource.",
        'No folder found for the client':"No folder found for the client",
        'Your account has not been validated by your company':"Your account has not been validated by your company",
        'Please contact your administrator.':"Please contact your administrator.",
        'Created client with name':"Created client with name ",
        "Your transfert request has been executed. You'll received an email with":"Your transfert request has been executed. You'll received an email with",
        'Your transfer request has been rejected.':"Your transfer request has been rejected.",
        'Audio Duration':"Audio duration (in seconds)",
        'Error : expected format : Client ID, Folder ID, AudioRecord ID':"Error : expected format : Client ID,Folder ID,Record ID",
        'File Size': 'File size',
        'Updated client success': 'Client informations updated successfully',
        'Upload': 'Upload',
        'Upload a new file': 'Upload a new file',
        'Folders List': 'Folders list',
        'Users Management': 'Users management',
        'Users list': 'Users list',
        'Add new user': 'Add a new user',
        'User edited successfully': 'User informations were edited successfully!',
        'User added successfully': 'User was added successfully!',
        'Transfer': 'Transfer',
        'Storage': 'Storage',
        'Other': 'Other',
        'Total cost': 'Total cost',
        'Password did not conform with policy: Password not long enough': "Password not long enough",
        'Loading costs metrics': 'Loading costs metrics',
        'Loading storage metrics': 'Loading storage metrics',
        'Loading downloads metrics': 'Loading downloads metrics',
        'Budgets': 'Budgets',
        'Loading budgets': 'Loading budgets',
        'Daily budget': 'Daily budget',
        'Monthly budget': 'Monthly budget',
        'Password did not conform with policy: Password must have symbol characters': "Password must have symbol characters",
        'Yearly budget': 'Yearly budget',
        'No clients found': "No clients found",
        'Spent today:': 'Spent today:',
        'Spent this month:': 'Spent this month:',
        'Spent this year:': 'Spent this year:',
        'Anomalies in the last 30 days': 'Anomalies in the last 30 days',
        'Anomalies in the last 12 months': 'Anomalies in the last 12 months',
        'DAILY budget limit:': 'Daily limit:',
        'MONTHLY budget limit:': 'Monthly limit:',
        'ANNUALLY budget limit:': 'Yearly limit:',
        'Unable to retrieve budgets': 'Unable to retrieve budgets',
        'Unable to update budget': 'Unable to update budget',
        'Updating budget': 'Updating budget',
        'Budget update failed!': 'Budget update failed!',
        'Budget limit': 'Budget limit',
        'Enable': "Enable",
        'Update': 'Update',
        'Profile': "Profil",
        'Upload now':"Upload now",
        'Upload failed!': 'Upload failed!',
        'Upload failed due to a network error!': 'Upload failed due to a network error!',
        'Upload successful': 'Upload successful',
        'notification(s) setup': 'notification(s) setup',
        'Notifications': 'Notifications',
        'Threshold': 'Threshold',
        'Add': 'Add',
        'Emails': 'Emails',
        'Add notification': 'Add notification',
        'Terms of Use': 'Terms of Use',
        'Password did not conform with policy: Password must have numeric characters': "Password must have numeric characters",
        'Accept All': 'Accept All',
        "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[S]+.*[S]+$": "The password must contain at least 8 characters.",
        'Your transfert request has been created.': "Your transfert request has been created.",
        'Disable': "Disable",
        'Add user': 'Add user',
        'Delete User?': 'Delete User?',
        'Cancel': 'Cancel',
        'Import CSV File':'Import CSV File',
        'Select any of the transfert mode to upload:': 'Select any of the transfert mode to upload:',
        'Edit user': 'Edit user',
        'Please accept the Terms of use': 'Cookies Policy and Terms of Use - Please accept the terms at the bottom of the page',
        'Cookies Policy': 'Cookie Management Policy',
        'Cookies Policy description': 'In order to guarantee an optimal functioning of the portal, we can be brought to deposit technical cookies necessary and exclusively intended to allow you to navigate on the Web site and to use its principal functionalities.',
    }
};
