
export const ListClients = `query ListClients {
    listClients(limit: 9999) {
        items {
            id
            name
            retention
            myRetentionList{
                type
                retention
                duration
            }
            updatedAt
        }
    }
  }`;

export const SubscribeToNewClients = `
    subscription SubscribeToNewClients {
      onCreateClient {
        id
        name
      }
    }
  `;

export const GetClient = `query GetClient($id: ID!, $nextTokenForAudios: String) {
    getClient(id: $id) {
      id
      name
      records(sortDirection: DESC, nextToken: $nextTokenForAudios){
        items {
          id
          recordType
          filename
          createdAt
          folder
          fullsize {
            key
            size
            duration
            altduration
            format
          }
          thumbnail {
            key
            size
            duration
          }
        }
        nextToken
      }
    }
  }
  `;

export const GetClientForUpdate = `query GetClientForUpdate($id: ID!) {
    getClient(id: $id) {
      id
      name
      myRetentionList
      {
        type
        retention
        duration
       }
       folderList {
         folder
       }
      }
    }
  `;

export const SubscribeToNewArchiveClients = `
    subscription SubscribeToNewArchiveClients {
      onCreateClient {
        id
        name
        myRetentionList
        {
          type
          retention
          duration
        }
        folderList {
          folder
        }
      }
    }
  `;

export function updateClientQuery(myRetentionList, folderList, clientName) {
  if (myRetentionList || folderList || clientName) {
    if (folderList && myRetentionList && clientName) {
      return `
      mutation modifyClient($id : ID!){
          updateClient (input : {
            id: $id
            myRetentionList : ${myRetentionList}
            folderList : ${folderList}
            name: "${clientName}"
          }) {
            id
            name
            myRetentionList {
              type
              retention
              duration
            }
            folderList {
              folder
            }
          }
         }
      `;
    } else if (myRetentionList && !folderList && !clientName) {
      return `
      mutation modifyClient($id : ID!){
          updateClient (input : {
            id: $id
            myRetentionList : ${myRetentionList}
          }) {
            id
            name
            myRetentionList {
              type
              retention
              duration
            }
            folderList {
              folder
            }
          }
         }
      `;
    } else if (!myRetentionList && folderList && !clientName) {
      return `
      mutation modifyClient($id : ID!){
          updateClient (input : {
            id: $id
            folderList : ${folderList}
          }) {
            id
            name
            myRetentionList {
              type
              retention
              duration
            }
            folderList {
              folder
            }
          }
         }
      `;
    } else if (!myRetentionList && !folderList && clientName) {
      return `
      mutation modifyClient($id : ID!){
          updateClient (input : {
            id: $id
            name : $clientName
          }) {
            id
            name
            myRetentionList {
              type
              retention
              duration
            }
            folderList {
              folder
            }
          }
         }
      `;
    } else if (myRetentionList && folderList && !clientName) {
      return `
      mutation modifyClient($id : ID!){
          updateClient (input : {
            id: $id
            myRetentionList : ${myRetentionList}
            folderList : ${folderList}
          }) {
            id
            name
            myRetentionList {
              type
              retention
              duration
            }
            folderList {
              folder
            }
          }
         }
      `;
    } else if (!myRetentionList && folderList && clientName) {
      return `
      mutation modifyClient($id : ID!){
          updateClient (input : {
            id: $id
            folderList : ${folderList}
            name : $clientName
          }) {
            id
            name
            myRetentionList {
              type
              retention
              duration
            }
            folderList {
              folder
            }
          }
         }
      `;
    } else if (myRetentionList && !folderList && clientName) {
      return `
      mutation modifyClient($id : ID!){
          updateClient (input : {
            id: $id
            myRetentionList : ${myRetentionList}
            name : $clientName
          }) {
            id
            name
            myRetentionList {
              type
              retention
              duration
            }
            folderList {
              folder
            }
          }
         }
      `;
    }

  } else {
    return `
    mutation modifyClient($id : ID!){
        updateClient (input : {
          id: $id
        }) {
          id
          name
          myRetentionList {
            type
            retention
            duration
          }
          folderList {
            folder
          }
        }
       }
    `;
  }
}

export function createClientQuery(myRetentionList, folderList) {
  if (myRetentionList || folderList) {
    if (folderList && myRetentionList) {
      return `mutation createClient($name: String!){
        createClient (input : {
          name: $name
          myRetentionList : ${myRetentionList}
          folderList : ${folderList}
        }) {
          id
          name
          myRetentionList {
            type
            retention
            duration
          }
          folderList {
            folder
          }
        }
       }`;
    }
    else if (myRetentionList && !folderList) {
      return `mutation createClient($name: String!){
        createClient (input : {
          name: $name
          myRetentionList : ${myRetentionList}
        }) {
          id
          name
          myRetentionList {
            type
            retention
            duration
          }
        }
       }`;
    } else if (!myRetentionList && folderList) {
      return `mutation createClient($name: String!){
        createClient (input : {
          name: $name
          folderList : ${folderList}
        }) {
          id
          name
          myRetentionList {
            type
            retention
            duration
          }
        }
       }`;
    }
  } else
    return `mutation createClient($name: String!){
    createClient (input : {
      name: $name
    }) {
      id
      name
      myRetentionList {
        type
        retention
        duration
      }
      folderList {
        folder
      }
    }
   }`;
}

export const GetClientWithFolderList = `query GetClientWithFolderList($id: ID!) {
  getClient(id: $id) {
    myRetentionList {
      type
      retention
    }
    name
    owner
    folderList {
      folder
    }
  }
}
`;
export const listAudioRecords = `
query listAudioRecords(
  $filter: ModelAudioRecordFilterInput
  $limit: Int
  $nextToken: String
  $audioRecordClientId: ID !
) {
  listAudioRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      audioRecordClientId
      bucket
      recordType
      filename
      folder
      storageStatus
      statusDate
      fullsize {
        key
        size
        duration
        altduration
        format
      }
      thumbnail {
        key
        size
        duration
      }
      createdAt
      tags
    }
    nextToken
  }
  getClient(id: $audioRecordClientId) {
    id
    name
    owner
    retention
    updatedAt
  }
}
`;
export const listAudioRecordsFolderFilter = `
query listAudioRecordsFolderFilter(
  $filter: ModelAudioRecordFolderFilterInput
  $limit: Int
  $nextToken: String
  $audioRecordClientId: ID !
) {
  listAudioFolderRecords(clientId: $audioRecordClientId,filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      audioRecordClientId
      bucket
      recordType
      filename
      folder
      storageStatus
      statusDate
      fullsize {
        key
        size
        duration
        altduration
        format
      }
      thumbnail {
        key
        size
        duration
      }
      createdAt
      tags
    }
    nextToken
  }
  getClient(id: $audioRecordClientId) {
    id
    name
    owner
    retention
    updatedAt
  }
}
`;


export const GetAudioListByFolderAndClient = `query GetAudioListByFolderAndClient ($limit: Int, $id : ID !, $folderName : String !, $nextToken: String) {
  listFolderRecords (
    limit:$limit
    folderName : $folderName,
    clientId : $id
    nextToken: $nextToken
  ) {
    items{
      id
      recordType
      filename
      createdAt
      folder
      tags
      storageStatus
      statusDate
      fullsize {
        key
        size
        duration
        altduration
        format
      }
      thumbnail {
        key
        size
        duration
      }
    }
    nextToken
  }
  getClient(id: $id) {
    id
    name
    owner
    myRetentionList {
      type
      retention
    }
  }
}`;

export const GetAudioListByFolderAndClientAndInfos = `query GetAudioListByFolderAndClientAndInfos ($limit: Int, $id : ID !, $folderName : String !, $nextToken: String) {
  listFolderRecords (
    limit:$limit
    folderName : $folderName,
    clientId : $id
    nextToken: $nextToken
  ) {
    items{
      id
      recordType
      filename
      createdAt
      folder
      tags
      storageStatus
      statusDate
      fullsize {
        key
        size
        duration
        altduration
        format
      }
      thumbnail {
        key
        size
        duration
      }
    }
    nextToken
  }
  getClient(id: $id) {
    id
    name
    owner
    myRetentionList {
      type
      retention
    }
  }
}`;

export function createTags(tagsList, audioId, clientId) {
  const query = `mutation createTags {
    updateTagAudioRecord (
      input : {tags : ${JSON.stringify(tagsList)}}, 
      condition : {id : ${JSON.stringify(audioId)},
        audioRecordClientId : ${JSON.stringify(clientId)}
      }) {
      audioRecordClientId
      id
      tags
    }
  }`;
  return query;
}

export function createTransfertRequest(fullFolder,audioRecordClientId,folder,audios,files,mode,restoreDuration,glacierFiles){
    return `mutation createTransfertRequestFolder {
      createTransfertRequest (input: {
        audioRecordClientId: ${JSON.stringify(audioRecordClientId)}
        folder: ${JSON.stringify(folder)}
        mode: ${JSON.stringify(mode)}
        ${ mode ==="restore" && restoreDuration ? `restoreDuration: ${restoreDuration}`: ""}
        ${ mode ==="restore" && glacierFiles ? `glacierFiles: ${JSON.stringify(glacierFiles)}`: ""}
        ${ !fullFolder ? `audios: ${JSON.stringify(audios)}`: ""}
        ${ !fullFolder ? `files: [ ${files.map((f) => {return `{id: ${JSON.stringify(f.id)}, name: ${JSON.stringify(f.filename)}}`})}]`: ""}
      }) {
        id
        audioRecordClientId
        userId
        userName
        ${ !fullFolder ? `audios` : ""}
        ${ !fullFolder ? `files { id name }` : ""}
      }
    }`
}

export const ListTrasfertRequestByClient =
  `query listTR($limit: Int, $id : ID !, $nextToken: String) {
    listTransferRequest (filter:{
      audioRecordClientId : {
        eq: $id
      }
    },nextToken: $nextToken,limit:$limit)
    {
      items{
        id
        userId
        userName
        createdAt
        mode
        restoreDuration
        audios
        files {
          id
          name
        }
        folder
        status
        audioRecordClientId
        client {
          name
        }
      }
      nextToken
    }
  }`;

  export const ListTrasfertRequestForSpecificUserByClient=
  `query listTR($limit: Int, $id : ID !, $userId: ID!, $nextToken: String) {
    listTransferRequest (filter:{
      audioRecordClientId : $id
      userId: $userId
    },nextToken: $nextToken,limit:$limit)
    {
      items{
        id
        createdAt
        mode
        audios
        folder
        status
        audioRecordClientId
      }
      nextToken
    }
  }`;

  export const UpdateStatusForTransfertRequest = `
    mutation updateStatusTransfertRequest($id: ID!, $status: String!,$audioRecordClientId: ID!) {
    updateStatusTransfertRequest (
      input : {
        id : $id,
        status: $status
      },
      condition : {
        audioRecordClientId : $audioRecordClientId
      }
    ){
      id
      audioRecordClientId
      userId
      audios
      status
    }
   }`;