import React, { Component} from 'react';
import { I18n } from 'aws-amplify';
import Auth from '@aws-amplify/auth'
import { Popup, Image, Message  } from 'semantic-ui-react'
import { getAudioLinkFromAPI } from '../util/api';
import Lightbox from 'react-image-lightbox';

class ImageViewer extends Component {
  constructor (props) {
    super(props);
    this.state= {placeHolder: "true" ,lightBoxIsOpen: false, fullsizeLink: undefined, showDownloadDisabledError: false};
  }

  componentDidMount() {
    this.getImageSource();
  }
  
  async getImageSource() {
    if (this.state && this.state.thumbnailSrc) return;
    const self = this;
    // plug here the call to lambda
    let link = await self.loadSignedUrlDownload(this.props.image.id, true)
    self.setState({ thumbnailSrc : link})
    
  };

  async loadSignedUrlDownload (srcKey, thumbnail)  {
    const self = this
    return new Promise (resolve => {
      Auth.currentAuthenticatedUser().then (value => {
        getAudioLinkFromAPI(srcKey,value,thumbnail).then(link => {
          resolve(link)
        }).catch(err => {
          if (err.response.status === 429) { // Downloads disabled
            this.setState({ showDownloadDisabledError: true });
            setTimeout(() => this.setState({ showDownloadDisabledError: false }), 5000);
            return;
          }
          console.error(err);
          if( thumbnail )
            self.setState({ thumbnailSrc : 'emptyfile.gif'})
        })
    });
    })

  } 

  handleCloseImageBox() {
    this.setState({ lightBoxIsOpen: false, lightBoxSrc: undefined });
  }

  async handleOpenImageBox(src){
    let fullsizeLink = await this.loadSignedUrlDownload(src, false)
    //console.log(fullsizeLink)
    this.setState({ lightBoxSrc: fullsizeLink })
    this.setState({ lightBoxIsOpen: true })

  }

  render() {
    if( this.props.fileAvailable )
      return (
        <React.Fragment>
        {this.state && this.state.thumbnailSrc && <>
          <Popup
            size='mini'
            trigger={
              <Image centered src={this.state.thumbnailSrc} alt={I18n.get("Preview of the record")} size='tiny' onClick={() => this.handleOpenImageBox(this.props.image.id)} />
            }>
            <Popup.Header>{I18n.get('Click to view image in full size')}</Popup.Header>
          </Popup>
          {this.state.showDownloadDisabledError &&
            <Message error size='tiny'>
              <Message.Header>{I18n.get("Downloads quota exceeded, contact your administrator")}</Message.Header>
            </Message>
          }
        </>}
        { this.state.lightBoxIsOpen && (
          <Lightbox
            mainSrc={this.state.lightBoxSrc}
            onCloseRequest={() => this.setState({ lightBoxIsOpen: false, lightBoxSrc: undefined })}
          />
        )}   
      </React.Fragment>
    )
  else 
      return (
        <React.Fragment>
        {this.state && this.state.thumbnailSrc &&
          <Image centered src={this.state.thumbnailSrc} alt={I18n.get("Preview of the record")} size='tiny' />
        }
        </React.Fragment>
      )
  }

}
  
export default ImageViewer;