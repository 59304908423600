import React, { Component } from 'react';
import { Modal, Icon, Message, Button, Form } from 'semantic-ui-react';
import { I18n } from 'aws-amplify';
import { generateFolderPathWithLinks } from '../../../util/folder';

const initialState = {
    folderName: '',
    loading: false,
    error: null,
}

/**
 * CreateFolderModal component props:
 *     
 *     open: boolean,
 *     client: {Client object},
 *     rootFolder: "folder name",
 *     subFolders: ["folder names"],
 *     createFolder: function(rootFolder, folderName),
 *     onClose: function,
 */
class CreateFolderModal extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    onModalOpen = () => {
        this.setState({ ...initialState });
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open)
            this.onModalOpen();
    }

    handleChange = (_e, { name, value }) => {
        this.setState({ [name]: value || '' });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({ loading: true });
        const path = this.props.rootFolder ? `${this.props.rootFolder}/${this.state.folderName}` : this.state.folderName
        this.props.createFolder(path).then(() => {
            this.props.onClose();
        }).catch(_err => this.setState({ loading: false, error: I18n.get('Could not create the folder') }));
    }

    render() {
        return (
            <Modal
                as={Form}
                style={{ width: 'unset', height: 'unset' }}
                dimmer='blurring'
                closeOnDimmerClick={!this.state.loading}
                closeIcon={!this.state.loading}
                open={this.props.open}
                onClose={this.props.onClose}
                onClick={e => e.stopPropagation()}
                error={this.state.error !== null}
            >
                <Modal.Header>
                    <Icon name='folder open' />{I18n.get('Add new folder')}
                </Modal.Header>
                <Modal.Content>
                    <Message error>
                        <Message.Header>{this.state.error}</Message.Header>
                    </Message>
                    <p>{this.props.rootFolder
                        ? <>{I18n.get('Creating a new folder in')} {generateFolderPathWithLinks(this.props.rootFolder, this.props.client.id)}</>
                        : I18n.get('Creating a new folder in root')
                    }</p>
                    <Form.Input
                        label={I18n.get('Folder name')}
                        placeholder={I18n.get('Folder name')}
                        name='folderName'
                        value={this.state.folderName}
                        onChange={this.handleChange}
                        disabled={this.state.loading}
                        error={this.props.subFolders.includes(this.state.folderName)
                            ? { content: I18n.get('This folder already exists'), pointing: 'above' }
                            : null
                        }
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        icon='checkmark'
                        labelPosition='left'
                        content={I18n.get('Add folder')}
                        onClick={this.handleSubmit}
                        loading={this.state.loading}
                        disabled={this.state.error !== null || this.state.loading || this.props.subFolders.includes(this.state.folderName)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default CreateFolderModal;