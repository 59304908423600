import React, { Component } from 'react';
import aws_exports from '../aws-exports';
import { Button, Message} from 'semantic-ui-react';
import { I18n } from 'aws-amplify';
import Auth from '@aws-amplify/auth'
import Amplify from '@aws-amplify/core'
import { getAudioLinkFromAPI } from '../util/api';

Amplify.configure(aws_exports);

class AudioPlayer extends Component {
  constructor (props) {
    super(props);
    this.state= {placeHolder: true, showDownloadDisabledError: false};
  }
  
  async getImageSource() {
    if (this.state && this.state.src) return;
    const self = this;
    // plug here the call to lambda
    if (this.state && this.state.src) return ;
    self.loadSignedUrlDownload(this.props.audio.id)
    
    
    //storage_1.default.get(srcKey, {}).then(result => {
    //  return self.setState({ src: result, showButton: true});
    //});
  };

  async loadSignedUrlDownload (srcKey)  {
    const self = this

    Auth.currentAuthenticatedUser().then (value => {
      //console.log(util.inspect(value.getSignInUserSession().getAccessToken(), {showHidden: false, depth: 5}));
      Auth.currentCredentials()
        .then(credentials => {
          //console.log(util.inspect(credentials, {showHidden: false, depth: 5}));
          getAudioLinkFromAPI(srcKey,value).then(link => {
            self.setState({src:link,showButton:true})
          }).catch(err => {
            if (err.response.status === 429) { // Downloads disabled
              this.setState({ showDownloadDisabledError: true });
              setTimeout(() => this.setState({ showDownloadDisabledError: false }), 5000);
              return;
            }
            console.error(err);
          })
        });
      });
  } 

  handleSubmit = async (event) => {
    this.getImageSource();
  }

  render() {
    
    return (
      <React.Fragment>
        { this.props.fileAvailable  && <Button primary fluid inverted
                  content={I18n.get('Open Audio player')}
                  onClick= {this.handleSubmit}
                  name='Audio'
                  style={{visibility: this.state.showButton ? 'hidden' :  'visible' }}  />
        }
        {this.state && this.state.src &&
          <audio ref="audio_tag" src={this.state.src} controlsList="nodownload" controls autoPlay={true} />
        }
        {this.state.showDownloadDisabledError &&
            <Message error size='tiny'>
              <Message.Header>{I18n.get("Downloads quota exceeded, contact your administrator")}</Message.Header>
            </Message>
        }
      </React.Fragment>
    );
  }
}
  
export default AudioPlayer;