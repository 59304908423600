
import React, { Component } from 'react';
import {  NavLink } from 'react-router-dom';
import { Table, Segment, Header, Button } from 'semantic-ui-react';
import { formatDateEpochTimeStamp } from '../util/constant';
import { makeComparator } from '../util/comparator';
import { I18n } from 'aws-amplify';


class ArchiveClientList extends Component {

  render() {
    return (
      <Segment>
        <Header as='h3'>{I18n.get('My Clients')}</Header>

          <Table basic='very' striped>

            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{I18n.get('Client name')}</Table.HeaderCell>
                <Table.HeaderCell>{I18n.get('Last update at')}</Table.HeaderCell>
                <Table.HeaderCell>{I18n.get('Types')}</Table.HeaderCell>
                <Table.HeaderCell>{I18n.get('Retentions')}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.props.clients.sort(makeComparator('name')).map((client, index) => (
                <React.Fragment key={'firstFragment'+index}>

                <Table.Row key={index}>

                  <Table.Cell rowSpan={1}>{client.name}</Table.Cell>

                  <Table.Cell rowSpan={1}>
                    {formatDateEpochTimeStamp(new Date(client.updatedAt).getTime() * 1000, I18n.get('dateFormat'))}
                  </Table.Cell>

                  <Table.Cell rowSpan={1}>
                    {client.myRetentionList.map((retention,index) => (
                      <p key={'secondFragment'+index}>{retention.type.toString().charAt(0).toUpperCase() + retention.type.toString().slice(1)}</p>
                    ))}
                  </Table.Cell>

                  <Table.Cell  rowSpan={1}>
                    {client.myRetentionList.map((retention,index) => (
                      <p key={'thirdFragment'+index}>{retention.retention + I18n.get(" day(s)")}</p>
                    ))}
                  </Table.Cell>

                  <Table.Cell rowSpan={1}><NavLink to={`/admin/update/${client.id}`}><Button floated='right' primary inverted>{I18n.get('Edit')}</Button></NavLink></Table.Cell>
                  
                </Table.Row>

                </React.Fragment>
              ))}
            </Table.Body>
            
          <Table.Footer />

        </Table>
      </Segment>
    );
  }
}

export default ArchiveClientList;