import React, { Component } from 'react';
import { Modal, Icon, Message, Button, Form } from 'semantic-ui-react';
import { I18n } from 'aws-amplify';
import { generateFolderPathWithLinks } from '../../../util/folder';

const initialState = props => {
    const folderParts = props.folder.split('/');
    const folderName = folderParts.pop();
    return {
        parentFolderPath: folderParts.join('/'),
        currentFolderName: folderName,
        newFolderName: folderName,
        loading: false,
        error: null,
    };
}

/**
 * RenameFolderModal component props:
 *     
 *     open: boolean,
 *     client: {Client object},
 *     folder: "folder name",
 *     subFolders: ["folder names"],
 *     renameFolder: function(oldPath, newPath),
 *     onClose: function,
 */
class RenameFolderModal extends Component {

    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    onModalOpen = () => {
        this.setState({ ...initialState });
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open)
            this.onModalOpen();
    }

    handleChange = (_e, { name, value }) => {
        this.setState({ [name]: value || '' });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({ loading: true });
        const oldPath = this.props.folder;
        const newPath = this.state.parentFolderPath ? `${this.state.parentFolderPath}/${this.state.newFolderName}` : this.state.newFolderName;
        this.props.renameFolder(oldPath, newPath).then(() => {
            this.props.onClose();
        }).catch(_err => this.setState({ loading: false, error: I18n.get('Could not rename the folder') }));
    }

    render() {
        return (
            <Modal
                as={Form}
                style={{ width: 'unset', height: 'unset' }}
                dimmer='blurring'
                closeOnDimmerClick={!this.state.loading}
                closeIcon={!this.state.loading}
                open={this.props.open}
                onClose={this.props.onClose}
                onClick={e => e.stopPropagation()}
                error={this.state.error !== null}
            >
                <Modal.Header>
                    <Icon name='folder open' />{I18n.get('Rename folder')}
                </Modal.Header>
                <Modal.Content>
                    <Message error>
                        <Message.Header>{this.state.error}</Message.Header>
                    </Message>
                    <p>{I18n.get('Renaming')} {generateFolderPathWithLinks(`${this.state.parentFolderPath}/${this.state.currentFolderName}`, this.props.client.id)}</p>
                    <Form.Input
                        label={I18n.get('Folder name')}
                        placeholder={this.props.folder}
                        name='newFolderName'
                        value={this.state.newFolderName}
                        onChange={this.handleChange}
                        disabled={this.state.loading}
                        error={(this.state.currentFolderName !== this.state.newFolderName && this.props.subFolders.includes(this.state.newFolderName))
                            ? { content: I18n.get('This folder already exists'), pointing: 'above' }
                            : null
                        }
                    />
                    <Message warning visible>
                        <Message.Header>{I18n.get('Warning')}</Message.Header>
                        <Message.Content>{I18n.get('Renaming folder will not move content.')}</Message.Content>
                    </Message>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        icon='checkmark'
                        labelPosition='left'
                        content={I18n.get('Rename folder')}
                        onClick={this.handleSubmit}
                        loading={this.state.loading}
                        disabled={this.state.error !== null || this.state.loading || this.props.subFolders.includes(this.state.newFolderName)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default RenameFolderModal;