import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { Card, Icon, Progress, Statistic } from 'semantic-ui-react';
import UpdateBudgetModal from './updateBudgetModal';

const titleStrings = {
    "DAILY": "Daily budget",
    "MONTHLY": "Monthly budget",
    "ANNUALLY": "Yearly budget",
}

const spentStrings = {
    "DAILY": "Spent today:",
    "MONTHLY": "Spent this month:",
    "ANNUALLY": "Spent this year:",
}

class BudgetCard extends Component {

    renderAnomaliesStatistic = budget => {
        if (!budget.History)
            return null;

        const limit = budget.BudgetLimit.Amount;
        const anomalies = budget.History.reduce((total, data) => {
            if (!data.ActualAmount)
                return total;
            return total + (data.ActualAmount.Amount > limit ? 1 : 0);
        }, 0);
        const statString = budget.TimeUnit === 'DAILY'
            ? I18n.get('Anomalies in the last 30 days')
            : I18n.get('Anomalies in the last 12 months')

        const getColor = count => {
            if (count === 0)
                return 'green';
            if (budget.TimeUnit === 'DAILY')
                return count < 5 ? 'orange' : 'red';
            if (budget.TimeUnit === 'MONTHLY')
                return count < 3 ? 'orange' : 'red';
        }

        return (
            <Card.Content textAlign={"center"}>
                <Statistic color={getColor(anomalies)}>
                    <Statistic.Label>{statString}</Statistic.Label>
                    <Statistic.Value>{anomalies}</Statistic.Value>
                </Statistic>
            </Card.Content>
        );
    }

    render() {
        const budget = this.props.budget;
        const spent = budget.CalculatedSpend.ActualSpend.Amount;
        const limit = budget.BudgetLimit.Amount;
        const notif_count = budget.Notifications.length;

        return (
            <Card style={{ width: '100%', height: 'unset' }}>
                <Card.Content>
                    <UpdateBudgetModal budget={budget} updateBudgetsFunction={this.props.updateBudgetsFunction} />
                    <Card.Header>{I18n.get(titleStrings[budget.TimeUnit])}</Card.Header>
                    <Card.Meta>{I18n.get(budget.TimeUnit + ' budget limit:') + ' $' + limit}</Card.Meta>
                </Card.Content>
                <Card.Content>
                    <Progress
                        value={spent}
                        total={limit}
                        progress="percent"
                        precision={1}
                        success
                        warning={spent >= 0.8 * limit}
                        error={spent >= limit}
                        label={I18n.get(spentStrings[budget.TimeUnit]) + ' $' + spent}
                    />
                </Card.Content>
                {this.renderAnomaliesStatistic(budget)}
                <Card.Content extra>
                    <Icon name="alarm" />
                    {notif_count + " " + I18n.get("notification(s) setup")}
                </Card.Content>
            </Card>
        );
    }
}

export default BudgetCard;