import React, { Component } from 'react';
import { Message, Icon, Table, Header, Button, Segment, Popup, List, Breadcrumb } from 'semantic-ui-react';
import Auth from '@aws-amplify/auth'
import API from '@aws-amplify/api'
import { graphqlOperation, I18n } from 'aws-amplify';
import { ListTrasfertRequestByClient, UpdateStatusForTransfertRequest } from '../util/query';
import { REJECTED, COMPLETED, IN_PROGRESS, formatDate, ERROR } from '../util/constant';
//import {dependenciesLambda} from '../util/aws-envvar';
//import awsconfig from '../aws-exports';

//var util = require('util');

var apiName = 'AudioDownloadAPI';
var path = '/transfert';
const statusDict = {
    'Completed': "green",
    'Rejected': "red",
    'Error': "red"
}
class TransfertRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nextToken: null,
            hasMoreTransfertRequest: true,
            listTransferRequest: null,
            loading: true,
            hasResults: false,
            acceptRequestSuccess: false,
            error: false,
            rejectRequestSuccess: false,
            mode: ''
        };
        this.clientId = this.props.clientId
    }

    componentDidMount() {
        this.loadMoreTransfertRequest();
    }

    async loadMoreTransfertRequest() {
        if (!this.state.hasMoreTransfertRequest) return;
        // const isAdmin = this.props.profile && this.props.profile.localeCompare('ITAdmin') === 0;
        this.setState({ loading: true });
        const FETCH_LIMIT = 20;
        let queryArgs = {};
        queryArgs = {
            limit: FETCH_LIMIT,
            id: this.clientId,
            nextToken: this.state.nextToken
        };


        if (!queryArgs.nextToken) delete queryArgs.nextToken;
        let listTransferRequest;
        const { data } = await API.graphql(graphqlOperation(ListTrasfertRequestByClient, queryArgs));
        if (this.state.listTransferRequest === null) {
            listTransferRequest = data.listTransferRequest;
        } else {
            listTransferRequest = this.state.listTransferRequest;
            listTransferRequest.items = listTransferRequest.items.concat(data.listTransferRequest.items);
        }
        if (data.listTransferRequest !== null && data.listTransferRequest.items.length > 0) {
            this.setState({
                listTransferRequest: listTransferRequest,
                loading: false,
                nextToken: data.listTransferRequest.nextToken,
                hasMoreTransfertRequest: data.listTransferRequest.nextToken !== null,
                clientName: listTransferRequest.items[0].client.name
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    acceptRequest = (index, request) => {
        var self = this;
        let listTransferRequest = self.state.listTransferRequest;
        listTransferRequest.items[index].disabled = true;
        self.setState({ listTransferRequest: listTransferRequest });
        //console.log(request)


        Auth.currentAuthenticatedUser().then(value => {
            Auth.currentCredentials()
                .then(credentials => {

                    // new school 

                    let myInit = { // OPTIONAL
                        headers: {},
                        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                        queryStringParameters: {  // OPTIONAL
                            id: request.id,
                            accessToken: value.getSignInUserSession().getAccessToken().getJwtToken()
                        }
                    }
                    API.get(apiName, path, myInit).then(response => {
                        listTransferRequest.items[index].disabled = false;
                        if (response.status === 200) {
                            listTransferRequest.items[index].status = response.data.status;
                            self.setState({ acceptRequestSuccess: true });
                        } else {
                            listTransferRequest.items[index].status = ERROR;
                            console.error(response)
                        }
                        self.setState({ listTransferRequest: listTransferRequest });
                        self.setState({ mode: listTransferRequest.items[index].mode });
                        const timer = setTimeout(() => {
                            self.setState({ acceptRequestSuccess: false });
                            clearTimeout(timer);
                        }, 5000);
                    }).catch(error => {
                        listTransferRequest.items[index].status = ERROR;
                        listTransferRequest.items[index].disabled = false;
                        self.setState({ error: true });
                        const timer = setTimeout(() => {
                            self.setState({ error: false });
                            clearTimeout(timer);
                        }, 5000);
                        this.setState({ listTransferRequest: listTransferRequest });
                    });
                });
        });
    };

    refuseRequest = async (index, request) => {
        let queryArgs = {
            id: request.id,
            status: REJECTED,
            audioRecordClientId: this.clientId
        };
        const { data } = await API.graphql(graphqlOperation(UpdateStatusForTransfertRequest, queryArgs));
        this.state.listTransferRequest.items[index].status = data.updateStatusTransfertRequest.status;
        this.setState({ rejectRequestSuccess: true });
        setTimeout(() => {
            this.setState({ rejectRequestSuccess: false });
        }, 5000);
        this.setState({ listTransferRequest: this.state.listTransferRequest });
    };
    render() {
        if (this.state.loading) {
            return (
                <Message icon>
                    <Icon name='circle notched' loading />
                    <Message.Content>
                        <Message.Header>{I18n.get('Just one second')}</Message.Header>
                        {I18n.get('We are fetching trasnsfert request for you.')}
                    </Message.Content>
                </Message>
            );
        }
        if (!this.state.listTransferRequest) {
            return (
                <Message warning>
                    <Message.Header>{I18n.get('No transfert request found!')}</Message.Header>
                </Message>
            )
        }
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Section href='#'>{I18n.get("Home")}</Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                    <Breadcrumb.Section active href={`#/client/${this.clientId}`}>{this.props.client ? this.props.client.name : this.state.clientName}</Breadcrumb.Section>
                    
                </Breadcrumb>
                <Segment>
                    <Header as='h3'>{I18n.get('Transfert Request')}</Header>
                    {this.state.acceptRequestSuccess && <Message positive>
                        <Message.Header>{I18n.get("Your transfert request has been executed. You'll received an email with")} {I18n.get(this.state.mode)}</Message.Header>
                    </Message>}
                    {this.state.rejectRequestSuccess && <Message positive>
                        <Message.Header>{I18n.get("Your transfert request has been rejected.")}</Message.Header>
                    </Message>}
                    {this.state.error && <Message negative>
                        <Message.Header>{I18n.get("An error occured while executing your transfert request.")}</Message.Header>
                    </Message>}
                    <div style={{ width: "100%", overflowX: "auto" }}>
                        <Table basic='very' striped >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{I18n.get('Id')}</Table.HeaderCell>
                                    <Table.HeaderCell>{I18n.get('UserName')}</Table.HeaderCell>
                                    <Table.HeaderCell>{I18n.get('Created at')}</Table.HeaderCell>
                                    <Table.HeaderCell>{I18n.get('Mode')}</Table.HeaderCell>
                                    <Table.HeaderCell>{I18n.get('Folder name')}</Table.HeaderCell>
                                    <Table.HeaderCell>{I18n.get('Requested Medias')}</Table.HeaderCell>
                                    <Table.HeaderCell>{I18n.get('Status')}</Table.HeaderCell>
                                    <Table.HeaderCell>{I18n.get('Action')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.listTransferRequest.items.map((request, index) => (
                                    <React.Fragment key={'firstFragment' + index}>
                                        <Table.Row key={index}>
                                            <Table.Cell>{request.id}</Table.Cell>
                                            <Table.Cell>{request.userName !== null ? request.userName : request.userId}</Table.Cell>
                                            <Table.Cell>{formatDate(request.createdAt,I18n.get('dateFormat'))}</Table.Cell>
                                            <Table.Cell>
                                                {(request.mode === 'restore' &&
                                                    <Popup
                                                        trigger={<span style={{ textDecorationLine: 'underline', textDecorationStyle: 'dotted' }}>{request.mode}</span>}
                                                        content={<span>{request.restoreDuration} {I18n.get('days')}</span>}
                                                        position='top center'
                                                    />
                                                ) || request.mode}
                                            </Table.Cell>
                                            <Table.Cell>{decodeURIComponent(request.folder)}</Table.Cell>
                                            {(request.files === null && request.audios === null) && <Table.Cell><Icon name="folder" />{I18n.get('Full folder')}</Table.Cell>}
                                            {(request.audios !== null && request.files === null) &&
                                                <Table.Cell>
                                                    <Popup trigger={<Button><Icon name='eye' />{I18n.get('Show audios')}</Button>} flowing hoverable>
                                                        <List bulleted>
                                                            {request.audios && request.audios.map(audio =>
                                                                <List.Item key={audio}>{audio}</List.Item>)}
                                                        </List>
                                                    </Popup>
                                                </Table.Cell>}
                                            {request.files !== null &&
                                                <Table.Cell>
                                                    <Popup trigger={<Button><Icon name='eye' />{I18n.get('Show audios')}</Button>} flowing hoverable>
                                                        <List bulleted>
                                                            {request.files && request.files.map(file =>
                                                                <List.Item key={file.id}>{file.name}</List.Item>)}
                                                        </List>
                                                    </Popup>
                                                </Table.Cell>}
                                            <Table.Cell style={{ fontWeight: "700", color: statusDict[request.status] }}><p color='red'>{I18n.get(request.status)}</p></Table.Cell>
                                            <Table.Cell>
                                                <Button.Group>
                                                    <Button disabled={request.disabled === true || request.status === COMPLETED || request.status === IN_PROGRESS} onClick={this.acceptRequest.bind(this, index, request)} positive>{I18n.get('Accept')}</Button>
                                                    <Button.Or />
                                                    <Button disabled={request.status === COMPLETED || request.status === REJECTED || request.status === IN_PROGRESS} onClick={this.refuseRequest.bind(this, index, request)} negative>{I18n.get('Reject')}</Button>
                                                </Button.Group>
                                            </Table.Cell>                                            
                                        </Table.Row>
                                    </React.Fragment>
                                ))}
                            </Table.Body>
                            <Table.Footer fullWidth>
                                <Table.Row>
                                    {this.state && this.state.hasMoreTransfertRequest && <Table.HeaderCell colSpan='8'>
                                        <Button
                                            floated='left'
                                            onClick={this.loadMoreTransfertRequest.bind(this)}
                                            icon
                                            labelPosition='left'
                                            disabled={this.state.loading}
                                            primary
                                            size='small'
                                        >
                                            <Icon name='refresh' /> {I18n.get('Load More Transfert Request')}
                                        </Button>
                                    </Table.HeaderCell>}
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </div>
                </Segment>
            </>
        )
    }
}
export default TransfertRequest;
