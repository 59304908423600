import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Header, Segment, Table, Message, Button, Modal, Form, Dropdown, Icon, Label} from 'semantic-ui-react';
import { I18n } from 'aws-amplify';
import { PROFILE_OPTIONS } from '../util/cognito'



class UsersList extends Component {

  handleChange = (e, { name, value }) => {
      this.setState({ [name]: value });
  }

  resetInputsState = ()=> {
    this.setState(
      {
        newUserUsername: null,
        newUserEmail: null,
        newUserProfile: null,
        newUserClients: null,
        editUserUsername: null,
        editUserEmail: null,
        editUserProfile: null,
        editUserClients: null,
        editUserOldProfile: null,
        removeUserUsername: null,
        })
  }

  addNewUser = () => {
    if (this.state.newUserUsername && this.state.newUserEmail) {
      this.props.addNewUser(this.state.newUserUsername, this.state.newUserEmail, this.state.newUserProfile, this.state.newUserClients)
      this.resetInputsState()
      this.setState(
        {
          addUserModalOpen: false,
        })
    }
  }

  editUser = () => {
    if (this.state.editUserUsername && this.state.editUserEmail) {
      this.props.editUser(this.state.editUserUsername, this.state.editUserEmail, this.state.editUserProfile, this.state.editUserClients, this.state.editUserOldProfile)
      this.resetInputsState()
      this.setState(
        {
          editUserModalOpen: false,
        })
    }
  }

  deleteUser = () => {
    if (this.state.removeUserUsername) {
      this.props.deleteUser(this.state.removeUserUsername)
      this.resetInputsState()
      this.setState(
        {
          removeUserModalOpen: false,
        })
    }
  }


  handleOpen = (e, { name }) => { this.setState({ [name]: true }) }

  handleClose = (e, { name }) => { 
    this.setState({ [name]: false }) 
    this.resetInputsState()
  }

  handleUserEditModalOpen(user){
    this.setState(
      { 
        editUserModalOpen: true,
        editUserUsername: user.Username,
        editUserEmail: user.Attributes.email,
        editUserProfile: user.Attributes.profile,
        editUserClients: user.Clients,
        editUserOldProfile: user.Attributes.profile
        }
    )
  }

  handleUserRemoveModalOpen(user){
    this.setState(
      {
        removeUserModalOpen: true,
        removeUserUsername: user.Username,
      }
    )
  }

  formatClientsListForSelect(clientsList) {
    let list = []
    list = list.concat(Object.values(clientsList).map(x => ( {text: x.name, value: x.id})))
    return list
  }

  constructor(props) {
    super(props)
    this.state = {
      newUserUsername: null,
      newUserEmail: null,
      newUserProfile: null,
      newUserClients: null,
      addUserModalOpen: false,
      editUserModalOpen: false,
      removeUserModalOpen: false,
    }
  }


  render() {
    return (
      <Segment>
        <Header as='h3'>{I18n.get('Users list')}


          <Modal open={this.state.addUserModalOpen} onClose={this.handleClose} name="addUserModalOpen" trigger={<Button primary floated='right' onClick={this.handleOpen} className="headerButton" name="addUserModalOpen" ><Icon name='user add' />{I18n.get("Add new user")}</Button>} closeIcon>
            <Header icon='add user' content={I18n.get('Add new user')} />
            <Modal.Content>
              <Form>
                <Form.Field>
                  <label>{I18n.get('Username')}</label>
                  <Form.Input fluid name='newUserUsername' placeholder={I18n.get('Username')} onChange={this.handleChange} />
                </Form.Field>
                <Form.Field>
                  <label>{I18n.get('Email')}</label>
                  <Form.Input type='email' fluid name='newUserEmail' placeholder={I18n.get('Email')} onChange={this.handleChange} />
                </Form.Field>
                <Form.Field>
                  <label>{I18n.get('Profile')}</label>
                  <Form.Select fluid
                    options={PROFILE_OPTIONS}
                    placeholder={I18n.get('Profile')}
                    name='newUserProfile'
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{I18n.get('Clients')}</label>
                  <Dropdown
                    name='newUserClients'
                    placeholder={I18n.get('Clients')}
                    fluid
                    multiple
                    search
                    selection
                    noResultsMessage={I18n.get('No clients found')}
                    options={this.formatClientsListForSelect(this.props.clientsList)}
                    onChange={this.handleChange}
                  />
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                type='submit'
                primary
                icon='checkmark'
                labelPosition='right'
                content={I18n.get('Add user')}
                onClick={this.addNewUser}
                disabled={!(this.state.newUserUsername && this.state.newUserEmail)}
              />

            </Modal.Actions>
          </Modal>
        </Header>

        {this.props.showAddSuccess &&
          <Message positive>
            <Message.Header>{I18n.get('User added successfully')}</Message.Header>
          </Message>}

        {this.props.showEditSuccess &&
          <Message positive>
            <Message.Header>{I18n.get('User edited successfully')}</Message.Header>
          </Message>}

        {this.props.showDeleteSuccess &&
          <Message positive>
            <Message.Header>{I18n.get('User deleted successfully')}</Message.Header>
          </Message>}

        {this.props.inputVerification.username==='exists' &&
          <Message error>
            <Message.Header>{I18n.get('Username already exists')}</Message.Header>
          </Message>}

        {this.props.inputVerification.email==='invalid' &&
          <Message error>
            <Message.Header>{I18n.get('Invalid email address format')}</Message.Header>
          </Message>}

        <Table basic='very' celled >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{I18n.get('Username')}</Table.HeaderCell>
              <Table.HeaderCell>{I18n.get('Profile')}</Table.HeaderCell>
              <Table.HeaderCell>{I18n.get('Email')}</Table.HeaderCell>
              <Table.HeaderCell>{I18n.get('Clients')}</Table.HeaderCell>

              <Table.HeaderCell></Table.HeaderCell>


            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              this.props.users.map((user, index) => (
                <Table.Row key={user.Username}>
                  <Table.Cell>
                    <p className={user.Enabled ? 'activeUser': 'disabledUser'}>{user.Username}</p>

                  </Table.Cell>
                  <Table.Cell>
                    {user.Attributes.profile}
                  </Table.Cell>
                  <Table.Cell>
                    <a href={"mailto:" + user.Attributes.email}>{user.Attributes.email}</a>
                  </Table.Cell>
                  <Table.Cell>
                    { 
                      user.Clients ? user.Clients.map(client => {
                        // debugger;
                        // console.log()
                        return <Label key={client}><NavLink to={`/client/${client}`}>
                            
                              <Icon name='user' />
                              {this.props.clientsList[client] ? this.props.clientsList[client].name : null}
                            
                          </NavLink></Label>
                      })
                        
                      // 
                      
                       : null
                    }
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Button.Group color='blue'>
                      <Button icon labelPosition='left' onClick={() => this.handleUserEditModalOpen(user)} name="editUserModalOpen"><Icon name='edit'/>{I18n.get('Edit')}</Button>
                      <Dropdown className='button icon' >
                        <Dropdown.Menu>
                          {
                            user.Enabled ?
                            <Dropdown.Item onClick={() => this.props.disableUser(user.Username)}>{I18n.get('Disable')}</Dropdown.Item> :
                            <Dropdown.Item onClick={() => this.props.enableUser(user.Username)}>{I18n.get('Enable')}</Dropdown.Item>
                          }
                          <Dropdown.Item onClick={() => this.handleUserRemoveModalOpen(user)} name="removeUserModalOpen">{I18n.get('Delete')}</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Button.Group>

                    
                  </Table.Cell>
                </Table.Row>
              ))}
              <Modal open={this.state.editUserModalOpen} onClose={this.handleClose} name="editUserModalOpen" closeIcon>
                      <Header icon='user' content={I18n.get('Edit user')} />
                      <Modal.Content>
                        <Form>
                          <Form.Field>
                            <label>{I18n.get('Username')}</label>
                            <Form.Input fluid name='editUserUsername' disabled placeholder={I18n.get('Username')} value={this.state.editUserUsername} />
                          </Form.Field>
                          <Form.Field>
                            <label>{I18n.get('Email')}</label>
                            <Form.Input type='email' fluid name='editUserEmail' placeholder={I18n.get('Email')} value={this.state.editUserEmail} onChange={this.handleChange} />
                          </Form.Field>
                          <Form.Field>
                            <label>{I18n.get('Profile')}</label>
                            <Form.Select fluid
                              options={PROFILE_OPTIONS}
                              value={this.state.editUserProfile}
                              placeholder={I18n.get('Profile')}
                              name='editUserProfile'
                              onChange={this.handleChange}
                            />
                          </Form.Field>

                          <Form.Field>
                            <label>{I18n.get('Clients')}</label>
                            <Dropdown
                              name='editUserClients'
                              placeholder={I18n.get('Clients')}
                              fluid
                              multiple
                              search
                              selection
                              noResultsMessage={I18n.get('No clients found')}
                              value={this.state.editUserClients}
                              options={this.formatClientsListForSelect(this.props.clientsList)}
                              onChange={this.handleChange}
                            />
                          </Form.Field>


                        </Form>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          type='submit'
                          primary
                          icon='checkmark'
                          labelPosition='right'
                          content={I18n.get('Edit user')}
                          onClick={this.editUser}
                          disabled={!( this.state.editUserEmail)}
                        />

                      </Modal.Actions>
                    </Modal>
                    <Modal basic size='small' open={this.state.removeUserModalOpen} onClose={this.handleClose} name="removeUserModalOpen" closeIcon>
                      <Header icon>
                        <Icon name='user delete' />
                        {I18n.get('Delete User?')}
                      </Header>
                      <Modal.Content>
                        <p>
                          {I18n.get('Are you sure you want to delete the user')} <b>{this.state.removeUserUsername}</b> ?
                        </p>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button color='red' inverted onClick={this.handleClose} name="removeUserModalOpen">
                          <Icon name='remove' /> {I18n.get('Cancel')}
                        </Button>
                        <Button color='green' inverted onClick={this.deleteUser}>
                          <Icon name='checkmark' /> {I18n.get('Confirm')}
                        </Button>
                      </Modal.Actions>
                    </Modal>
          </Table.Body>
        </Table>

      </Segment>
    );
  }
}

export default UsersList;