import React, { Component } from 'react'
import { I18n } from 'aws-amplify';
import Auth from '@aws-amplify/auth'
import API from '@aws-amplify/api'
import { Header, Segment, Dimmer, Loader, Image, Card, Grid } from 'semantic-ui-react';
import Chart from '../shared/barchart';
import BudgetCard from './budgetCard';

const ceTypes = ['Storage', 'Transfer', 'Other'];

class CostDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            costExplorerData: [],
            budgetsData: [],
            budgetsLoading: true,
        };
    }

    async componentDidMount() {
        this.getCostExplorerDataApiCall();
        this.getBudgetsDataApiCall();
    }

    getCostExplorerDataApiCall = async () => {
        const apiName = 'AudioDownloadAPI';
        const path = "/dashboard/costs";
        const self = this;

        await Auth.currentAuthenticatedUser().then(_user => {
            Auth.currentCredentials().then(_credentials => {
                const params = { response: true };
                API.get(apiName, path, params).then(response => {
                    if (response.status === 200) {
                        this.setState({costExplorerData: response.data})
                    } else {
                        self.setState({ error: I18n.get("Unable to retrieve costs") });
                        const timer = setTimeout(() => {
                            self.setState({ error: false });
                            clearTimeout(timer);
                        }, 5000);
                    }
                }).catch(_error => {
                    self.setState({ error: I18n.get("Unable to retrieve costs") });
                    const timer = setTimeout(() => {
                        self.setState({ error: false });
                        clearTimeout(timer);
                    }, 5000);
                });
            });
        });
    }

    getBudgetsDataApiCall = async () => {
        const apiName = 'AudioDownloadAPI';
        const path = "/dashboard/budgets";
        const self = this;

        this.setState({ budgetsLoading: true });

        await Auth.currentAuthenticatedUser().then(_user => {
            Auth.currentCredentials().then(_credentials => {
                const params = { response: true };
                API.get(apiName, path, params).then(response => {
                    if (response.status === 200) {
                        self.setState({ budgetsData: response.data, budgetsLoading: false });
                    } else {
                        self.setState({ error: I18n.get("Unable to retrieve budgets"), budgetsLoading: false });
                        const timer = setTimeout(() => {
                            self.setState({ error: false });
                            clearTimeout(timer);
                        }, 5000);
                    }
                }).catch(_error => {
                    self.setState({ error: I18n.get("Unable to retrieve budgets"), budgetsLoading: false });
                    const timer = setTimeout(() => {
                        self.setState({ error: false });
                        clearTimeout(timer);
                    }, 5000);
                });
            });
        });
    }

    costExplorerDashboardDataProcess = (data) => {
        const lines = []
        for (let i = 0; i < 30; i++) {
            const line = {'x': new Date(data[0].ResultsByTime[i].TimePeriod.Start).toLocaleDateString(), 'y0': Number(data[0].ResultsByTime[i].Total.UnblendedCost.Amount), 'y1': Number(data[1].ResultsByTime[i].Total.UnblendedCost.Amount), 'y2': Number(data[2].ResultsByTime[i].Total.UnblendedCost.Amount)}
            lines.push(line)
        }
        for (var i = 0; i < 30; i++) {lines[i].y2 -= lines[i].y0 + lines[i].y1;}
        return lines
    }

    roundCost = (cost) => {
        if (cost >= 1) {
            return cost.toFixed(2);
        }
        cost = cost.toPrecision(2);
        return cost.toString().includes("e-") ? 0 : cost;
    }

    render() {

        return (
            <React.Fragment>

                <Segment>

                    <Header as="h3">{I18n.get('Costs Dashboard')}</Header>

                    {(this.state.costExplorerData.length > 0 &&
                        <Chart
                            title={I18n.get('Cost ($)')}
                            data={this.costExplorerDashboardDataProcess(this.state.costExplorerData)}
                            crosshairTitleFormat={d => [{ title: 'Date', value: d.x}]}
                            crosshairItemsFormat={d => {
                                const storage_costs = d.y0;
                                const transfer_costs = d.y1;
                                const other_costs = d.y2;
                                return [
                                    { title: I18n.get('Storage'), value: this.roundCost(storage_costs) + ' ($)' },
                                    { title: I18n.get('Transfer'), value: this.roundCost(transfer_costs) + ' ($)' },
                                    { title: I18n.get('Other'), value: this.roundCost(other_costs) + ' ($)' },
                                    { title: I18n.get('Total cost'), value: this.roundCost(storage_costs + transfer_costs + other_costs) + ' ($)' }
                                ]
                            }}
                            legends={ceTypes.map(I18n.get)}
                        />
                    ) || (
                        <Segment style={{ height: "290px", marginTop: 0, marginBottom: "10px", display: "flex", alignItems: "center" }}>
                            <Dimmer active inverted>
                                <Loader inverted content={I18n.get('Loading costs metrics')} />
                            </Dimmer>
                            <Image fluid src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                        </Segment>
                    )}

                </Segment>

                <Segment>
                    <Header as="h3">{I18n.get('Budgets')}</Header>
                    {this.state.budgetsData.length > 0 &&
                        <React.Fragment>
                            <Card.Group as={Grid} columns={3}>
                                {this.state.budgetsData.map(budgetData =>
                                    <Grid.Column key={budgetData.BudgetName}>
                                        <BudgetCard budget={budgetData} updateBudgetsFunction={this.getBudgetsDataApiCall} />
                                    </Grid.Column>
                                )}
                            </Card.Group>
                        </React.Fragment>
                    }
                    {this.state.budgetsLoading &&
                        <React.Fragment>
                            <Dimmer active inverted>
                                <Loader inverted content={I18n.get('Loading budgets')} />
                            </Dimmer>
                            {this.state.budgetsData.length === 0 && <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />}
                        </React.Fragment>
                    }
                </Segment>
            </React.Fragment>
        );
    }
}

export default CostDashboard;