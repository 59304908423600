
import React, { useEffect, useState} from 'react';
import UsersList from './userslist';
import { getUsers, createUser, editUser, disableUser, enableUser, deleteUser, formatAttributes } from '../util/cognito';
import { API } from '@aws-amplify/api'
import { graphqlOperation } from 'aws-amplify';
import { ListClients, SubscribeToNewClients } from '../util/query';

const UsersListLoader = () => {

  const [users, setUsers] = useState([])
  const [clients, setClients] = useState([])
  const [inputVerification, setInputVerification] = useState({'email': null, 'password': null, 'username': null})
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)
  const [showAddSuccess, setShowAddSuccess] = useState(false)
  const [showEditSuccess, setShowEditSuccess] = useState(false)

  const getCognitoUsers = () => {
    getUsers().then((result) => { setUsers(result)})
  }

  const addNewUserFunction = (username,email,profile,client) => {
    createUser(username,email,profile,client).then((user) => {
        user.Attributes = formatAttributes(user.Attributes)
        getCognitoUsers()
        setShowAddSuccess(true)
        setShowEditSuccess(false)
    }).catch((err) => {
        setShowAddSuccess(false)
        switch (err.__type) {
          case "UsernameExistsException":
            setInputVerification({'username': 'exists', 'email': null, 'password': null})
            break;
          case "InvalidParameterException":
            setInputVerification({'username': null, 'email': 'invalid', 'password': null})
            break;
          default:
            setInputVerification({'username': null, 'email': null, 'password': null})
            break;
        }
    })
  }

  const editUserFunction = (username, email, profile, client, oldProfile) => {
    editUser(username, email, profile, client, oldProfile).then( () => {
      getCognitoUsers()
      setShowAddSuccess(false)
      setShowEditSuccess(true)
    }).catch((err) => {
        setShowEditSuccess(false)
        switch (err.__type) {
          case "InvalidParameterException":
            setInputVerification({'username': null, 'email': 'invalid', 'password': null})
            break;
          default:
            setInputVerification({'username': null, 'email': null, 'password': null})
            break;
        }
    })      
  }

  const formatClientList = (array) => {
    if(array)
      return array.reduce( (acc,cur) => {
        acc[cur.id] = cur
      return acc
    },{})
  }

  const disableUserFunction = (username) => {
    disableUser(username).then(() => {
      getCognitoUsers()
    })
  }

  const enableUserFunction = (username) => {
    enableUser(username).then(() => {
      getCognitoUsers()
    })
  }

  const deleteUserFunction = (username) => {
    deleteUser(username).then(() => {
      getCognitoUsers()
      setShowDeleteSuccess(true)
    })
  }

  useEffect(() => {

    const fetchClients = async () => {
      const returnedAPIData = await API.graphql(graphqlOperation(ListClients))
      setClients(returnedAPIData.data.listClients.items)
    }

    const subscription = async () => {
      await API.graphql(graphqlOperation(SubscribeToNewClients)).subscribe({
        next: (result) => {
          const newClient = result.value.data.onCreateClient
          setClients(oldClients => [...oldClients, newClient])
        }
      })
    }

    getCognitoUsers()
    fetchClients()
    subscription()

  }, [])

  return(
    <>
      {<UsersList clientsList={formatClientList(clients)} users={users} addNewUser={addNewUserFunction} editUser={editUserFunction} inputVerification={inputVerification} showAddSuccess={showAddSuccess} showEditSuccess={showEditSuccess} showDeleteSuccess={showDeleteSuccess} disableUser={disableUserFunction} enableUser={enableUserFunction} deleteUser={deleteUserFunction}/>}
    </>
  )   

}

export default UsersListLoader