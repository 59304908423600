import React, { useState, useEffect} from 'react';
import {API, graphqlOperation} from 'aws-amplify'
import { ListClients, SubscribeToNewArchiveClients } from '../util/query';
import { Loader } from '@aws-amplify/ui-react';
import ArchiveClientList from './archiveclientlist';

const ArchiveClientListLoader = () => {

  const [clients, setClients] = useState([])
  const [loading,setLoading] = useState(true)

  useEffect(() => {

    const fetch = async () => {
      const returnedAPIData = await API.graphql(graphqlOperation(ListClients))
      setClients(returnedAPIData.data.listClients.items)
      setLoading(false)
    }

    const subscription = async () => {
      await API.graphql(graphqlOperation(SubscribeToNewArchiveClients)).subscribe({
        next: (result) => {
          const newClient = result.value.data.onCreateClient
          newClient.updatedAt = new Date().toISOString();
          setClients(oldClients => [...oldClients, newClient])
        }
      })
    }

    fetch()
    subscription()
    
  }, [])

  return(
    <>
      {loading && <Loader size="large" filledColor="#2185d0"/>}
      {clients && <ArchiveClientList clients={clients}/>}
    </>
  );

}

export default ArchiveClientListLoader;