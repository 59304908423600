import React, { Component } from 'react';
import { Modal, Icon, Message, Button, Form } from 'semantic-ui-react';
import { I18n } from 'aws-amplify';
import { generateFolderPathWithLinks } from '../../../util/folder';

const initialState = {
    loading: false,
    error: null,
}

/**
 * DeleteFolderModal component props:
 *     
 *     open: boolean,
 *     client: {Client object},
 *     folder: "folder path",
 *     deleteFolder: function(folderPath),
 *     onClose: function,
 */
class DeleteFolderModal extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    onModalOpen = () => {
        this.setState({ ...initialState });
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open)
            this.onModalOpen();
    }

    handleChange = (_e, { name, value }) => {
        this.setState({ [name]: value || '' });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({ loading: true });
        this.props.deleteFolder(this.props.folder).then(() => {
            this.props.onClose();
        }).catch(_err => this.setState({ loading: false, error: I18n.get('Could not delete the folder') }));
    }

    render() {
        return (
            <Modal
                as={Form}
                style={{ width: 'unset', height: 'unset' }}
                dimmer='blurring'
                closeOnDimmerClick={!this.state.loading}
                closeIcon={!this.state.loading}
                open={this.props.open}
                onClose={this.props.onClose}
                onClick={e => e.stopPropagation()}
                error={this.state.error !== null}
            >
                <Modal.Header color='red'>
                    <Icon name='trash' />{I18n.get('Remove folder')}
                </Modal.Header>
                <Modal.Content>
                    <Message error>
                        <Message.Header>{this.state.error}</Message.Header>
                    </Message>
                    <p>{I18n.get('Deleting')} {generateFolderPathWithLinks(this.props.folder, this.props.client.id)}</p>
                    <Message warning visible>
                        <Message.Header>{I18n.get('Warning')}</Message.Header>
                        <Message.Content>{I18n.get('Deleting folder will permanently delete content if none are locked.')}</Message.Content>
                    </Message>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='red'
                        icon='trash'
                        labelPosition='left'
                        content={I18n.get('Remove folder')}
                        onClick={this.handleSubmit}
                        loading={this.state.loading}
                        disabled={this.state.error !== null || this.state.loading}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default DeleteFolderModal;