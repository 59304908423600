import aws_exports from '../aws-exports';
import Auth from '@aws-amplify/auth'
import { I18n } from 'aws-amplify';
import { CognitoIdentityProvider } from '@aws-sdk/client-cognito-identity-provider'
let cognitoidp = null

export const PROFILE_OPTIONS = [
    {text: I18n.get('None'), value:null},
    {text: 'IT Admin', value: 'ITAdmin' },
    {text: 'CM', value: 'CM' },
    {text: 'FM', value: 'FM' },
  ]

const SPECIAL_GROUPS = ['ITAdmin', 'FM', 'CM']

export const COGNITO_CREATE_USERS_ERRORS = {
    'Invalid email address format.': 'email',
    "1 validation error detected: Value at 'temporaryPassword' failed to satisfy constraint: Member must have length greater than or equal to 6": "password",
    "Password did not conform with password policy: Password not long enough": "password",
}
  

async function getCognitoClient() {
    if(cognitoidp === null) {
        let credentials = await Auth.currentCredentials()
        cognitoidp = new CognitoIdentityProvider({
            region: aws_exports.aws_appsync_region,
            credentials: Auth.essentialCredentials(credentials)
        });
    }
}

function listUserGroups(username){
    return new Promise(async function(resolve, reject) {
        await getCognitoClient()
        // Retrieve user groups and check if user already in group
        var listGroupsParams = {
            UserPoolId: aws_exports.aws_user_pools_id,
            Username: username
            };
        cognitoidp.adminListGroupsForUser(listGroupsParams, function(err, data) {
            if (err) reject(err); // an error occurred
            else {
                resolve(data.Groups)
            }
        })
    })
}
function addUserToGroup(username,groupname) {
    return new Promise(async function(resolve, reject) {
        await getCognitoClient()
        var addUserToGroupParams = {
            GroupName: groupname,
            UserPoolId: aws_exports.aws_user_pools_id,
            Username: username
        };
        let specialGroups = SPECIAL_GROUPS
        if( specialGroups.includes(groupname) ){
            let groups = (await listUserGroups(username)).map(x => x.GroupName).filter(x => (specialGroups.includes(x)) )
            if(groups.includes(groupname))
                resolve()
            else {
                groups.forEach( async (group) => {
                    await removeUserFromGroup(username, group)
                });
            }
        }
        cognitoidp.adminAddUserToGroup(addUserToGroupParams, function(err, data) {
            if (err) reject(err);
            if(data) resolve()
        });
    })
}

function removeUserFromGroup(username,groupname) {
    return new Promise(async function(resolve, reject) {
        await getCognitoClient()
        var removeUserFromGroupParams = {
            GroupName: groupname,
            UserPoolId: aws_exports.aws_user_pools_id,
            Username: username
        };
        cognitoidp.adminRemoveUserFromGroup(removeUserFromGroupParams, function(err, data) {
            if (err) reject(err);
            if(data) resolve()
        });
    })
}

export function createGroup(groupId, groupName) {
    return new Promise(async function(resolve, reject) {
        await getCognitoClient()
        var createGroupParams = {
            GroupName: groupId,
            UserPoolId: aws_exports.aws_user_pools_id,
            Description: groupName
          };
        cognitoidp.createGroup(createGroupParams, function(err, data) {
            if (err) reject(err);
            if(data) resolve()
         });

    })
}

async function addClientsToUser(username, groups, oldGroups) {
    await getCognitoClient()
    // groups variable represents the group designed
    // oldGroups variable represents the group the user belongs to
    let groupsToBeDeleted = oldGroups.filter(x => !groups.includes(x))
    let groupsToBeAdded = groups.filter(x => !oldGroups.includes(x))
    let promises = []
    // Add user to groups
    groupsToBeAdded.forEach(groupId => {
        promises.push(addUserToGroup(username, groupId))
    })

    // Remove user from other groups
    groupsToBeDeleted.forEach(groupId => {
        promises.push(removeUserFromGroup(username, groupId))
    })

    // Wait for promises
    await Promise.all(promises)

}

export function getUsers(){
    return new Promise(async function(resolve, reject) {
        await getCognitoClient()
        var params = {
            UserPoolId: aws_exports.aws_user_pools_id,
        };
        cognitoidp.listUsers(params, async (err, data) => {
            if (err) reject(err)
            else {
                let pagination = data.PaginationToken
                let users = data.Users
                while(pagination){
                    try {
                        users = [...users, ...await cognitoidp.listUsers(params)];
                    } catch (err) {
                        reject(err);
                    }
                }
                // Reduce the Attributes array into an object to simplify the data structure
                // To optimize get groups for users
                users = await Promise.all(users.map( async user => {
                        let groups = await listUserGroups(user.Username)
                        user.Clients = groups.map(x => (x.GroupName !== "ITAdmin" && x.GroupName !== "FM" && x.GroupName !== "CM") ? x.GroupName : false).filter(Boolean)
                        user.Attributes = formatAttributes(user.Attributes)
                        user.Attributes.profile = groups.map(x => x.GroupName).filter( x => x === "CM" || x === "FM" || x === "ITAdmin")[0];
                        return user
                    }
                ))
                resolve(users)
            }
        });
    })
}

export async function createUser(username,email,profile,clients){
    return new Promise(async function(resolve, reject) {
        await getCognitoClient()
        var params = {
            UserPoolId: aws_exports.aws_user_pools_id,
            Username: username,
            UserAttributes: [
                {
                  Name: 'email',
                  Value: email
                }
            ]
        };
        // if(profile)
        //     params.UserAttributes.push(
        //         {
        //             Name: 'profile',
        //             Value: profile
        //         }
        //     )
        cognitoidp.adminCreateUser(params, async function(err, data) {
            if (err) reject(err)
            else {
                let user = data.User
                if(profile){
                    await addUserToGroup(username,profile)
                }
                if(clients){
                    await addClientsToUser(username, clients, [])
                }
                resolve(user)
            }
        });
    })
}

export async function editUser(username,email,profile,clients){
    return new Promise(async function(resolve, reject) {
        await getCognitoClient()
        var params = {
            UserPoolId: aws_exports.aws_user_pools_id,
            Username: username,
            UserAttributes: [
                {
                  Name: 'email',
                  Value: email
                }
            ]
        };
        // if(profile)
        //     params.UserAttributes.push(
        //         {
        //             Name: 'profile',
        //             Value: profile
        //         }
        //     )
        cognitoidp.adminUpdateUserAttributes(params, async function(err, data) {
            if (err) reject(err)
            else {
                let user = data.User
                if(profile){
                    await addUserToGroup(username,profile)
                }
                if(clients){
                    await addClientsToUser(username, clients, [])
                }
                resolve(user)
            }
        });
    })
}

/*export async function editUser(username,email,profile,clients,hadProfile){
    return new Promise(async function(resolve, reject) {
        await getCognitoClient()
        let oldGroups = (await listUserGroups(username)).map(x => x.GroupName)
        let oldGroupsFiltered = oldGroups.filter( x => !SPECIAL_GROUPS.includes(x))

        // if(profile)
        //     params.UserAttributes.push(
        //         {
        //             Name: 'profile',
        //             Value: profile
        //         }
        //     )
        // else {
        //     if(hadProfile){
        //         if(hadProfile === "ITAdmin" && profile !== "ITAdmin"){
        //             await removeUserFromGroup(username, "ITAdmin")
        //         }
        //         await deleteProfileAttribute(username)
        //     }
        // }
        cognitoidp.adminUpdateUserAttributes(params, async function(err, data) {
            if (err) reject(err)
            else {
                if(profile)
                    await addUserToGroup(username,profile)
                else {
                    SPECIAL_GROUPS.forEach(async (group) => {
                        if(oldGroups.includes(group)){
                            await removeUserFromGroup(username,group)
                        }
                    })
                }

                if(clients){
                    await addClientsToUser(username, clients, oldGroupsFiltered )
                }
                else if(oldGroups) {
                    await addClientsToUser(username, [], oldGroupsFiltered )
                }
                resolve()
            }
        });
    })
}*/

// This function is used to convert Attributes array into an object for simpler usage
export function formatAttributes(array) {
    return array.reduce((obj, item) => {
        // remove custom prefix from custom attributes
        let prefix = "custom:"
        if(item['Name'].startsWith(prefix))
            obj[item['Name'].slice(prefix.length)] = item['Value']
        else
            obj[item['Name']] = item['Value']
        
        return obj  
    }, {})
}


export function deleteUser(username) {
    return new Promise(async function(resolve, reject) {
        await getCognitoClient()

        var params = {
            UserPoolId: aws_exports.aws_user_pools_id,
            Username: username
        };
        cognitoidp.adminDeleteUser(params, function(err, data) {
            if (err) reject(err); // an error occurred
            else     resolve(data);           // successful response
        });

    })
}

export function disableUser(username) {
    return new Promise(async function(resolve, reject) {
        await getCognitoClient()
        var params = {
            UserPoolId: aws_exports.aws_user_pools_id,
            Username: username
        };
        cognitoidp.adminDisableUser(params, function(err, data) {
            if (err) reject(err); // an error occurred
            else     resolve(data);           // successful response
        });

    })
}

export function enableUser(username) {
    return new Promise(async function(resolve, reject) {
        await getCognitoClient()
        var params = {
            UserPoolId: aws_exports.aws_user_pools_id,
            Username: username
        };
        cognitoidp.adminEnableUser(params, function(err, data) {
            if (err) reject(err); // an error occurred
            else     resolve(data);           // successful response
        });

    })
}