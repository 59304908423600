import React, { Component } from 'react';
import { Breadcrumb, Label, Button } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { graphqlOperation, I18n } from 'aws-amplify';
import API from '@aws-amplify/api'
import { config } from '../../config';
import S3FileUploadModal from './s3FileUploadModal';
import { updateClientQuery } from '../../util/query';
import { folderArray, folderMap } from '../../util/folder';

const initialState = {
    createFolderLoading: false,
    createFolderError: false,
}

/**
 * ClientViewHeader component props:
 * 
 *     profile: "Role (ITAdmin, CM, FM)",
 *     client: {Client object},
 *     folderList: ["folders"],
 *     updateFolderList: (["newFolders"]) => void,
 *     folder: "Folder name",
 *     reloadFileList: function,
 */
class ClientViewHeader extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    isAdmin = () => this.props.profile === 'ITAdmin' || this.props.profile === 'CM';

    isFolderMissing = () => {
        // Do not consider a folder missing if root
        if (!this.props.folder)
            return false;
        return !this.props.folderList.includes(decodeURIComponent(this.props.folder));
    }

    createFolder = () => {
        this.setState({ createFolderLoading: true });
        const newFolderList = [...this.props.folderList, decodeURIComponent(this.props.folder)];

        folderMap(newFolderList).then(async data => {
            let params = JSON.stringify(await folderArray(data)).replace(/"(\w+)"\s*:/g, '$1:');
            API.graphql(graphqlOperation(updateClientQuery(null, params, null), { id: this.props.client.id })).then(() => {
                this.setState({ createFolderLoading: false }, () => this.props.updateFolderList(newFolderList));
            });
        });
    }

    FoldersBreadcrumb = ({ folderURI }) => {
        // "videos/bla/test" => ["videos", "videos/bla", "videos/bla/test"]
        let folders = folderURI.split('/');
        folders = folders.map((_f, index) => folders.slice(0, index + 1).join('/'));

        return folders.map((folder, index) =>
            <React.Fragment key={index}>
                <Breadcrumb.Divider icon='right angle' />
                <Breadcrumb.Section
                    active={index === folders.length - 1}
                    href={`#/client/${this.props.client.id}/folder/${encodeURIComponent(folder)}`}
                >
                    {folder.split('/').pop()}
                </Breadcrumb.Section>
            </React.Fragment>
        );
    }

    render() {
        const { client, folder } = this.props;
        const folderURI = folder ? decodeURIComponent(folder) : null;

        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Section href='#'>{I18n.get("Home")}</Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                    <Breadcrumb.Section active={!folder} href={`#/client/${client.id}`}>{client.name}</Breadcrumb.Section>
                    {folder && <this.FoldersBreadcrumb folderURI={folderURI} />}
                    {this.isFolderMissing() &&
                        <React.Fragment>
                            <Breadcrumb.Divider icon='ellipsis vertical' />
                            <Breadcrumb.Section><Label icon='warning sign' content={I18n.get("Missing folder")} color='orange' /></Breadcrumb.Section>
                        </React.Fragment>
                    }
                </Breadcrumb>

                {this.isAdmin() &&
                    <div className="clientButtons">
                        <S3FileUploadModal clientId={this.props.client.id} folderName={folderURI} reloadFileList={this.props.reloadFileList} />
                        {config.FUNC_TRANSFERT &&
                            <NavLink to={`/transfert/${this.props.client.id}`}>
                                <Button size="small" floated="right">{I18n.get('Transfer Request')}</Button>
                            </NavLink>
                        }
                        {this.isFolderMissing() &&
                            <Button
                                icon='plus'
                                labelPosition='left'
                                size='small'
                                onClick={this.createFolder}
                                primary
                                content={I18n.get('Create folder')}
                                loading={this.state.createFolderLoading}
                                disabled={this.state.createFolderLoading}
                            />
                        }
                    </div>
                }
            </>
        );
    }
}

export default ClientViewHeader;