import React, { Component } from 'react';
import { Segment, Header, Dimmer, Loader, Image, Card } from 'semantic-ui-react';
import { I18n } from 'aws-amplify';
import API from '@aws-amplify/api'
import Chart from '../shared/linechart';

class PerformancesDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        this.retrievePerformanceMetrics();
    }

    retrievePerformanceMetrics = async () => {
        API.get('AudioDownloadAPI', '/dashboard/performance-metrics', { response: true }).then(response => {
            if (response.status === 200) {
                this.processStat("zipPerformances", [response.data.ZipPerformances]);
                this.processStat("downloadPerformances", [response.data.DownloadPerformances]);
                this.processStat("recordProcessorPerformances", [response.data.RecordProcessorPerformances]);
                this.processStat("appsyncLatency", [response.data.AppSyncLatency]);
                this.setState({ loading: false });
            } else throw new Error(`Error ${response.status}`);
        }).catch(error => {
            console.error(error);
            this.setState({ loading: false });
        })
    }

    processStat = (dataName, data) => {
        let m = data.map(datapoints =>
            Object.keys(datapoints).map((key) => ({ x: parseInt(key), y: datapoints[key] })).sort((a, b) => a.x - b.x)
        );
        let lines = []
        for (let i = 0; i < m[0].length; i++) {
            const line = {'x': new Date(m[0][i].x).toLocaleString(), 'y0': m[0][i].y}
            lines.push(line)
        }
        this.setState({ [`${dataName}Lines`]: lines})
    }

    render() {

        return (
            <Segment>

                <Header as="h3">{I18n.get('Performances Dashboard')}</Header>

                {(!this.state.loading &&
                    <>
                        <Card style={{ width: 'unset', height: 'unset' }}>
                            <Card.Content>
                                <Card.Header>{I18n.get('ZIP download performances')}</Card.Header>
                                <Chart
                                    yFormat={d => `${d} ${I18n.get('ms/B')}`}
                                    legends={[I18n.get('Performances')]}
                                    data={this.state.zipPerformancesLines}
                                    crosshairTitleFormat={d => [{ title: 'Date', value: d.x }]}
                                    crosshairItemsFormat={d => [{ title: I18n.get('Performances'), value: d.y0.toFixed(2)+" "+I18n.get('ms/B')}]}
                                />
                            </Card.Content>
                        </Card>
                        
                        <Card style={{ width: 'unset', height: 'unset' }}>
                            <Card.Content>
                                <Card.Header>{I18n.get('Download performances')}</Card.Header>
                                <Chart
                                    yFormat={d => `${d} ${I18n.get('ms')}`}
                                    legends={[I18n.get('Performances')]}
                                    data={this.state.downloadPerformancesLines}
                                    crosshairTitleFormat={d => [{ title: 'Date', value: d.x}]}
                                    crosshairItemsFormat={d => [{ title: I18n.get('Performances'), value: d.y0.toFixed(2)+" ms" }]}
                                />
                            </Card.Content>
                        </Card>
                        
                        <Card style={{ width: 'unset', height: 'unset' }}>
                            <Card.Content>
                                <Card.Header>{I18n.get('Upload performances')}</Card.Header>
                                <Chart
                                    yFormat={d => `${d} ${I18n.get('ms/B')}`}
                                    legends={[I18n.get('Performances')]}
                                    data={this.state.recordProcessorPerformancesLines}
                                    crosshairTitleFormat={d => [{ title: 'Date', value: d.x}]}
                                    crosshairItemsFormat={d => [{ title: I18n.get('Performances'), value: d.y0.toFixed(3)+" "+I18n.get('ms/B')}]}
                                />
                            </Card.Content>
                        </Card>
                        
                        <Card style={{ width: 'unset', height: 'unset' }}>
                            <Card.Content>
                                <Card.Header>{I18n.get('Browsing API latency')}</Card.Header>
                                <Chart
                                    yFormat={d => `${d} ${I18n.get('ms')}`}
                                    legends={[I18n.get('Latency')]}
                                    data={this.state.appsyncLatencyLines}
                                    crosshairTitleFormat={d => [{ title: 'Date', value: d.x}]}
                                    crosshairItemsFormat={d => [{ title: I18n.get('Latency'), value: d.y0.toFixed(2)+" ms" }]}
                                />
                            </Card.Content>
                        </Card>
                    </>
                ) || (
                    <Segment style={{ height: "290px", marginTop: "10px", marginBottom: 0, display: "flex", alignItems: "center" }}>
                        <Dimmer active inverted>
                            <Loader inverted content={I18n.get('Loading performance metrics')} />
                        </Dimmer>
                        <Image fluid src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                    </Segment>
                )}

            </Segment>
        );
    }
}

export default PerformancesDashboard;