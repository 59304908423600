import { Auth } from 'aws-amplify';
import aws_exports from '../aws-exports';
import { S3 } from '@aws-sdk/client-s3';

export class S3Util {

    constructor() {
        this.s3 = undefined
    }

    STORAGECLASS = "GLACIER" 

    async initialize() {
        let credentials = await this.getCredentials();
        this.s3 = new S3({ credentials: Auth.essentialCredentials(credentials), region: aws_exports.aws_user_files_s3_bucket_region })
    }

    static async create() {
        const s3 = new S3Util();
        await s3.initialize();
        return s3;
    }

    async getCredentials() {
        return Auth.currentCredentials()
    }

    async getLifecyclePolicies(bucketName){
        try {
            let lifecyclePolicy = await this.s3.getBucketLifecycleConfiguration({Bucket: bucketName})
            return lifecyclePolicy.Rules
        }
        catch (e) {
            return []
        }
    }

    mergeLifecyclePolicyRules(bucketLifecyclePolicyRules, rulesToBeAdded, clientId){
        return bucketLifecyclePolicyRules.filter( x => !x.ID.startsWith(`RetentionPolicy-${clientId}`) ).concat(rulesToBeAdded)
    }

    async createOrUpdateLifecyclePolicy(bucketName, clientId, retentionArray) { // retentionArray parameter is an array of objects {retentionType , days}
        const bucketLifecyclePolicyRules = await this.getLifecyclePolicies(bucketName)
        const rulesToBeAdded = retentionArray.map(retention => {
            return {
                ID: `RetentionPolicy-${clientId}-${retention.type}`,
                Status: "Enabled",
                Filter: {
                    And: {
                        Prefix: "storage/",
                        Tags: [
                            {
                                Key: 'clientId',
                                Value: clientId
                            },
                            {
                                Key: 'type',
                                Value: retention.type
                            },
                        ]
                    }
                },
                Transitions: [
                    {
                        Days: retention.retention,
                        StorageClass: this.STORAGECLASS
                    }
                ]
            }
        })

        let mergedRules = this.mergeLifecyclePolicyRules(bucketLifecyclePolicyRules, rulesToBeAdded, clientId)
        if(mergedRules.length > 0){
            var params = {
                Bucket: bucketName,
                LifecycleConfiguration: {
                    Rules: mergedRules
                }
            };
            return this.s3.putBucketLifecycleConfiguration(params)
        }
        else 
            return
    }
}

