import React, { Component } from 'react';
import { Header, Icon, Button } from 'semantic-ui-react';
import { I18n } from 'aws-amplify';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { NavLink } from 'react-router-dom';
import { getSubfolders } from '../../../util/folder';
import { arraysEqual } from '../../../util/constant';
import CreateFolderModal from './createFolderModal';
import RenameFolderModal from './renameFolderModal';
import DeleteFolderModal from './deleteFolderModal';

const initialState = {
    activeFolder: null,
    addFolderModalOpen: false,
    renameFolderModalOpen: false,
    deleteFolderModalOpen: false,
    folderTree: {},
}

/**
 * FolderList component props:
 * 
 *     client: {Client object},
 *     folders: ["folders"],
 *     root: "rootFolder",
 *     editMode: boolean,
 *     createFolder: function,    // Only used when editMode = true
 *     renameFolder: function,    // Only used when editMode = true
 *     deleteFolder: function,    // Only used when editMode = true
 */
class FolderList extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.createFolderTree();
    }

    componentDidUpdate(prevProps) {
        if (!arraysEqual(prevProps.folders, this.props.folders) || prevProps.root !== this.props.root)
            this.createFolderTree();
    }

    createFolderTree() {
        let folderTree = {};
        this.props.folders.forEach(path => {
            let subPath = this.props.root
                ? path.replace(new RegExp(`${this.props.root}/`), '')
                : path;
            let tree = folderTree;
            subPath.split('/').forEach(folder => {
                if (!tree[folder])
                    tree[folder] = {};
                tree = tree[folder];
            });
        });
        this.setState({ folderTree });
    }

    activeFolderLocked = () => this.state.addFolderModalOpen || this.state.renameFolderModalOpen || this.state.deleteFolderModalOpen;

    renderTree = (folderTree, root) => {
        const folders = Object.keys(folderTree);
        if (folders.length === 0)
            return null;

        return folders.map(folder => {
            const path = root ? `${root}/${folder}` : folder;
            return (
                <TreeItem
                    key={path}
                    nodeId={path}
                    label={
                        <div
                            className="treeItem"
                            onMouseEnter={() => {
                                if (!this.activeFolderLocked())
                                    this.setState({ activeFolder: path })
                            }}
                        >
                            <Icon name="folder" />
                            <NavLink to={`/client/${this.props.client.id}/folder/${encodeURIComponent(path)}`}>
                                {folder}
                            </NavLink>
                            {this.props.editMode && this.state.activeFolder === path &&
                                <Button.Group floated='right' size='tiny'>
                                    {this.props.createFolder && <>
                                        <Button
                                            primary
                                            animated
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ addFolderModalOpen: true });
                                            }}
                                        >
                                            <Button.Content visible>
                                                <Icon name='folder' />
                                            </Button.Content>
                                            <Button.Content hidden>{I18n.get('Add')}</Button.Content>
                                        </Button>
                                        <CreateFolderModal
                                            open={this.state.addFolderModalOpen && this.state.activeFolder === path}
                                            client={this.props.client}
                                            rootFolder={path}
                                            subFolders={getSubfolders(path, this.props.folders).map(f => f.replace(new RegExp(`${path}/`), ''))}
                                            createFolder={this.props.createFolder}
                                            onClose={() => this.setState({ addFolderModalOpen: false, activeFolder: null })}
                                        />
                                    </>}

                                    {this.props.renameFolder && <>
                                        <Button
                                            color='teal'
                                            animated
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ renameFolderModalOpen: true });
                                            }}
                                        >
                                            <Button.Content visible>
                                                <Icon name='edit' />
                                            </Button.Content>
                                            <Button.Content hidden>{I18n.get('Rename')}</Button.Content>
                                        </Button>
                                        <RenameFolderModal
                                            open={this.state.renameFolderModalOpen && this.state.activeFolder === path}
                                            client={this.props.client}
                                            folder={path}
                                            subFolders={getSubfolders(root, this.props.folders).map(f => f.replace(new RegExp(`${root}/`), ''))}
                                            renameFolder={this.props.renameFolder}
                                            onClose={() => this.setState({ renameFolderModalOpen: false, activeFolder: null })}
                                        />
                                    </>}

                                    {this.props.deleteFolder && <>
                                        <Button
                                            color='red'
                                            animated
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ deleteFolderModalOpen: true });
                                            }}
                                        >
                                            <Button.Content visible>
                                                <Icon name='trash' />
                                            </Button.Content>
                                            <Button.Content hidden>{I18n.get('Delete')}</Button.Content>
                                        </Button>
                                        <DeleteFolderModal
                                            open={this.state.deleteFolderModalOpen && this.state.activeFolder === path}
                                            client={this.props.client}
                                            folder={path}
                                            deleteFolder={this.props.deleteFolder}
                                            onClose={() => this.setState({ deleteFolderModalOpen: false, activeFolder: null })}
                                        />
                                    </>}
                                </Button.Group>
                            }
                        </div>
                    }
                >
                    {this.renderTree(folderTree[folder], path)}
                </TreeItem>
            )
        });
    }

    render() {
        return (
            <>
                <Header>
                    {I18n.get('Folders List')}
                    {this.props.editMode && this.props.createFolder && <>
                        <Button
                            primary
                            floated='right'
                            className='headerButton'
                            content={I18n.get("Add folder to root")}
                            onClick={e => {
                                e.preventDefault();
                                this.setState({ addFolderModalOpen: true });
                            }}
                        />
                        <CreateFolderModal
                            open={this.state.addFolderModalOpen && this.state.activeFolder === null}
                            client={this.props.client}
                            rootFolder={null}
                            subFolders={this.props.folders}
                            createFolder={this.props.createFolder}
                            onClose={() => this.setState({ addFolderModalOpen: false, activeFolder: null })}
                        />
                    </>}
                </Header>

                <div className='folderTreeContainer'>
                    <TreeView
                        defaultCollapseIcon={<Icon name="chevron down" />}
                        defaultExpanded={['root']}
                        defaultExpandIcon={<Icon name="chevron right" />}
                        onMouseLeave={() => {
                            if (!this.activeFolderLocked())
                                this.setState({ activeFolder: null })
                        }}
                    >
                        {this.renderTree(this.state.folderTree, this.props.root || '')}
                    </TreeView>
                </div>
            </>
        );
    }
}

export default FolderList;