import { graphqlOperation, I18n } from 'aws-amplify';
import API from '@aws-amplify/api'
import React, { Component } from 'react';
import { Modal, Button, Card, Message, Icon, Form } from 'semantic-ui-react';
import { createTransfertRequest } from '../../util/query';
import { config } from '../../config';

const initialState = {
    mode: undefined,
    loading: false,
    showRestoreDaysWarning: false,
    restoreDaysInput: 1,
}

/**
 * TransferRequestModal component props:
 * 
 *     open: boolean,
 *     client: {Client object},
 *     folder: "folderName",
 *     fullFolder: boolean,         // true when requesting an entire folder, takes priority over the files
 *     files: [{file Objects}],     // ignored when fullFolder is true
 *     onClose: function,
 */
class TransferRequestModal extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open)
            this.setState({ ...initialState });
    }

    maxRestoreDuration = () => {
        if (this.state.mode !== 'restore')
            return undefined;

        const { client, files, fullFolder } = this.props;

        const types = fullFolder ? undefined : [...new Set(files.map(f => f.recordType))];
        const retentions = fullFolder
            ? client.myRetentionList.map(ret => ret.retention)
            : client.myRetentionList.filter(ret => types.includes(ret.type)).map(ret => ret.retention);
        if (retentions.length === 0)
            return undefined;

        // The maximum duration that can be asked is the minimum of the ones configured for the types contained in selection
        return Math.min(...retentions);
    }

    validateRestoreInput = restoreDays => {
        if (!restoreDays || isNaN(restoreDays) || restoreDays < 1)
            return false;
        const maxRestoreDuration = this.maxRestoreDuration();
        return !maxRestoreDuration || restoreDays <= maxRestoreDuration;
    }

    requestTransfer = () => {
        const { mode, restoreDaysInput } = this.state;

        if (mode === 'restore' && !this.validateRestoreInput(restoreDaysInput)) {
            this.setState({ showRestoreDaysWarning: true, restoreDaysInput: initialState.restoreDaysInput });
            return;
        }

        const { client, fullFolder } = this.props;
        const audios = this.props.files.map(f => f.id); // to be removed later
        const files = this.props.files;
        const folder = this.props.folder ?? '/';


        /*var today = new Date()
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear()+1;
        today = mm + '/' + dd + '/' + yyyy;*/

        let glacierFiles = (!fullFolder && mode === 'restore') ? files : undefined;
        this.setState({ loading: true });
        API.graphql(graphqlOperation(createTransfertRequest(fullFolder, client.id, folder, audios, files, mode, restoreDaysInput, glacierFiles)))
            .then(this.props.onClose);
    }

    render() {
        const maxRestore = this.maxRestoreDuration();

        return (
            <Modal
                as={Card}
                style={{ width: 'unset', height: 'unset' }}
                dimmer='blurring'
                closeOnDimmerClick={!this.state.loading}
                closeIcon={!this.state.loading}
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <Card.Content>
                    <Card.Header><Icon name='share' />{I18n.get("Select transfert mode")}</Card.Header>
                    {this.state.showRestoreDaysWarning &&
                        <Message negative>
                            <Message.Header>{I18n.get('Please enter a valid number !')}</Message.Header>
                        </Message>
                    }

                    <p>{I18n.get('Select any of the transfert mode:')}</p>
                    <Form >
                        <Form.Group grouped>
                            {config.FUNC_TRANSFERT_MODES.SFTP_MODE && <Form.Radio
                                label={I18n.get('Sftp')}
                                name='radioGroup'
                                value={this.state.mode}
                                checked={this.state.mode === 'sftp'}
                                onChange={() => this.setState({ mode: 'sftp' })}
                            />}
                            {config.FUNC_TRANSFERT_MODES.URL_MODE && <Form.Radio
                                label={I18n.get('Url')}
                                name='radioGroup'
                                value={this.state.mode}
                                checked={this.state.mode === 'url'}
                                onChange={() => this.setState({ mode: 'url' })}
                            />}
                            {config.FUNC_TRANSFERT_MODES.RESTORE_MODE &&
                                <Form.Group grouped>
                                    <Form.Radio
                                        label={I18n.get('Restore')}
                                        name='radioGroup'
                                        value={this.state.mode}
                                        checked={this.state.mode === 'restore'}
                                        onChange={() => this.setState({ mode: 'restore' })}

                                    />
                                    {(this.state.mode === 'restore') && <Form.Input
                                        label={`${I18n.get('Number of days that the restored files will be available')}${(maxRestore && ` (max ${maxRestore})`) || ''}`}
                                        type='number'
                                        size='mini'
                                        name='restoreDaysInput'
                                        value={this.state.restoreDaysInput}
                                        min={1}
                                        max={maxRestore}
                                        onChange={(_e, { value }) => this.setState({ restoreDaysInput: value })}
                                    />}
                                </Form.Group>}
                        </Form.Group>
                    </Form>
                </Card.Content>
                <Card.Content extra textAlign='center'>
                    <Button
                        disabled={!this.state.mode || (this.state.mode === 'restore' && !this.validateRestoreInput(this.state.restoreDaysInput)) || this.state.loading}
                        loading={this.state.loading}
                        icon='checkmark'
                        content={I18n.get(this.props.fullFolder ? 'Request Full Folder' : 'Request Selected Files')}
                        onClick={this.requestTransfer}
                        labelPosition='left' size='small' color='green'
                    />
                </Card.Content>
            </Modal>
        );
    }
}

export default TransferRequestModal;