import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import Auth from '@aws-amplify/auth'
import Storage from '@aws-amplify/storage'
import { v4 as uuid } from 'uuid';
import { storageType, storageMapping } from '../../util/constant';
import { Form, Progress, Button, Modal, Grid, Message } from 'semantic-ui-react';
import Select from 'react-select';

const defaultType = storageType[0];

const initialState = {
    uploading: false,
    files: [],
    typeaccept: `${defaultType.value}`,
    type: `${defaultType.text}`,
    error: false,
    showSuccess: false,
    showCancelButton: false,
}

class S3FileUploadModal extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    onModalOpen = async () => {
        this.setState(initialState);
    }

    handleStateProgress = (index, loaded, error = false) => {
        let files = [...this.state.files];
        if (!files[index])
            return;
        files[index].loaded = loaded;
        files[index].error = error;
        if (loaded === files[index].file.size && !error)
            files[index].done = true;
        this.setState({ files });
    }

    uploadFile = (fileData, index, fileUuid) => new Promise((resolve, reject) => {
        if (!fileData.file)
            return reject('Invalid file');

        Auth.currentAuthenticatedUser().then(user => {
            const fileName = fileUuid ? fileUuid : uuid();
            const recordType = this.state.type
            const uploadName = fileData.file.name;
            const folder = this.props.folderName ? this.props.folderName : '/';

            const uploadTask = Storage.put(
                fileName,
                fileData.file,
                {
                    contentType: fileData.type,
                    customPrefix: { public: 'uploads/' },
                    metadata: {
                        clientid: this.props.clientId,
                        owner: encodeURIComponent(user.username),
                        recordtype: recordType,
                        mappingType: storageMapping[recordType],
                        originalIdUploadFile: encodeURIComponent(uploadName),
                        folder: encodeURIComponent(folder)
                    },
                    progressCallback: progress => this.handleStateProgress(index, progress.loaded)
                }
            );

            // Save promise to allow cancelling
            let files = [...this.state.files];
            files[index].uploadTask = uploadTask;
            this.setState({ files });

            uploadTask.then(_result => {
                this.handleStateProgress(index, fileData.file.size);
                if (this.refreshTimer)
                    window.clearTimeout(this.refreshTimer);
                this.refreshTimer = setTimeout(this.props.reloadFileList, 5000);
                resolve(index);
            }).catch(err => {
                if (Storage.isCancelError(err))
                    return reject(err);
                this.handleStateProgress(index, fileData.loaded, true);
                reject(err.message === 'Network Error' ? err.message : err)
            });
        }).catch(error => reject(error));
    });

    startUpload = () => {
        Promise.all(this.state.files.map((file, index) =>
            this.uploadFile(file, index).catch(err => { throw err; })
        )).then(() =>
            this.setState({ uploading: false, showCancelButton: false, showSuccess: true })
        ).catch(err => {
            if (!Storage.isCancelError(err)) { // On cancel, state has already been reset
                this.setState({
                    uploading: true,
                    showCancelButton: true,
                    error: err === 'Network Error'
                        ? I18n.get('Upload failed due to a network error!')
                        : I18n.get('Upload failed!')
                });
            }
        });
    }

    handleFiles = async e => {
        this.setState({
            files: Array.from(e.target.files).map(f => {
                return {
                    file: f,
                    loaded: 0,
                    error: false,
                    uploadTask: null,
                    done: false,
                };
            }),
            uploading: true,
            showCancelButton: true
        }, () => this.startUpload());
    }

    handleChange = async e => {
        this.setState({ typeaccept: `${e.value}`, type: `${e.text}` });
    }

    cancelUploads = () => {
        this.state.files.forEach(fileData => {
            if (fileData.uploadTask)
                Storage.cancel(fileData.uploadTask);
        });
        this.setState(initialState);
    }

    retryUploads = () => {
        let files = this.state.files.filter(f => !f.done); // Only retry failed files
        files.forEach(f => {
            f.loaded = 0;
            f.error = false;
            f.uploadTask = null;
        });
        this.setState({ files, error: false }, () => this.startUpload());
    }

    render() {
        return (
            <Modal
                size='tiny'
                dimmer='blurring'
                trigger={<Button primary size="small" onClick={this.onModalOpen} floated="right">{I18n.get('Upload')}</Button>}
                closeIcon={!this.state.uploading}
                closeOnDimmerClick={!this.state.uploading}
            >
                <Modal.Header>{I18n.get('Upload new files')}</Modal.Header>
                <Modal.Content>
                    {this.state.error &&
                        <Message negative>
                            <Message.Header>{this.state.error}</Message.Header>
                        </Message>
                    }
                    {this.state.showSuccess &&
                        <Message positive>
                            <Message.Header>{I18n.get('Upload successful')}</Message.Header>
                        </Message>
                    }

                    {!this.state.uploading &&
                        <Grid className="centered" padded>
                            <Form>
                                <Form.Group>

                                    <div className="field">
                                        <span>{I18n.get('My Record Type')} : </span>
                                        <Select
                                            options={storageType}
                                            defaultValue={defaultType}
                                            getOptionLabel={(type) => type.text}
                                            getOptionValue={(type) => type.key}
                                            id="myUploadRecordType"
                                            className="ui fluid dropdown"
                                            required={true}
                                            name="recordType"
                                            onChange={this.handleChange}
                                        />
                                    </div>

                                    <div className="field">
                                        <label htmlFor="">&nbsp;</label>
                                        <Form.Button
                                            primary
                                            onClick={() => document.getElementById('add-audio-file-input').click()}
                                            icon='file audio outline'
                                            content={I18n.get(`Add ${this.state.type}`)}
                                        />
                                    </div>
                                </Form.Group>
                                <input
                                    id='add-audio-file-input'
                                    type="file" multiple
                                    accept={`${this.state.typeaccept}/*`}
                                    onChange={this.handleFiles}
                                    style={{ display: 'none' }}
                                />
                            </Form>

                        </Grid>
                    }
                    {
                        this.state.files.map((fileData, index) => (
                            <Progress
                                key={index}
                                label={fileData.file.name}
                                value={fileData.loaded}
                                total={fileData.file.size}
                                active={!fileData.done && !fileData.error}
                                color={fileData.error ? 'red' : 'green'}
                                progress="percent"
                                precision={1}
                            />
                        ))
                    }
                </Modal.Content>
                <Modal.Actions>
                    {this.state.error && <Button
                        primary
                        compact
                        onClick={this.retryUploads}
                        content={I18n.get('Retry')}
                    />}
                    {this.state.showCancelButton && <Button
                        color='red'
                        compact
                        onClick={this.cancelUploads}
                        content={I18n.get('Cancel')}
                    />}
                </Modal.Actions>
            </Modal>

        );
    }
}

export default S3FileUploadModal;