
const DEFAULT_CONFIG = {
    FUNC_TRANSFERT: true,
    USER_MANAGEMENT: true,
    DASHBOARD: true,
    FUNC_TRANSFERT_MODES : {
            URL_MODE :  true,
            SFTP_MODE : true,
            RESTORE_MODE: true,
        },
    STORAGE_TYPES : { 'audio': 'audio', 'video': 'video', 'image': 'image', 'application': 'video'},
    MEDIA_PREVIEW : true,
};


export const config = {
    PROJECTNAME: process.env.REACT_APP_PROJECTNAME,
    FUNC_TRANSFERT: process.env.REACT_APP_FUNC_TRANSFERT ? JSON.parse(process.env.REACT_APP_FUNC_TRANSFERT) : DEFAULT_CONFIG.FUNC_TRANSFERT,
    USER_MANAGEMENT: process.env.REACT_APP_USER_MANAGEMENT ?  JSON.parse(process.env.REACT_APP_USER_MANAGEMENT) : DEFAULT_CONFIG.USER_MANAGEMENT,
    DASHBOARD: process.env.REACT_APP_DASHBOARD ? JSON.parse(process.env.REACT_APP_DASHBOARD) : DEFAULT_CONFIG.DASHBOARD,
    FUNC_TRANSFERT_MODES : {
        URL_MODE : process.env.REACT_APP_FUNC_TRANSFERT_MODES_URL ? JSON.parse(process.env.REACT_APP_FUNC_TRANSFERT_MODES_URL) : DEFAULT_CONFIG.FUNC_TRANSFERT_MODES.URL_MODE,
        SFTP_MODE : process.env.REACT_APP_FUNC_TRANSFERT_MODES_SFTP ?  JSON.parse(process.env.REACT_APP_FUNC_TRANSFERT_MODES_SFTP) : DEFAULT_CONFIG.FUNC_TRANSFERT_MODES.SFTP_MODE,
        RESTORE_MODE : process.env.REACT_APP_FUNC_TRANSFERT_MODES_RESTORE ?  JSON.parse(process.env.REACT_APP_FUNC_TRANSFERT_MODES_RESTORE) : DEFAULT_CONFIG.FUNC_TRANSFERT_MODES.RESTORE_MODE,
        
    },
    STORAGE_TYPES : process.env.REACT_APP_STORAGE_TYPES ? JSON.parse(process.env.REACT_APP_STORAGE_TYPES) : DEFAULT_CONFIG.STORAGE_TYPES,
    MEDIA_PREVIEW: process.env.REACT_APP_MEDIA_PREVIEW ? JSON.parse(process.env.REACT_APP_MEDIA_PREVIEW) : DEFAULT_CONFIG.MEDIA_PREVIEW,


};