import '../App.css'
import '@aws-amplify/ui-react/styles.css';
import '../amplify_style.css';
import { Authenticator, View, Text, Heading, useAuthenticator, Button, useTheme, Image, Flex } from '@aws-amplify/ui-react';
import CGUModal from "./shared/beforeSignin/cgumodal";
import CookiesModal from "./shared/beforeSignin/cookiesModal";
import React, { useEffect, useState } from 'react';
import 'react-image-lightbox/style.css'; 
import Routing from './routing';
import {Auth} from '@aws-amplify/auth'
import { dictionary } from '../util/dictionary';
import { I18n } from 'aws-amplify';
import logogif from '../images/Logo.gif'
I18n.putVocabularies(dictionary);

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: I18n.get("Enter your username"),
      isRequired: true,
      label: I18n.get("Username")+" *"
    },
    password: {
      labelHidden: false,
      placeholder: I18n.get("Enter your password"),
      isRequired: true,
      label: I18n.get("Password")+" *"
    }
  },
  signUp: {
    username: {
      labelHidden: false,
      placeholder: I18n.get("Enter your username"),
      isRequired: true,
      label: I18n.get("Username")+" *",
      order: 1
    },
    email: {
      labelHidden: false,
      placeholder: I18n.get("Enter your email"),
      isRequired: true,
      label: I18n.get("Email")+" *",
      order: 2
    },
    password: {
      labelHidden: false,
      placeholder: I18n.get("Enter your password"),
      isRequired: true,
      label: I18n.get("Password")+" *",
      order: 3
    },
    confirm_password: {
      labelHidden: false,
      placeholder: I18n.get("Enter your password"),
      isRequired: true,
      label: I18n.get("Confirm password")+" *",
      order: 4
    }
  },
  resetPassword: {
    username: {
      labelHidden: false,
      placeholder: I18n.get("Enter your username"),
      isRequired: true,
      label: I18n.get("Username")+" *"
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: I18n.get("Enter your confirmation code"),
      label: 'Code *',
      isRequired: true,
    },
    password: {
      labelHidden: false,
      placeholder: I18n.get("Enter your password"),
      isRequired: true,
      label: I18n.get("Password")+" *"
    },
    confirm_password: {
      labelHidden: false,
      placeholder: I18n.get("Enter your password"),
      isRequired: true,
      label: I18n.get("Confirm password")+" *"
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: 'test issuer',
      totpUsername: 'amplify_qr_test_user',
    },
    confirmation_code: {
      labelHidden: false,
      label: I18n.get("Code") +" *",
      placeholder: I18n.get("Enter your confirmation code"),
      isRequired: true,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: I18n.get("Code") +" *",
      placeholder: I18n.get("Enter your confirmation code"),
      isRequired: true,
    },
  },
};

export default function App() {

  const [cguModalOpen, setGcuModalOpen] = useState(false)
  const [cookiesModalOpen, setCookiesModalOpen] = useState(false)
  const components = {

    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image alt="Logo" src={logogif} style={{maxWidth: "80%"}}/>
        </View>
      );
    },
  
    Footer() {
      const { tokens } = useTheme();
      return <View padding={tokens.space.large}></View>
    },
  
  
    SignIn: {
      Header() {
        return (
          <Heading level={4} style={{textAlign: "center", paddingTop: 28}}>
            {I18n.get("Sign in to your account")}
          </Heading>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();
  
        return (
          <Flex justifyContent="center" alignItems="center" direction="column" style={{fontSize: ".9em",paddingBottom: 28}}>
            <Flex justifyContent="center" alignItems="center">
              <Text>{I18n.get("Forgot Password") + " ?"}</Text>
              <Button fontWeight="normal" onClick={toResetPassword} size="small" variation="link" style={{paddingLeft: 0, paddingRight: 0}}>
                {I18n.get("Reset your password")}
              </Button>
            </Flex>
            <Flex>
              <Button fontWeight="normal" size="small" onClick={() => setGcuModalOpen(true)} variation="link" style={{paddingLeft: 0, paddingRight: 0}}>
                {I18n.get("Terms of Use")}
              </Button>
            </Flex>
          </Flex>
        );
      },
    },
  
    SignUp: {
      Header() {
        return (
          <Heading level={4} style={{textAlign: "center", paddingTop: 28}}>
            {I18n.get("Create account")}
          </Heading>
        );
      },
      Footer() {
        return (
          <Flex justifyContent="center" alignItems="center" direction="column" style={{fontSize: ".9em",paddingBottom: 28}}>
            <Flex>
              <Button fontWeight="normal"  size="small" onClick={() => setGcuModalOpen(true)} variation="link" style={{paddingLeft: 0, paddingRight: 0}}>
                {I18n.get("Terms of Use")}
              </Button>
            </Flex>
          </Flex>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        return (
          <Heading level={4} style={{textAlign: "center"}}>
            {I18n.get("Confirm Signup")}
          </Heading>
        );
      },
      Footer() {
        return (
          <Flex justifyContent="center" alignItems="center" direction="column" style={{fontSize: ".9em"}}>
            <Flex>
              <Button fontWeight="normal"  size="small" onClick={() => setGcuModalOpen(true)} variation="link" style={{paddingLeft: 0, paddingRight: 0}}>
                {I18n.get("Terms of Use")}
              </Button>
            </Flex>
          </Flex>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return (
          <Flex justifyContent="center" alignItems="center" direction="column" style={{fontSize: ".9em",paddingBottom: 28}}>
            <Flex>
              <Button fontWeight="normal"  size="small" onClick={() => setGcuModalOpen(true)} variation="link" style={{paddingLeft: 0, paddingRight: 0}}>
                {I18n.get("Terms of Use")}
              </Button>
            </Flex>
          </Flex>
        );
      },
    },
    ResetPassword: {
      Header() {
        return (
          <Heading level={4} style={{textAlign: "center"}}>
            {I18n.get("Reset your password")}
          </Heading>
        );
      },
      Footer() {
        return (
          <Flex justifyContent="center" alignItems="center" direction="column" style={{fontSize: ".9em",paddingBottom: 28}}>
            <Flex>
              <Button fontWeight="normal"  size="small" onClick={() => setGcuModalOpen(true)} variation="link" style={{paddingLeft: 0, paddingRight: 0}}>
                {I18n.get("Terms of Use")}
              </Button>
            </Flex>
          </Flex>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        return (
          <Heading level={4} style={{textAlign: "center"}}>
            {I18n.get("Reset your password")}
          </Heading>
        );
      },
      Footer() {
        return (
          <Flex justifyContent="center" alignItems="center" direction="column" style={{fontSize: ".9em",paddingBottom: 28}}>
            <Flex>
              <Button fontWeight="normal"  size="small" onClick={() => setGcuModalOpen(true)} variation="link" style={{paddingLeft: 0, paddingRight: 0}}>
                {I18n.get("Terms of Use")}
              </Button>
            </Flex>
          </Flex>
        );
      },
    },
  };

  useEffect(() => {

    if (localStorage.getItem('acceptTermsOfUse')) {
      setCookiesModalOpen(false)
    } else {
      setCookiesModalOpen(true)
    }

  },[])

  const acceptTermsOfUse = () => {
    localStorage.setItem("acceptTermsOfUse",true)
    setCookiesModalOpen(false)
  }

  const services =  {
    async handleSignIn (formD) {
      let {username, password}= formD //validated tomas 12/2023
      if (localStorage.getItem("acceptTermsOfUse")) {
        return Auth.signIn({username, password})//validated tomas 12/2023
      } else {
        throw new Error("Please accept the terms and conditions of use.")
      }
    },
    async handleSignUp (formD) {
      let {username, password, attributes}= formD //validated tomas 12/2023
      if (localStorage.getItem("acceptTermsOfUse")) {
        if (attributes.email.length !== 0) {
          return Auth.signUp({username, password, attributes})//validated tomas 12/2023
        } else {
          throw new Error("Email cannot be empty")
        }
      } else {
        throw new Error("Please accept the terms and conditions of use.")
      }
    },
    async handleForgotPassword (formD) {
      let username= formD //modification tomas 12/2023
      if (localStorage.getItem("acceptTermsOfUse")) {
        return Auth.forgotPassword(username)//modification tomas 12/2023
      } else {
        throw new Error("Please accept the terms and conditions of use.")
      }
    },
    async handleConfirmSignUp (formD) {
      let {username, code}= formD // Validated tomas 12/2023
      if (localStorage.getItem("acceptTermsOfUse")) {
        return Auth.confirmSignUp(username, code)//modification tomas 12/2023
      } else {
        throw new Error("Please accept the terms and conditions of use.")
      }
    },
    async handleForgotPasswordSubmit (formD) {
      let {username, code, password}= formD 
      if (localStorage.getItem("acceptTermsOfUse")) {
        return Auth.forgotPasswordSubmit(username, code, password)
      } else {
        throw new Error("Please accept the terms and conditions of use.")
      }
    }
  }
  return (
    <div>
      <CookiesModal open={cookiesModalOpen} acceptTermsOfUse={() => acceptTermsOfUse()} onClose={() => setCookiesModalOpen(false)}/>
      <CGUModal open={cguModalOpen} onClose={() => setGcuModalOpen(false)}/>
      <Authenticator formFields={formFields} components={components} services={services}  className="auth" signUpAttributes={['email']}>
        <div><Routing /></div>
      </Authenticator>
    </div>
  );
}
