import { I18n } from 'aws-amplify';
import React, { Component } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Dropdown, Grid, Form } from 'semantic-ui-react';

import 'react-datepicker/dist/react-datepicker.css';

/**
 * SearchFile component props:
 * 
 *     folder: "Folder name",
 *     handleSearchResults: function,
 */
class SearchFile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileName: '',
            folder: '',
            tags: '',
            creationDateFrom: null,
            creationDateTo: null,
            searchType: '',
        };
    }

    handleChange = (_e, { name, value }) => {
        this.setState({ [name]: value || '' });
    }

    handleSubmit = () => {
        this.props.handleSearchResults(this.state);
    }

    render() {
        return (
            <div className='dropdownContainer'>
                <Dropdown
                    simple button fluid basic
                    closeOnChange={false}
                    className='pointing'
                    text={I18n.get('Search') + '...'}
                >
                    <Dropdown.Menu direction='left'>
                        <Grid padded relaxed className='dropdownFormGrid'>
                            <Form className='dropdownForm'>

                                <Grid.Row as={Dropdown.Item}>
                                    <Form.Input
                                        label={I18n.get('Filename')}
                                        placeholder={I18n.get('Filename')}
                                        value={this.state.fileName}
                                        name="fileName"
                                        onChange={this.handleChange}
                                    />
                                </Grid.Row>

                                <Grid.Row as={Dropdown.Item}>
                                    <Form.Input
                                        label={I18n.get('Folder')}
                                        placeholder={I18n.get('Folder')}
                                        value={this.state.folder}
                                        name="folder"
                                        onChange={this.handleChange}
                                    />
                                </Grid.Row>

                                <Grid.Row as={Dropdown.Item}>
                                    <Form.Input
                                        label={I18n.get('Tag name')}
                                        placeholder={I18n.get('Tag name')}
                                        value={this.state.tags}
                                        name="tags"
                                        onChange={this.handleChange}
                                    />
                                </Grid.Row>

                                <Grid.Row as={Dropdown.Item}>
                                    <Form.Field>
                                        <label>{I18n.get('Creation date from')}</label>
                                        <ReactDatePicker
                                            placeholderText={I18n.get('Creation date from')}
                                            className="ui fluid input"
                                            selected={this.state.creationDateFrom}
                                            dateFormat="yyyy-MM-dd"
                                            onChange={date => this.setState({ creationDateFrom: date })}
                                        />
                                    </Form.Field>
                                </Grid.Row>

                                <Grid.Row as={Dropdown.Item}>
                                    <Form.Field>
                                        <label>{I18n.get('Creation date to')}</label>
                                        <ReactDatePicker
                                            placeholderText={I18n.get('Creation date to')}
                                            className="ui fluid input"
                                            selected={this.state.creationDateTo}
                                            dateFormat="yyyy-MM-dd"
                                            onChange={date => this.setState({ creationDateTo: date })}
                                        />
                                    </Form.Field>
                                </Grid.Row>

                                <Grid.Row as={Dropdown.Item}>
                                    <Form.Input
                                        label={I18n.get('Type')}
                                        placeholder={I18n.get('Type')}
                                        value={this.state.searchType}
                                        name="searchType"
                                        onChange={this.handleChange}
                                    />
                                </Grid.Row>

                                <Grid.Row as={Dropdown.Item}>
                                    <Form.Button
                                        type="submit"
                                        fluid primary
                                        onClick={this.handleSubmit}
                                        icon='search'
                                        content={I18n.get('Search')}
                                    />
                                </Grid.Row>

                            </Form>
                        </Grid>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
}

export default SearchFile;