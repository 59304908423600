import React, {useEffect} from 'react';
import './App.css'
import 'react-image-lightbox/style.css'; 
import { dictionary } from './util/dictionary';
import { config } from './config';
import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import { I18n } from 'aws-amplify';
import awsconfig from "./aws-exports";
import CustomAuth from './components/customAuth' 
I18n.putVocabularies(dictionary);

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

export default function App() {

  useEffect(() => {

    const PROJECTNAME = config.PROJECTNAME
    if(PROJECTNAME) {
      document.title = PROJECTNAME + " Storage Portal"
    } else {
      document.title = "Storage Portal"
    }

  },[])


  return (
    <div>
      <CustomAuth />
    </div>
  );
}