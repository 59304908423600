import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import Auth from '@aws-amplify/auth'
import { Modal, Button, Card, Message, Icon } from 'semantic-ui-react';
import { deleteRecord } from '../../util/api';

const initialState = {
    loading: false,
    error: null,
    filesError: [],
}

/**
 * DeleteModal component props:
 *     
 *     open: boolean,
 *     client: {Client object},
 *     files: [{file Objects}],
 *     deleteFiles: function([{file Objects}]),
 *     onClose: function,
 */
class DeleteModal extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    onModalOpen = () => {
        this.setState({ ...initialState });
        this.props.files.forEach(file => delete file.error);
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && !prevProps.open)
            this.onModalOpen();
    }

    deleteFile = async (file, user) => {
        const { client } = this.props;
        const path = file.folder === '/' ? file.id : `${file.folder}/${file.id}`;
        await deleteRecord(path, user, client.id).catch(_err => {
            this.setState({
                error: I18n.get("Some files could not be deleted, try again later"),
                filesError: [...this.state.filesError, file]
            });
        });
    }

    deleteFiles = () => {
        this.setState({ loading: true });
        Auth.currentAuthenticatedUser().then(user => {
            Auth.currentCredentials().then(_credentials => {
                Promise.all(this.props.files.map(file => this.deleteFile(file, user))).then(() => {
                    this.setState({ loading: false });
                    this.props.deleteFiles(this.props.files.filter(file => !file.error));
                    if (!this.state.error)
                        this.props.onClose();
                });
            });
        })
    }

    render() {
        return (
            <Modal
                as={Card}
                style={{ width: 'unset', height: 'unset' }}
                dimmer='blurring'
                closeOnDimmerClick={!this.state.loading}
                closeIcon={!this.state.loading}
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <Card.Content>
                    <Card.Header><Icon name='trash' />{I18n.get('Confirm deletion')}</Card.Header>
                </Card.Content>
                <Card.Content>
                    {!this.state.error &&
                        <Message warning>
                            <Message.Header>
                                {I18n.get('Confirm deletion of these {count} files?').replace('{count}', this.props.files.length)}
                            </Message.Header>
                            <Message.List>
                                {this.props.files.map(file =>
                                    <Message.Item key={file.id}>{file.filename}</Message.Item>
                                )}
                            </Message.List>
                        </Message>
                    }

                    {this.state.error && !this.state.loading &&
                        <Message error>
                            <Message.Header>{this.state.error}</Message.Header>
                            <Message.List>
                                {this.state.filesError.map(file =>
                                    <Message.Item key={file.id}>{file.filename}</Message.Item>
                                )}
                            </Message.List>
                        </Message>
                    }
                </Card.Content>
                <Card.Content extra textAlign='center'>
                    <Button
                        color='red'
                        icon='delete'
                        labelPosition='left'
                        content={I18n.get('Confirm deletion')}
                        onClick={this.deleteFiles}
                        loading={this.state.loading}
                        disabled={this.state.error !== null || this.state.loading}
                    />
                </Card.Content>
            </Modal>
        );
    }
}

export default DeleteModal;