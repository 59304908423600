
import React, { Component } from 'react';
import { graphqlOperation, I18n } from 'aws-amplify';
import Auth from '@aws-amplify/auth'
import API from '@aws-amplify/api'
import { Navigate } from 'react-router-dom';
import { Button, Dimmer, Loader, Image, Form, Header, Input, Segment, Message, Grid, GridRow } from 'semantic-ui-react';
import { GetClientForUpdate, updateClientQuery } from '../util/query';
import { folderArray, folderMap, formatFolder, hierarchyToFoldersList } from '../util/folder';
import { storageType, removeEmptyRetentionList } from '../util/constant';
import FolderList from './shared/folderList';
import { S3Util } from '../util/s3';
import aws_exports from '../aws-exports';


class ArchiveClientDetailsLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client: undefined,
            clientName: '',
            myRetentionList: [{
                type: '',
                retention: 0,
                duration: ''
            }
            ],
            folders: [],
            showMyComponent: false,
            showWarning: false,
            showSuccess: false,
            disablebutton: new Map(),
            foldersTree: [],
            storageType: storageType
        };
    }

    async loadClient() {
        const { data } = await API.graphql(graphqlOperation(GetClientForUpdate, { id: this.props.id }));
        const client = data.getClient;
        if (client !== null) {
            this.setState({
                clientName: client.name
            });
            const retentionList = client.myRetentionList;
            const folderList = client.folderList;
            if (Array.isArray(retentionList) && retentionList.length) {
                this.setState({
                    myRetentionList: retentionList
                });
            }
            this.disableStorageTypeInputSelectedInRetentions(retentionList)
            this.setState({ showAddButton: retentionList.length < storageType.length });

            if (Array.isArray(folderList) && folderList.length) {
                folderList.sort();
                var myFolderList = folderList.map(function (o) { return o.folder; });

                this.setState({
                    folders: myFolderList,
                    foldersTree: formatFolder(undefined, myFolderList)
                });
            }
        }
        else {

        }
        this.setState({
            client: client
        });
    }

    updateFolderList = async (newFolderList) => {
        await folderMap(newFolderList).then(async data => {
            let params = JSON.stringify(await folderArray(data)).replace(/"(\w+)"\s*:/g, '$1:');
            await API.graphql(graphqlOperation(updateClientQuery(null, params, null), { id: this.props.id })).then(() => {
                this.setState({ folders: newFolderList });
                this.setState({foldersTree: formatFolder(undefined, newFolderList)})
            });
        });
    }

    updateClient = async (event) => {
        event.preventDefault();
        let myRetentionList = undefined
        let clientName = undefined
        if (!this.props.cm) {
            myRetentionList = removeEmptyRetentionList(this.state.myRetentionList);
            myRetentionList = JSON.stringify(myRetentionList);
            myRetentionList = myRetentionList.replace(/"(\w+)"\s*:/g, '$1:');
            clientName = this.state.clientName
        }
        let folders = hierarchyToFoldersList(this.state.foldersTree).map(x => ({ folder: x }));
        folders = JSON.stringify(folders);
        folders = folders.replace(/"(\w+)"\s*:/g, '$1:');
        try {
            await this.updateRetentions(this.state.myRetentionList, this.props.id)
            const result = await API.graphql(graphqlOperation(updateClientQuery(myRetentionList, folders, clientName), { id: this.props.id }));
            this.setState({ showSuccess: true, showWarning: false });
            this.setState({ createdClient: result.data.updateClient.name });
        } catch (err) {
            this.setState({ showWarning: true, showSuccess: false })
        }



    }

    updateRetentions = async (retentionList, clientId) => {
        let s3 = await S3Util.create()
        retentionList = retentionList.filter(x => x.retention || x.type)
        return s3.createOrUpdateLifecyclePolicy(aws_exports.aws_user_files_s3_bucket, clientId, retentionList)
    }


    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    showType = () => {
        this.setState({ showAddButton: this.state.showAddButton });
        let myRetentionList = [...this.state.myRetentionList];
        myRetentionList.push({ type: '', retention: 0 });
        this.setState({ myRetentionList });
        if (myRetentionList.length >= storageType.length)
            this.setState({ showAddButton: false });
    }

    handleSelectChange = (index, type, event, { value }) => {
        let myRetentionList = [...this.state.myRetentionList];
        let item = { ...myRetentionList[index] };
        if (type === 'type') {
            item.type = value;
            if (value === undefined)
                item.retention = 0
        }
        if (type === 'retention') {
            item.retention = parseInt(value);
        }
        if (type === 'duration') {
            item.duration = parseInt(value);
        }
        myRetentionList[index] = item;
        this.setState({ myRetentionList });
        this.disableStorageTypeInputSelectedInRetentions(myRetentionList)
    };

    disableStorageTypeInputSelectedInRetentions = (myRetentionList) => {
        let types = [...this.state.storageType]
        types.map(x => {
            if (myRetentionList.map(t => t.type).includes(x.key))
                x.disabled = true
            else
                x.disabled = false
            return x
        })
        this.setState({ storageType: types });
    }

    handleFolderChange = async (index, event, { value }) => {
        let folders = [...this.state.folders];
        let item = { ...folders[index] };
        item.folder = value;
        folders[index] = item;
        this.setState({ folders });
    };

    componentDidMount() {
        this.loadClient();
    }

    treeStateHelper(tree) {
        this.setState({ foldersTree: tree })
    }

    createFolder = async folder => {
        const newFolderList = [...this.state.folders, folder];
        await this.updateFolderList(newFolderList);
    }

    renameFolder = async (oldName, newName) => {
        const regex = new RegExp(`(${oldName})(/.*)?$`);
        const newFolderList = this.state.folders.map(f => f.match(regex)
            ? f.replace(regex, `${newName}$2`)
            : f
        );
        await this.updateFolderList(newFolderList);
    }

    deleteFolder = async folder => {
        // Delete folder
        await Auth.currentAuthenticatedUser().then(async user => {
            await Auth.currentCredentials().then(async _credentials => {
                const params = {
                    response: true,
                    body: {
                        accessToken: user.getSignInUserSession().getAccessToken().getJwtToken()
                    }
                }
                await API.del('AudioDownloadAPI', `/${this.props.id}/folder/${folder}`, params);
            });
        });

        // Update folder list
        const regex = new RegExp(`(${folder})(/.*)?$`);
        const foldersToDelete = this.state.folders.filter(f => f.match(regex));
        const newFolderList = this.state.folders.filter(f => !foldersToDelete.includes(f));
        await this.updateFolderList(newFolderList);
    }

    render() {
        if (this.state.client === undefined)
            return (
                <Segment>
                    <Dimmer active>
                        <Loader content={I18n.get('Loading Client Details')} />
                    </Dimmer>
                    <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                </Segment>
            );
        if (this.state.client !== null)
            return (
                <Segment>
                    {this.state.showSuccess &&
                        <Message positive>
                            <Message.Header>{I18n.get('Updated client success')}</Message.Header>
                        </Message>
                    }
                    {this.state.showWarning &&
                        <Message negative>
                            <Message.Header>{I18n.get('Issue with client informations!')}</Message.Header>
                        </Message>
                    }

                    <Header as='h3'>{I18n.get('Edit client')}</Header>
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field
                                control={Input}
                                required={true}
                                value={this.state.clientName}
                                onChange={this.handleChange}
                                label={I18n.get('Client name')}
                                name='clientName'
                                placeholder={I18n.get('Client name')}
                                disabled={this.props.cm}
                            />
                        </Form.Group>

                        {this.state.myRetentionList.map((item, index) => (
                            <Form.Group key={index}>
                                <React.Fragment key={index}>
                                    <Form.Select
                                        label={I18n.get('Type')}
                                        key={'type' + index}
                                        options={[{ key: "null", text: "", value: undefined }].concat(this.state.storageType)}
                                        placeholder={I18n.get('Type')}
                                        value={item.type}
                                        disabled={this.props.cm}
                                        onChange={this.handleSelectChange.bind(this, index, 'type')} />
                                    <Form.Input fluid label={I18n.get('Retention (days)')}
                                        type="number"
                                        min="0" step="1"
                                        key={'retention' + index}
                                        required={item.type !== ''}
                                        control={Input}
                                        value={item.retention}
                                        disabled={this.props.cm}
                                        onChange={this.handleSelectChange.bind(this, index, 'retention')}
                                        placeholder={I18n.get('Retention')}
                                    />
                                </React.Fragment>
                            </Form.Group>
                        ))}
                        {this.state.showAddButton && !this.props.cm && <React.Fragment>
                            <div className="field">
                                {this.state.myRetentionList.length<1 && <label htmlFor="">{I18n.get("Add retention rules")}</label>}
                                <button type="submit" className="ui button icon" title={I18n.get('Add retention')} onClick={this.showType}><i className="plus icon"></i></button>
                            </div>
                        </React.Fragment>
                        }


                        <FolderList
                            client={this.state.client}
                            folders={this.state.folders}
                            root={null}
                            editMode={true}
                            createFolder={this.createFolder}
                            renameFolder={this.renameFolder}
                            deleteFolder={this.deleteFolder}
                            createSuccess={false}

                        />
                        <Grid>
                            <GridRow>
                                <Grid.Column floated='left' width={4}>
                                    <Button primary fluid type='submit' size='large' onClick={this.updateClient}>
                                        {I18n.get('Update client')}
                                    </Button>
                                </Grid.Column>
                            </GridRow>
                        </Grid>
                    </Form>

                </Segment>)
        else
            return (
                <Navigate to='/' />
            );
    }
}

export default ArchiveClientDetailsLoader;