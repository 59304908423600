import React, { Component } from 'react';
import CustomTooltip from './customTooltip';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';

class Chart extends Component {
    
    render() {
        const { yFormat, crosshairTitleFormat, crosshairItemsFormat, legends, data } = this.props;

        return (
            <ResponsiveContainer width='100%'  height={300}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis tickFormatter={yFormat} tickMargin={10} tickSize={5} tick={{fontSize: 10}}/>
                    <XAxis dataKey="x" tick={{fontSize: 10}} tickSize={5} tickMargin={10}/>
                    <Tooltip content={<CustomTooltip crosshairtitle={crosshairTitleFormat} crosshairitems={crosshairItemsFormat}/>} />
                    <Legend verticalAlign="top" align="right" wrapperStyle={{paddingBottom: 10, fontSize: 12}} iconType="plainline" iconSize={10} />
                    <Bar name={legends[0]} dataKey="y0" stackId="1" fill="rgb(18, 147, 154)"/>
                    <Bar name={legends[1]} dataKey="y1" stackId="1" fill="rgb(121, 199, 227)"/>
                    <Bar name={legends[2]} dataKey="y2" stackId="1" fill="rgb(26, 49, 119)"/>
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

export default Chart;